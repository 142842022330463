import { Component, Inject, Injectable, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
export class WidgetTitleEditDialogComponentData {
  title: string;
  dialogMessage?: string;
}

@Component({
  selector: 'app-widget-title-edit-dialog',
  templateUrl : './widget-title-dialog.html',
  styleUrls: ['./widget-title-dialog.scss']
})

export class WidgetTitleEditDialogComponent {
  // @Input() dialogRef;
  // @Input() title;
  // @Input() dialogMessage = 'Please Enter the title of the widget';
  constructor( 
    public dialogRef: MatDialogRef<WidgetTitleEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: WidgetTitleEditDialogComponentData
  ) {
    _.defaults(this.dialogData, {dialogMessage: 'Please Enter the title of the widget'});
  }
}
