<mat-card>
  <mat-card-header>
    <mat-card-title class="title" *ngIf="!roleId">New Role</mat-card-title>
    <mat-card-title class="title" *ngIf="roleId"> Edit Role </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <form [formGroup]="roleGroup" *ngIf="roleGroup" fxLayout="column">
      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100%">
        <mat-label>Role Name</mat-label>
        <input matInput placeholder="Role name" formControlName='roleName'>
        <mat-error *ngIf="roleName.invalid && roleName.errors.required">Role Name is required</mat-error>
      </mat-form-field>
      <table fxFlex="100%" mat-table [dataSource]="permissions" class="mat-elevation-z8" [formGroup]="permJSON">
        <!-- Permissions Column -->
        <ng-container matColumnDef="permissions">
          <th mat-header-cell *matHeaderCellDef> Permissions </th>
          <td mat-cell *matCellDef="let element; let i= index"> {{element}}
        </ng-container>

        <!-- Controls Column -->
        <ng-container matColumnDef="controls">
          <th mat-header-cell *matHeaderCellDef> Permissions </th>
          <td mat-cell *matCellDef="let element; let i= index" [formGroupName]="element">
            <mat-checkbox [id]="'edit_'+ i" formControlName="hasEdit"></mat-checkbox> Edit
            <mat-checkbox [id]="'delete'+ i" formControlName="hasView" class="view"></mat-checkbox> View
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </form>
  </mat-card-content>
  <mat-card-actions class="text-right">
    <button mat-raised-button class="mat-green" [disabled] ="roleGroup.invalid" type="submit" (click)="saveRole()">Submit</button>
    <button mat-raised-button routerLink='/admin/roles'>Cancel</button>
  </mat-card-actions>
</mat-card>
