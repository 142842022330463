// Core modules
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { GeneralDataService } from '../../../services/general-data-service';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';
import { DataRetentionComponent } from '../../../common/dialogs/data-retention-dialog/data-retention.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { UsersService } from '../../../services/users.service';
import { OperationalFieldsAliasesComponent } from 'app/common/dialogs/op-fields-aliases/op-fields-aliases.component';
import { firstValueFrom } from 'rxjs';

/**
 * Client Index Weights Component
 */
@Component({
  selector: 'app-client-ip-white-list',
  templateUrl: './client-ip-whitelist.component.html',
  styleUrls: ['./client-ip-whitelist.component.scss']
})
export class ClientIpWhitelistComponent extends SurvaleCommonComponent implements OnInit, OnChanges {
  @Input() settings: any;
  @Input() useIpWhiteList: number;
  @Input() ipWhiteList: string;
  @Input() clientAPIKeys: { userId: number; userName: string; enabled: boolean; key: string; created: string }[];
  // ipWhiteListForm: any;
  public cSensitiveField = '';
  public eSensitiveField = '';
  public cSensitiveFieldExport = '';
  public eSensitiveFieldExport = '';
  public widgetBlackListField = '';
  public customFields: any[] = [];
  public apiUser: any;
  public ghWebHook: any;
  public showRenameField: boolean;
  public currentCustomField;
  public renamedField;
  public webHookStatus: { status: string; triggers: string[] } = { status: '', triggers: [] };
  private dialogRef: any;

  constructor(
    public authenticationService: AuthenticationService,
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private generalDataService: GeneralDataService,
    private userService: UsersService,
    private toaster: ToastrService,
    private configService: ConfigService
  ) {
    super(authenticationService);
  }

  get apiHost(): string {
    return ConfigService.config.apiHost;
  }

  async ngOnInit(): Promise<void> {
    // console.log('ClientIpWhitelistComponent ngOnInit');
    // this.ipWhiteListForm = this.fb.group({
    //   useIpWhiteList: new FormControl(this.useIpWhiteList),
    //   ipWhiteList: new FormControl(this.ipWhiteList),
    //   sensitiveFields: new FormControl(this.settings.sensitiveFields)
    // })
    if (this.isSurvaleAdmin) {
      this.ghWebHook = (await firstValueFrom(this.generalDataService.getGHWebHook())).body;
    }

    const r: any = await firstValueFrom(this.generalDataService.getCustomFields('all'));
    this.customFields = r.body;
    // console.log('this.customFields ', this.customFields);
    this.customFields.push({ key: 'firstName', value: 'First Name', hash: 1 });
    this.customFields.push({ key: 'lastName', value: 'Last Name', hash: 2 });
    this.customFields.push({ key: 'emailAddress', value: 'Email Address', hash: 3 });
    this.customFields.push({ key: 'jobTitle', value: 'Job Title', hash: -1625529189 });
    this.customFields = _.uniqBy(this.customFields, (cf): any => cf.hash);
    // const hidden = ['hiringManager', 'recruiter'];
    // this.customFields = _.filter(this.customFields, cf => hidden.indexOf( cf.key) === -1);
    this.customFields = _.sortBy(this.customFields, (cf): any => cf.value);
    // console.log('this.customFields', this.customFields);
    // get api users
    this.userService.apiUser()
      .subscribe((rr: any): void => {
        this.apiUser = _.get(rr, 'body.apiUser');
      });

    // console.log('settings ', this.settings);
    _.each(this.settings.operationalFieldAliases, (value, key): void => {
      const idx = _.findIndex(this.customFields, (cf: any): boolean => cf.key === key);
      _.set(this.customFields[idx], 'alias', value);
    });

    this.generalDataService.webhookStatus()
      .subscribe((rr: any): void => {
        console.log('webhook status ', rr);
        this.webHookStatus.status = rr.body.status;
        this.webHookStatus.triggers = (rr.body.triggers || '').split(',');
      });
  }

  async createGreenHouseWebHookURLs(): Promise<void> {
    this.ghWebHook = (await firstValueFrom(this.generalDataService.createGHWebHook())).body;
  }

  addField(fieldList: string, field: string): void {
    this.settings[fieldList].push(field);
    // console.log('this.settings[fieldList] - >', this.settings[fieldList]);
  }

  createAPIKey(): void {
    if (!this.apiUser) {
      this.toaster.error('Please create a API User First');
      return;
    }
    try {
      if (!this.clientAPIKeys) {
        this.clientAPIKeys = [];
      }
      const guid = Date.now().toString(36) + Math.random().toString(36).substring(2);
      // const u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
      // const guid = [u.substr(0,8), u.substr(8,4), '4000-8' + u.substr(13,3), u.substr(16,12)].join('-');
      this.clientAPIKeys.push(
        {
          userId: this.apiUser.userId,
          userName: this.apiUser.email,
          enabled: true,
          key: guid,
          created: moment().toISOString()
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  removeAPIKey(o: any): void {
    if (confirm('Delete this API Key.')) {
      _.remove(this.clientAPIKeys, o);
    }
  }

  disableAPIKey(o: any): void {
    o.enabled = false;
  }

  enableAPIKey(o: any): void {
    o.enabled = true;
  }

  // addCandidateSensitiveField() {
  //   // console.log(this.cSensitiveField)
  //   this.settings.candidateSensitiveFields.push(this.cSensitiveField);
  //   this.cSensitiveField = '';
  //
  // }

  // addWidgetBlackListField() {
  //   this.settings.widgetBlackList.push(this.widgetBlackListField);
  //   this.widgetBlackListField = '';
  // }

  // addEmployeeSensitiveField() {
  //   this.settings.employeeSensitiveFields.push(this.eSensitiveField);
  //   this.eSensitiveField = '';
  // }

  ngOnChanges(): void {
    if (_.isNil(this.useIpWhiteList)) {
      this.useIpWhiteList = 0;
    }
    if (this.settings && _.isNil(this.settings.drillDownFloorLimit)) {
      this.settings.drillDownFloorLimit = this.minDrillDownFloorLimit;
    }
    if (_.isNil(this.ipWhiteList)) {
      this.ipWhiteList = '';
    }
    if (this.settings) {
      const defaultRules = { archiveAfter: 36, actionWithSurveys: 'L-A_S-A', actionWithoutSurveys: 'L-D' };
      this.settings.candidateSensitiveFields = _.get(this.settings, 'candidateSensitiveFields', []);
      this.settings.employeeSensitiveFields = _.get(this.settings, 'employeeSensitiveFields', []);
      this.settings.candidateSensitiveFieldsExports = _.get(this.settings, 'candidateSensitiveFieldsExports', []);
      this.settings.operationalFieldAliases = _.get(this.settings, 'operationalFieldAliases', {});
      this.settings.employeeSensitiveFieldsExports = _.get(this.settings, 'employeeSensitiveFieldsExports', []);
      this.settings.includeCandidateSensitiveFieldsInAutomatedExports =
        _.get(this.settings, 'includeCandidateSensitiveFieldsInAutomatedExports', true);
      this.settings.includeEmployeeSensitiveFieldsInAutomatedExports =
        _.get(this.settings, 'includeEmployeeSensitiveFieldsInAutomatedExports', true);
      this.settings.widgetBlackList = _.get(this.settings, 'widgetBlackList', []);
      this.settings.drillDownFloorLimit = _.get(this.settings, 'drillDownFloorLimit', this.minDrillDownFloorLimit);
      this.settings.dataRetentionRules = _.get(this.settings, 'dataRetentionRules', defaultRules);
      this.settings.slackBotSettings = _.get(this.settings, 'slackBotSettings', { botUserOAuthToken: '' });
      this.settings.webHookType = _.get(this.settings, 'webHookType', 'gh');
      console.log('this.settings.webHookType', this.settings.webHookType);

    }
  }

  showDataRetentionDialog(): any {
    const existingSetting = this.settings.dataRetentionRules;
    const dConfig = _.assign(ConfigService.getDialogConfig(), { data: { existingSetting, settingsMode: 'client' } });
    this.dialogRef = this.matDialog.open(DataRetentionComponent, dConfig);
    this.dialogRef.afterClosed().subscribe(async (result: any): Promise<void> => {
      if (result) {
        this.settings.dataRetentionRules = result;
        this.toaster.info('Please save the settings');
        console.log(this.settings.dataRetentionRules);
      }
    });
  }

  getLiValue(v: string): any {
    let i = _.find(DataRetentionComponent.archiveOptions, (o: any): boolean => o.key === v);
    if (i) {
      return i.value;
    }
    i = _.find(DataRetentionComponent.archiveOptionsWithSurveys, (o: any): boolean => o.key === v);
    return i.value;
  }

  saveOperationalFieldAlias(operationalField: any, newName: string): void {
    console.log('current and renamed field ', operationalField, newName);
    this.showRenameField = false;
    _.set(this.settings.operationalFieldAliases, operationalField.key, newName);
    // console.log('this.settings.operationalFieldAliases', this.settings.operationalFieldAliases);
  }
  showOpFieldsAliases(): any {
    this.dialogRef = this.matDialog.open(OperationalFieldsAliasesComponent, {
      width: '600px', data: {
        aliases:
          this.settings.operationalFieldAliases, fields: this.customFields
      }
    });
    this.dialogRef.afterClosed().subscribe((result: any): void => {
      if (result) {
        // console.log('after closing dialog ', result);
        // this.customFields = result;
      }
    });
  }
}
