<h5>Other Settings</h5>
<div [style.minHeight]="'40vh'">
  <!--  <span class="title"> Index Weights</span>-->

  <div class="weights-wrap">
    <div *ngIf="otherSettings?.sqs_Jobs">
      <h6>Candidate inflow settings</h6>
      <div fxLayout="row" fxLayoutGap="10px" *ngFor="let job of otherSettings.sqs_Jobs; let i = index"
        style="margin:15px 0 15px 30px;">
        <div fxFlex="49%" style="height: 900px;">
          <div>
            Q Name: {{job.qName}}
          </div>
          <div>
            Provider: {{job.jsProvider}}
          </div>
          <div>
            Api Url: {{job.apiUrl}}
          </div>
          
          <div>
            <div>
              Last Run Time: {{job.lastRunTime | date: 'shortTime'}};{{job.lastRunTime | amTimeAgo}};({{job.lastRunTime}}) <br />
              Next Run At: {{job.nextJobAt | date: 'shortTime'}}; <br />
              Job Created: {{job.createdAt | amCalendar}}
            </div>
            <div>
              <span *ngIf="job.lastAlertedDay2" style="color: red;font-weight: 600;"> In Day 2 Alert since:
                {{job.lastAlertedDay2 | amTimeAgo}}
              </span><br/>
              <span *ngIf="job.lastAlertedDay7" style="color: red;font-weight: 600;"> In Day 7 Alert since:
                {{job.lastAlertedDay7 | amTimeAgo}}
              </span>
            </div>
          </div>
          <div style="max-height: 70%; overflow-y: auto;">
            Field Map
            <pre>{{job?.settings?.api?.fieldMap | json}}</pre>
          </div>
        </div>
        <div fxFlex="49%">
          Mapping
          <!-- <pre>{{job?.settings?.api.fieldMap | json}}</pre> -->
          <pre>{{job?.emailListSettings?.emailListJSON?.customFieldMapping | json}}</pre>
        </div>
      </div>
    </div>
    <div *ngIf="otherSettings?.scheduledJobs">
      <h6>Scheduled Export Jobs</h6>
      <div fxLayout="row" fxLayoutGap="10px" *ngFor="let job of otherSettings.scheduledJobs; let i = index"
        style="margin:15px 0 15px 30px;">
        <div *ngIf="job.scheduleType !== 'scheduled-once'" fxFlex="49%">
          <div>
            Job Name: {{job.jobName}}
          </div>
          <div>
            Job Description: {{job.jobDescription}}
          </div>
          <div>
            Job Type: {{job.jobType}}
          </div>
          <div>
            Schedule Type: {{job.scheduleType}}
          </div>

          <div>
            <div>Created {{job.createdAt | amCalendar}}; <br />
              Updated {{job.updatedAt | amCalendar}}</div>
          </div>
        </div>
        <div *ngIf="job.scheduleType !== 'scheduled-once'" fxFlex="49%">
          <pre>{{job.jobSettings | json}}</pre>
        </div>
      </div>
    </div>

  </div>
  <!-- <div>
    <pre>{{otherSettings | json}}</pre>
  </div> -->
</div>
