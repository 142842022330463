// Core modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

// Third party
import { MatSnackBar } from '@angular/material/snack-bar';

// Application services
import { UsersService } from 'app/services/users.service';
import { RolesService } from 'app/services/roles.service';

/**
 * Assign admin role to user
 */
@Component({
  selector: 'app-assign-admin',
  templateUrl: './assign-admin.component.html',
  styleUrls: ['./assign-admin.component.scss']
})
export class AssignAdminComponent implements OnInit {

  public formGroup: FormGroup;
  public users = [];
  private role;

  /**
   * Life cycle method
   * @param fb FormBuilder
   * @param userService UserService
   * @param roleService RoleService
   * @param snackBar SnackBat
   */
  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private roleService: RolesService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  /**
   * Find user value
   */
  get user(): AbstractControl {
    return this.formGroup.get('user');
  }

  /**
   * Life cycle init method initializing current users and role
   */
  ngOnInit(): void {
    this.getUsers();
    this.getRole();
    this.formGroup = this.fb.group({
      user: new FormControl('', Validators.required)
    });
  }

  /**
   * Find all list of users
   */
  getUsers(): void {
    this.userService.getUsers(undefined).subscribe(response => this.users = response.body);
  }

  /**
   * Find admin role and its permissions
   */
  getRole(): void {
    this.roleService.getRole('29').subscribe(response => this.role = response.body[0]);
  }

  /**
   * Update user with admin role and permissions values
   */
  assignAdminRole(): void {
    const currentUser = this.users.find(elem => elem.userId.toString() === this.user.value);
    const data = {
      ...currentUser,
      role: this.role
    };
    this.userService.editUser(this.user.value, data).subscribe({
      next: (): void => {
        this.snackBar.open('Assigned permission successfully !!', '', { duration: 3000 });
        this.router.navigate(['admin/roles']);
      },
      error: ((error): any => {
        console.error(error);
        this.snackBar.open('Error(s) Occurred', 'X', { duration: 3000 });
      })
    });
  }

}
