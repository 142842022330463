import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { SurveyFiltersService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppCache } from '../components/survale-common/cache/app.cache';

@Injectable()
export class SurveyFilterResolver implements Resolve<any> {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private surveyFiltersService: SurveyFiltersService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const surveyFilterId = route.paramMap.get('id');
    const cached = this.cache.getCache(this.cacheTypes.SurveyFilter, surveyFilterId);
    if (cached) {
      return cached;
    } else {
      console.error('Survey Filter NOT CACHED', surveyFilterId);
      // this should never happen as all the filters should be cached
      return null;
    }
  }
}
