<div mat-dialog-title class="dialog-header">
  <button class="close-button" (click)="close()">X</button>
  <h2>Set Password</h2>
</div>
<div mat-dialog-content class="dialog-content">
  <form [formGroup]='setPasswordForm' *ngIf="setPasswordForm">

    <div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="99%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Password</mat-label>
            <input matInput placeholder="At least 8 characters" type="text" formControlName='password'>
            <mat-error *ngIf="password.invalid && password.errors.required">Password is required</mat-error>
            <mat-error *ngIf="password.invalid && password.errors.minlength">
              Password should have minimum 8 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="99%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Force Password Change on Next Login</mat-label>
            <mat-select formControlName="forcePasswordChange">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        Password needs to be minimum 8 characters, 1 special character, 1 upper case character, 1 lower case character &
        1 number
      </div>

    </div>
  </form>
</div>

<div mat-dialog-actions class="dialog-footer">
  <div class="buttons-wrap">

    <button mat-raised-button [disabled]="setPasswordForm.invalid" color="primary" (click)="setPassword()">
      <span> Set Password </span>
    </button>

    <button mat-raised-button (click)="close()">
      Close
    </button>
  </div>
</div>
