import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obfuscateEmailInText'
})
export class ObfuscateEmailInTextPipe implements PipeTransform {

  transform(value: string) {
    return value.replace(/[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+/g, 'xxx');
  }
}
