<div mat-dialog-title class="dialog-header">
  <h4>Add Client</h4>
</div>
<div mat-dialog-content class="dialog-content">
  <form [formGroup]="addClientForm" *ngIf="addClientForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Name</mat-label>
        <input matInput placeholder="Client Name" formControlName="name">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>User Login</mat-label>
        <input matInput placeholder="Login Eamil Address" formControlName="login">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Users</mat-label>
        <input matInput placeholder="Users" formControlName="users" type="number">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Views</mat-label>
        <input matInput placeholder="Views" formControlName="views" type="number">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Parent</mat-label>
        <input matInput placeholder="Parent" formControlName="parent" readonly>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Main User First Name</mat-label>
        <input matInput placeholder="Main User First Name" formControlName="CSRFirstName">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Main User Last Name</mat-label>
        <input matInput placeholder="Main User Last Name" formControlName="CSRLastName">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <input matInput placeholder="URL" formControlName="url">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Reply to Email</mat-label>
        <input matInput placeholder="Reply to Email" formControlName="replyToEmail">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Reply to Name</mat-label>
        <input matInput placeholder="Reply to Name" formControlName="replyToName">
      </mat-form-field>
    </div>
  </div>
</form>
</div>
<div mat-dialog-actions class="dialog-footer">
  <div class="buttons-wrap">
    <button mat-raised-button mat-dialog-close class="mat-green">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="addClientForm" class="mat-green">Add Client</button>
  </div>
</div>
