import { Routes } from '@angular/router';

import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { ClientComponent } from './client/client.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';
import { AssignAdminComponent } from './assign-admin/assign-admin.component';
import { GdprLogComponent } from './gdpr-log/gdpr-log.component';
import { GdprComponent } from './gdpr/gdpr.component';
import {ApiCallLogComponent} from './api-call-log/api-call-log.component';
import {SftpAccessComponent} from './sftp-access/sftp-access.component';

export const AdminRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'client',
        component: ClientComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'users/add',
        component: AddEditUserComponent
      },
      {
        path: 'users/:id',
        component: AddEditUserComponent
      },
      {
        path: 'roles',
        component: RolesComponent
      },
      {
        path: 'api-call-logs',
        component: ApiCallLogComponent
      },
      {
        path: 'roles/add',
        component: AddEditRoleComponent
      },
      {
        path: 'roles/assign',
        component: AssignAdminComponent
      },
      {
        path: 'roles/:id',
        component: AddEditRoleComponent
      },
      {
        path: 'smart-recruiters-auth',
        redirectTo: 'auth/smart-recruiters-auth'
      },
      {
        path: 'icims-auth',
        redirectTo: 'auth/icims-auth'
      },
      {
        path: 'gdpr-log',
        component: GdprLogComponent,
      },
      {
        path: 'gdpr',
        component: GdprComponent,
      },
      {
        path: 'sftp-access',
        component: SftpAccessComponent
      },
      {
        path: 'auth',
        loadChildren: () => import ('./auth/oauth.module').then( m => m.OauthModule)
      }
    ]
  }
];
