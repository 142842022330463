import { Component, OnInit } from '@angular/core';
import { ServerDataServiceService } from 'app/services/server-data-service.service';

@Component({
  selector: 'app-gdpr-log',
  templateUrl: './gdpr-log.component.html',
  styleUrls: ['./gdpr-log.component.scss']
})
export class GdprLogComponent implements OnInit {
  gdpr = [];
  count = 0;
  offset = 0;
  limit = 10;
  selected: any[] = [];
  columns = [
    {name: 'identifier', prop: 'gdpr.identifier', flexGrow: .5},
    {name: 'Action', prop: '_source.gdprAction', flexGrow: 1},
    {name: 'By', prop: '_source.emailAddress', flexGrow: 1},
    {name: 'Log', prop: '_source.gdprLogMessage', flexGrow: 1},
    {name: 'Added', prop: '_source.created', flexGrow: .5},
  ];

  constructor(private serverDataService: ServerDataServiceService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.serverDataService.getGDPRLog(this.limit, this.offset * this.limit).subscribe((data: any) => {
      console.log('getGDPR', data);
      this.gdpr = data.body.hits.hits;
      this.count = data.body.hits.total;
    });
  }

  onPage(event: any) {
    console.log('Page Event', event);
    this.offset = event.offset;
    this.limit = event.limit;
    this.loadData();
  }


}
