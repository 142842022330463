import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';
import { EventsServiceDateRange } from './events.service';

@Injectable()

export class SentimentAnalysisService extends BaseHttpService {
  // private resource = 'survale-analysis';

  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  // getAnalysisList(surveyIds: string) {
  //   const params: any = {
  //     surveyIds
  //   };
  //   return this.get('sentiment-analysis', undefined, params);
  // }

  // surveyIds, q.npsOnly === 'yes', q.sort === 'asc', q.limit, q.offset]
  getSentimentAnalysis(limit: number, offset: number,
    dateRange: EventsServiceDateRange, surveyIds: string, filters: any, searchTerm: string) {
    const params: any = {
      dateRange: encodeURIComponent(JSON.stringify(dateRange)),
      filters: encodeURIComponent(JSON.stringify(filters)),
      searchTerm: encodeURIComponent(searchTerm),
      surveyIds, limit, offset
    };
    return this.get('sentiment-analysis', undefined, params);
  }

}
