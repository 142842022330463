// Core modules
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

/**
 * Client Index Weights Component
 */
@Component({
  selector: 'app-client-index-weights',
  templateUrl: './client-index-weights.component.html',
  styleUrls: ['./client-index-weights.component.scss']
})
export class ClientIndexWeightsComponent implements OnChanges {
  @Input() settings: any;
  public indexWeights: Array<any> = [];

  constructor(private fb: FormBuilder) {

  }
  ngOnChanges(): void {
    if (this.settings && this.settings.indexWeights) {
      this.indexWeights = this.settings.indexWeights || [];
    }
  }

  addRow() {
    this.indexWeights.push({ keyword: '', multiplier: 0.5 });
  }

  remove(idx: number) {
    this.indexWeights.splice(idx, 1);
  }

}
