import { Injectable } from '@angular/core';


declare let gtag: (type: string, name: string, options: object) => void;

export enum EventTypes {
  CandeSurveyResultFakeHiringManagerClick = 'CandeSurveyResultFakeHiringManagerClick',
  CandeSurveyResultFakeRecruiterClick = 'CandeSurveyResultFakeRecruiterClick',
  CandeSurveyResultFakeBusinessUnitClick = 'CandeSurveyResultFakeBusinessUnitClick',
  CandeSurveyResultFakeDivisionClick = 'CandeSurveyResultFakeDivisionClick',
  CandeSurveyResultFakeJobFamilyClick = 'CandeSurveyResultFakeJobFamilyClick',
  CandeSurveyResultFakeHiringManagerHover = 'CandeSurveyResultFakeHiringManagerHover',
  CandeSurveyResultFakeRecruiterHover = 'CandeSurveyResultFakeRecruiterHover',
  CandeSurveyResultFakeBusinessUnitHover = 'CandeSurveyResultFakeBusinessUnitHover',
  CandeSurveyResultFakeDivisionHover = 'CandeSurveyResultFakeDivisionHover',
  CandeSurveyResultFakeJobFamilyHover = 'CandeSurveyResultFakeJobFamilyHover',
  CandeNPSMetricComponentHover = 'CandeNPSMetricComponentHover',
  CandeVideoViewed = 'CandeVideoViewed',
  CandeFakeSurveyWidget = 'CandeFakeSurveyWidget',
}

@Injectable()
export class GoogleAnalyticsService {
  constructor() {
  }

  // public trackGAEvent(eventName: EventTypes, eventCategory: string, eventLabel: string, eventAction: string, eventValue: number) {
  //   console.log(eventName);
  //   gtag('event', eventName, {
  //     eventCategory,
  //     eventLabel,
  //     eventAction,
  //     eventValue
  //   })
  // }
}
