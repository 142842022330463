// Core modules
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { ClientService } from '@services/client.service';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ConfigService } from '@services/config.service';
// import { parseString } from 'xml2js';
import * as X2JS from 'x2js';
import * as _ from 'lodash';
import { GeneralDataService } from '@services/general-data-service';
/**
 * Add client dialog
 */
@Component({
  selector: 'app-add-saml-sso-dialog',
  templateUrl: './add-saml-sso-dialog.component.html',
  styleUrls: ['./add-saml-sso-dialog.component.scss']
})
export class AddSamlSSODialogComponent extends SurvaleCommonComponent implements OnInit {

  addSSOForm: FormGroup;
  dummyForm: FormGroup;
  logMessageOpts = ['yes', 'no'];
  // authProvider = { provider: 'okta', metaDataUrl: 'https://marvell.okta.com/app/exkspnt9lktPd7gLc2p7/sso/saml/metadata' };  
  // eslint-disable-next-line max-len
  // authProvider = { provider: 'azure', metaDataUrl: 'https://login.microsoftonline.com/600d01fc-055f-49c6-868f-3ecfcc791773/federationmetadata/2007-06/federationmetadata.xml?appid=d530cc58-b999-4e4e-9e5b-24c242d6bb72' };
  authProvider = { provider: '', metaDataUrl: '' };
  // okta - https://moonfare.okta.com/app/exkc7jwok6Gdy8wuS417/sso/saml/metadata
  // azure - 
  /**
   * Life cycle method
   * @param fb FormBuilder
   */
  constructor(
    public authenticationService: AuthenticationService,
    public generalDataService: GeneralDataService,
    private clientService: ClientService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private fb: FormBuilder,
  ) {
    super(authenticationService);
  }

  get authName(): AbstractControl {
    return this.addSSOForm.get('authName');
  }

  get authTitle(): AbstractControl {
    return this.addSSOForm.get('authTitle');
  }

  get logMessages(): AbstractControl {
    return this.addSSOForm.get('logMessages');
  }

  get entryPoint(): AbstractControl {
    return this.addSSOForm.get('entryPoint');
  }

  get issuer(): AbstractControl {
    return this.addSSOForm.get('issuer');
  }

  get cert(): AbstractControl {
    return this.addSSOForm.get('cert');
  }

  get apiHost(): string {
    return ConfigService.config.apiHost;
  }

  get metaDataUrl(): AbstractControl {
    return this.dummyForm.get('metaDataUrl');
  }

  get provider(): AbstractControl {
    return this.dummyForm.get('provider');
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {

    this.addSSOForm = this.fb.group({
      authName: new FormControl('', Validators.required),
      authTitle: new FormControl('', Validators.required),
      logMessages: new FormControl('yes', Validators.required),
      entryPoint: new FormControl('', Validators.required),
      issuer: new FormControl('', Validators.required),
      cert: new FormControl('', Validators.required),
    });
    this.dummyForm = this.fb.group({
      provider: new FormControl('', Validators.required),
      metaDataUrl: new FormControl('', Validators.required),
    });

    console.log('sso form initialized');
    this.getSSO();
  }

  async getSSO(): Promise<void> {
    const ssoResp: any = await firstValueFrom(this.clientService.getSSO());
    console.log('ssoVal', ssoResp);
    this.addSSOForm.patchValue(ssoResp.body);
  }

  async saveSSO(): Promise<void> {
    try {
      await firstValueFrom(this.clientService.setSSO(this.addSSOForm.value));
      this.toastr.success('SSO added successfully');
    } catch (error) {
      console.log('error', error);
      this.toastr.error('Error adding SSO');
    }
  }

  async processSSO(): Promise<void> {
    try {
      if (this.authProvider.provider === 'okta') {
        const response = await fetch(this.authProvider.metaDataUrl.trim());
        const xml: string = await response.text();
        // console.log('xml', xml);
        const x2js = new X2JS();
        const doc = x2js.xml2js(xml);
        // console.log('doc', doc);
        // let authName;
        // try {
        //   // authName = _.startCase(_.get(doc, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService[0]._Location')
        //   //   .split('//')[1].replace(':', '').split('.')[0]);
        // } catch (error) {
        // }
        this.addSSOForm.patchValue({
          cert: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.KeyDescriptor.KeyInfo.X509Data.X509Certificate.__text'),
          entryPoint: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService[0]._Location'),
          issuer: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService[0]._Location'),
          // authName: authName.toLowerCase() + '_okta',
          // authTitle: authName + ' Okta SAML',
        });
      } else if (this.authProvider.provider === 'azure') {
        const response: any = await lastValueFrom(this.generalDataService.reflectResponse(this.authProvider.metaDataUrl.trim()));
        const xml = response.body.data;
        // console.log('xml', xml);
        const x2js = new X2JS();
        const doc = x2js.xml2js(xml);
        // console.log('doc', doc);

        this.addSSOForm.patchValue({
          cert: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.KeyDescriptor.KeyInfo.X509Data.X509Certificate'),
          entryPoint: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService[0]._Location'),
          issuer: _.get(doc, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService[0]._Location'),
          // authName: authName.toLowerCase() + '_okta',
          // authTitle: authName + ' Okta SAML',
        });
        // });
      }
    } catch (error) {
      this.toastr.error('Error extracting SSO' + error.toString());
      console.log('error', error);
    }
  }

}
