import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { CampaignsService, InsightsService, EmployeeCampaignService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppCache } from '../components/survale-common/cache/app.cache';
import * as _ from 'lodash';

@Injectable()
export class EmployeeCampaignsResolver implements Resolve<any> {
  // private cache: AppCache = AppCache.getInstance();
  // private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private employeeCampaignService: EmployeeCampaignService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // const cached = this.cache.getCache(this.cacheTypes.EmployeeCampaigns, '');
    // if (cached) {
    //   return cached;
    // }
    // this call cannot fail
    return new Observable<any>((observer) => {
      this.employeeCampaignService.getEmployeeCampaigns().subscribe(
        data => {
          // console.log('data', data);
          observer.next(data);
          observer.complete();
        }, (error): any => {
          // condition to handle 403
          observer.next({body: []});
          observer.complete();
        }
      );
    });
  }
}
