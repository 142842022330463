import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class EmployeeCampaignService extends BaseHttpService {

  private resource = 'employee-campaigns';

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   * @param authenticationService Authentication Service
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getEmployeeCampaigns(limit = 10, offset = 0, contains = '', tags = '') {

    // console.log('limit and offset ', limit, offset);
    const params: any = {
      limit,
      offset,
      contains,
      loadCounts: true,
      tags
    };

    return this.get(this.resource, '', params);
  }

  getAllEmployeeCampaigns() {

    return this.get('all-employee-campaigns', '');
  }

  /**
   * POST Request for adding campaign
   * @param campaign
   */
  addEmployeeCampaign(campaign: any) {
    return this.post('employee-campaign', campaign);
  }

  /**
   * Delete selected campaign with given id
   * @param id
   */
  deleteEmployeeCampaign(id: string) {
    return this.delete('employee-campaign', id);
  }

  /**
   * Return campaign object by campaign id
   * @param id id of campaign
   */
  getEmployeeCampaignById(id: string): any {
    return this.get('employee-campaign', id);
  }

  /**
   * Update campaign
   * @param payload Campaign object
   * @param id campaign id
   */
  updateEmployeeCampaignById(payload, id): any {
    return this.put('employee-campaign', payload, id);
  }
}
