import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

// import { DemoMaterialModule } from './shared/demo.module';
// import { TimeAgoPipe } from 'time-ago-pipe';

import { environment } from '../environments/environment';
// import { CalendarDialogComponent } from './apps/fullcalendar/fullcalendar.component';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { ServicesModule, ConfigService } from './services/services.module';
import { SurvaleHttpInterceptor } from './interceptors/survale-http-interceptor';
import { LocalStorageModule, LocalStorageService } from 'angular-2-local-storage';
import { ViewsResolver } from './resolvers/views.resolver';
import { InsightsResolver } from './resolvers/insights.resolver';
import { UserDashboardsResolver } from './resolvers/user-dashboards.resolver';
import { EmployeeCampaignsResolver } from './resolvers/employee-campaigns-resolver';
import { InsightResolver } from './resolvers/insight.resolver';
import { SurveyFilterResolver } from './resolvers/survey-filter.resolver';
import { SurveyFiltersResolver } from './resolvers/survey-filters.resolver';
import { ViewReportsSurveysComponent } from './screens/reports/view-reports-surveys/view-reports-surveys.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { NgxNotifierModule } from 'ngx-notifier';
import { ResendEmailPopupComponent } from './screens/reports/resend-email-popup/resend-email-popup.component';
import { ToastrModule } from 'ngx-toastr';
// import { NgxAnimationsModule } from 'ngx-animations';
import { PipesModule } from './pipes/pipes.module';
import { SurveyFiltersDialogComponent } from './screens/survey-dashboard/survey-filters/survey-filters-dialog.component';
import { DashboardFiltersDialogComponent } from './screens/views/dashboard/dashboard-filters/dashboard-filters-dialog.component';
import { SurvaleCommonComponent } from './common/survale-common.component';
// eslint-disable-next-line max-len
import { ScreenshotEmailSubscribersDialogComponent } from './common/dialogs/screenshot-email-subscribers/screenshot-email-subscribers-dialog.component';
import { ShareDashboardDialogComponent } from './common/dialogs/share-dashboard/share-dashboard-dialog.component';
import { MapSurveyAnswersDialogComponent } from './common/dialogs/map-survey-answers/map-survey-answers-component';
import { DataRetentionComponent } from './common/dialogs/data-retention-dialog/data-retention.component';
import { ContactMeDialogComponent } from './common/dialogs/contact-me-dialog/contact-me-dialog.component';
import { AppMaterialModule } from './app-material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ShareFilteredDashboardDialogComponent } from './common/dialogs/share-filtered-dashboard/share-filtered-dashboard-dialog.component';
import { CloudinaryImageUploadAdapter } from 'ckeditor-cloudinary-uploader-adapter';
import { AdminModule } from './screens/admin/admin.module';
import { MomentModule } from 'ngx-moment';
import { ShowJsonDialogDialogComponent } from './common/dialogs/show-json-dialog/show-json-dialog-dialog.component';
import { SelfDashboardsFiltersResolver } from './resolvers/self-dashboards-filters-resolver';
import { SelfDashboardFiltersResolver } from './resolvers/self-dashboard-filters.resolver';
import { CreateFilterDialogComponent } from './common/dialogs/create-filter/create-filter-dialog.component';
import { AddWidgetToInsightComponent } from './common/dialogs/add-widget-to-insight/add-widget-to-insight.component';
import { CandidateCampaignDetailsComponent } from './common/dialogs/candidate-campaign-details/candidate-campaign-details.component';
import { CandidateCampaignLogsComponent } from './common/dialogs/candidate-campaign-logs/candidate-campaign-logs.component';
import { InfoDialogComponent } from './common/dialogs/info-dialog/info-dialog.component';
import { OperationalFieldsAliasesComponent } from './common/dialogs/op-fields-aliases/op-fields-aliases.component';
import { SideMenuModule } from './components/side-menu/side-menu.module';
import { SurveysByUserDialogComponent } from '@common/dialogs/surveys-by-user-dialog/surveys-by-user-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// export function ckEditorImagePluginFactory(editor, currentUser) {
//   editor.plugins.get('FileRepository').createUploadAdapter =
//     (loader) => new CloudinaryImageUploadAdapter(loader, 'survale', currentUser.cloudinaryPreset, [160, 500, 1000, 1052]);
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ViewReportsSurveysComponent,
    ResendEmailPopupComponent,
    SurveyFiltersDialogComponent,
    DashboardFiltersDialogComponent,
    SurvaleCommonComponent,
    ScreenshotEmailSubscribersDialogComponent,
    ShareDashboardDialogComponent,
    ShareFilteredDashboardDialogComponent,
    MapSurveyAnswersDialogComponent,
    DataRetentionComponent,
    ContactMeDialogComponent,
    ShowJsonDialogDialogComponent,
    CreateFilterDialogComponent,
    InfoDialogComponent,
    AddWidgetToInsightComponent,
    CandidateCampaignDetailsComponent,
    CandidateCampaignLogsComponent,
    OperationalFieldsAliasesComponent,
    SurveysByUserDialogComponent,
    // TimeAgoPipe,
  ],
  imports: [
    BrowserModule,
    // ChartModule.forRoot(require('highcharts')),
    BrowserAnimationsModule,
    SharedModule,
    // RouterModule.forRoot(AppRoutes, {useHash: true, enableTracing: true, scrollPositionRestoration: 'enabled'}),
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    }),
    FormsModule,
    AppMaterialModule,
    // DemoMaterialModule,
    HttpClientModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FlexLayoutModule,
    PerfectScrollbarModule,
    ServicesModule.forRoot(),
    LocalStorageModule.forRoot({
      prefix: 'survale-customer-portal',
      storageType: 'localStorage'
    }),
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot(),
    PipesModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxMatSelectSearchModule,
    AdminModule,
    // NgxNotifierModule,
    MomentModule,
    // for 2FA dialog
    SideMenuModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    TranslateService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SurvaleHttpInterceptor,
      multi: true
    },
    SurvaleHttpInterceptor,
    LocalStorageService,
    SurvaleCommonComponent,
    ViewsResolver,
    InsightsResolver,
    InsightResolver,
    UserDashboardsResolver,
    SurveyFiltersResolver,
    SurveyFilterResolver,
    EmployeeCampaignsResolver,
    SelfDashboardFiltersResolver,
    SelfDashboardsFiltersResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private configService: ConfigService) {

  }
}
