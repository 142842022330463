// Core modules
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClientService } from '@services/client.service';

/**
 * Client Index Weights Component
 */
@Component({
  selector: 'app-other-client-settings',
  templateUrl: './other-client-settings.component.html',
  styleUrls: ['./other-client-settings.component.scss']
})
export class OtherClientSettingsComponent implements OnInit {
  @Input() settings: any;
  public otherSettings: any;
  constructor(private fb: FormBuilder,
    private clientService: ClientService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.otherSettings = (await this.clientService.getOtherClientSettings().toPromise()).body;
    for (const setting of this.otherSettings.scheduledJobs) {
      try {
        setting.jobSettings = JSON.parse(setting.jobSettings);
      } catch (e) {}
    }
    for (const job of this.otherSettings.sqs_Jobs) {
      try {
        job.settings = JSON.parse(job.settings);
      } catch (e) {}
      try {
        job.emailListSettings = JSON.parse(job.emailListSettings);
      } catch (e) {}
    }
    console.log('this.otherSettings', this.otherSettings);
  }



}
