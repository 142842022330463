import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class TagsService extends BaseHttpService {

  private resource = 'tags';

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   * @param authenticationService Authentication Service
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getTags() {
    return this.get(this.resource);
  }


  /**
   * POST Request for adding campagin
   * @param campaign
   */
  addTag(tag: any) {
    const params: any = {
      tagName: tag
    };
    return this.post('tags', params);
  }

  saveDocumentTags(documentType, tags, documentId) {
    const params: any = {
      documentType,
      tags,
      documentId,
    };
    return this.post('tag', params);
  }

  /**
   * Delete selected campaign with given id
   * @param id
   */
  deleteCampaign(id: string) {
    return this.delete('campaign', id);
  }


  /**
   * Return logs
   * @param id campaign id
   */
  getLogs(id: string, isEmployeeCampaign): any {
    const resource = isEmployeeCampaign ? 'employee-campaign/logs' : 'campaign/logs';
    return this.get(resource, id, {limit: 50});
  }

  /**
   * Return campaign object by campaign id
   * @param id id of campaign
   */
  getCampaignById(id: string): any {
    return this.get('campaign', id);
  }

  /**
   * Update campaign
   * @param payload Campaign object
   * @param id campaign id
   */
  updateCampaignById(payload, id): any {
    return this.put('campaign', payload, id);
  }
}
