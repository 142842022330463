import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService, UserTypes } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { SurvaleHttpInterceptor } from '../interceptors/survale-http-interceptor';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class UsersService extends BaseHttpService {
  private resource = 'user';

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getUsers(searchTerm) {
    return this.get('users', undefined, { searchTerm });
  }

  getSftpUsers(clientId) {
    return this.get('sftp-users', undefined, {clientId});
  }

  getUserSftpBucketContents(folder) {
    return this.get('sftp-bucket-contents', undefined, {folder});
  }

  deleteSftpObject(payload: any) {
    return this.post('sftp-delete-object', payload);
  }

  uploadSftpFile(formData, Key) {
    this.restHost = ConfigService.config.apiHost;
    const url = [this.restHost, '/v0/', 'sftp-upload-file'];
    return this.http.post(url.join(''), formData);
  }

  getLoginHistory(emailAddress) {
    return this.get('login-history', undefined, { emailAddress });
  }

  getUsersByType(userTypes: Array<UserTypes>) {
    return this.get('users-by-type', undefined, { userTypes });
  }

  getUser(id: string) {
    return this.get(`${this.resource}/${id}`);
  }

  apiUser() {
    return this.get('api-user');
  }

  addUser(payload: any) {
    return this.post(this.resource, payload);
  }

  editUser(id: string, payload: any) {
    return this.put(this.resource, payload, id);
  }

  deleteUser(id: string) {
    return this.delete(this.resource, id);
  }

  generateTwoFACode(userId) {
    if (!userId) {
      throw new Error('userId is required');
    }
    return this.post('two-fa-code/generate', {}, { userId });
  }

  validateTwoFACode(token, secret) {
    return this.post('two-fa-code/validate', { token, secret });
  }

  commitTwoFACode(userId, secret) {
    return this.post('two-fa-code/commit', { userId, secret });
  }

  clearTwoFACode(userId) {
    return this.post('two-fa-code/remove', { userId });
  }

  setPassword(userId, password: string, forcePasswordChange: string): Observable<any> {
    return this.post('set-password', { userId, password, forcePasswordChange });
  }
}
