<div mat-dialog-title class="dialog-header">
  <h4>SSO Settings</h4>
</div>
<div mat-dialog-content class="dialog-content">

  <form [formGroup]="dummyForm" *ngIf="dummyForm">
    <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
      <div fxFlex="32%">
        <mat-form-field appearance="outline">
          <mat-label>Sign on URL / Identifier Domain</mat-label>
          <div>
            <mat-select [(ngModel)]="authProvider.provider" formControlName="provider">
              <mat-option [value]="'okta'"> Okta </mat-option>
              <mat-option [value]="'azure'"> Azure AD </mat-option>
            </mat-select>
          </div>
        </mat-form-field>
      </div>
      <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Metadata - URL</mat-label>
        <input matInput placeholder="https://xxxx.okta.com/app/xxxxxx/sso/saml/metadata" [(ngModel)] = "authProvider.metaDataUrl" formControlName="metaDataUrl">
      </mat-form-field>
    </div>
      <div fxFlex="32%">
        <button mat-raised-button (click)="processSSO()">Extract Values</button>
      </div>
    </div>
  </form>
  <hr style="margin: 20px 0;" />

  <form [formGroup]="addSSOForm" *ngIf="addSSOForm">
    <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
      <div fxFlex="60%">
        <!-- <mat-form-field appearance="outline"> -->
        <mat-label>Sign on URL / Identifier (Entity ID)</mat-label>
        <div><code>{{apiHost}}/v0/auth/{{authName?.value}}</code></div>
        <!-- </mat-form-field> -->
      </div>
      <div fxFlex="36%">
        <!-- <mat-form-field appearance="outline"> -->
        <mat-label>Relay State</mat-label>
        <div><code>{{apiHost}}</code></div>
        <!-- </mat-form-field> -->
      </div>
      <div fxFlex="80%">
        <!-- <mat-form-field appearance="outline"> -->
        <mat-label>Reply URL</mat-label>
        <div><code>{{apiHost}}/v0/auth/{{authName?.value}}/callback</code></div>
        <!-- </mat-form-field> -->
      </div>
    </div>
    <hr style="margin: 21px;" />
    <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
      <div fxFlex="32%">
        <mat-form-field appearance="outline">
          <mat-label>Saml Name</mat-label>
          <input matInput placeholder="Saml Name e.g. Company Name - Saml" formControlName="authTitle">
        </mat-form-field>
      </div>
      <div fxFlex="32%">
        <mat-form-field appearance="outline">
          <mat-label>Saml Identifier</mat-label>
          <input matInput placeholder="saml identifier e.g. company-name/saml" formControlName="authName">
        </mat-form-field>
      </div>
      <div fxFlex="32%">
        <mat-form-field appearance="outline">
          <mat-label>Log Messages</mat-label>
          <mat-select formControlName="logMessages">
            <mat-option *ngFor="let logMessage of logMessageOpts" [value]="logMessage">
              {{logMessage}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="66%">
        <mat-form-field appearance="outline">
          <mat-label>Entry Point</mat-label>
          <input matInput placeholder="Entry Point" formControlName="entryPoint">
        </mat-form-field>
      </div>
      <div fxFlex="32%">
        <mat-form-field appearance="outline">
          <mat-label>Cert Issuer</mat-label>
          <input matInput placeholder="certificate issuer, leave black if not known" formControlName="issuer">
        </mat-form-field>
      </div>
      <div fxFlex="99%">
        <mat-form-field appearance="outline">
          <mat-label>X 509 Certificate</mat-label>
          <textarea matInput placeholder="X 509 Certificate" rows="5" formControlName='cert'></textarea>
        </mat-form-field>
      </div>

    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-footer">
  <div class="buttons-wrap">
    <button mat-raised-button mat-dialog-close class="mat-green">Close</button>
    <button mat-raised-button (click)="saveSSO()" class="mat-green">Add SSO</button>
  </div>
</div>
