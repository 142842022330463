// Core modules
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Application services
import { ClientService } from 'app/services/client.service';

/**
 * Email alert settings component
 */
@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnChanges {
  @Input() settings;

  public surveyEscalationSettings: any;
  public form: FormGroup;
  public emailForm: FormGroup;
  public keywordForm: FormGroup;


  /**
   * Life cycle method
   * @param clientService Client service
   * @param fb FormBuilder
   */
  constructor(
    private clientService: ClientService,
    private fb: FormBuilder
  ) {
  }

  /**
   * Returns schedule
   */
  get schedule(): AbstractControl {
    return this.form.get('schedule');
  }

  get email(): AbstractControl {
    return this.emailForm.get('email');
  }

  /**
   * Find default survey escalation settings and initialize form
   */
  ngOnInit(): void {
    this.surveyEscalationSettings = this.clientService.getDefaultSurveyEscalation();
    this.initializeForm();
  }

  /**
   * Settings the value received from parent to child
   */
  ngOnChanges(): void {
    if (this.form && this.settings) {
      this.surveyEscalationSettings = this.settings.surveyEscalation;
      if (this.surveyEscalationSettings) {
        this.form.patchValue(this.surveyEscalationSettings);
      }
    }
  }

  /**
   * Initialize form for email settings
   */
  initializeForm(): void {
    this.form = this.fb.group({
      longCommentsMinChars: new FormControl(),
      schedule: new FormControl(),
      hours: new FormControl(),
      minScore: new FormControl(),
      npsAlert: new FormControl(),
    });

    this.emailForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
      username: new FormControl()
    });

    this.keywordForm = this.fb.group({
      keyword: new FormControl()
    });
  }

  removeRecipient(idx: number) {
    this.surveyEscalationSettings.reportToEmail.splice(idx, 1);
  }

  removeKeyword(idx: number) {
    this.surveyEscalationSettings.customLookoutKeyWords.splice(idx, 1);
  }

  /**
   * Add user in reportToEmail Array
   */
  addUser(): void {
    if (this.emailForm.value.email && this.emailForm.value.username) {
      this.surveyEscalationSettings.reportToEmail.push(this.emailForm.value);
      this.emailForm.reset();
    }
  }

  /**
   * Add keyword in customLookoutKeywords Array
   */
  addKeyword(): void {
    if (this.keywordForm.value.keyword) {
      this.surveyEscalationSettings.customLookoutKeyWords.push(this.keywordForm.value.keyword);
      this.keywordForm.reset();
    }
  }
}
