<mat-card>
  <mat-card-header>
    <mat-card-title class="title" *ngIf="!userId"> New User</mat-card-title>
    <mat-card-title class="title" *ngIf="userId"> Edit User</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]='addEditForm' *ngIf="addEditForm">
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First Name" formControlName='firstName'>
            <mat-error *ngIf="firstName.invalid && firstName.errors.required">First Name is required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last Name" formControlName='lastName'>
            <mat-error *ngIf="lastName.invalid && lastName.errors.required">Last Name is required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always" *ngIf="!isSpecialUserWoEmail">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName='email'>
            <mat-error *ngIf="email.invalid && email.errors.required">Email is required</mat-error>
            <mat-error *ngIf="email.invalid && email.errors.email">Please enter valid email address</mat-error>
          </mat-form-field>
          <div appearance="outline" floatLabel="always" *ngIf="isSpecialUserWoEmail" style="margin-top: 10px">
            <mat-label>Email: {{email.value.toString()}}</mat-label>
          </div>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Email Alias</mat-label>
            <input matInput placeholder="Email Alias" formControlName='emailAlias'>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Job Title</mat-label>
            <input matInput placeholder="Title" formControlName='jobTitle'>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>User Type</mat-label>
            <mat-select formControlName='userType'>
              <mat-option *ngFor="let k of userTypeKeys" [value]="userTypes[k]">
                {{k}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>User Status</mat-label>
            <mat-select formControlName='userStatus'>
              <mat-option value="active">Active</mat-option>
              <mat-option value="suspended">Suspended</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Role</mat-label>
            <mat-select formControlName='roleId'>
              <mat-option *ngFor="let role of roles" value="{{role.roleId}}">{{role.roleName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="roleId.invalid && roleId.errors.required">Role Id is required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Views</mat-label>
            <mat-select multiple formControlName='viewIds' [disabled]="userType.value !== userTypes.AppUser">
              <mat-option *ngFor="let view of views" value="{{view.viewId}}">{{view.viewName}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="userType.value !== userTypes.AppUser">Only App Users can have Views</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Send Weekly Email</mat-label>
            <mat-select formControlName='preferences_weekly_email'>
              <!--              <mat-option value="release2018">2018 Release</mat-option>-->
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-hint *ngIf="userType.value !== userTypes.AppUser">Only App Users are sent weekly emails if enabled in client settings</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="48%" *ngIf="authProviderOptions && authProviderOptions.length > 0">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Auth Provider</mat-label>
            <mat-select formControlName='authProvider'>
              <mat-option *ngFor="let o of authProviderOptions" [value]="o.title">
                {{o.title}}
              </mat-option>
            </mat-select>
            <mat-hint>Only if client has Single Sign On setup complete</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="48%" *ngIf="!userId">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName='password'>
            <mat-error *ngIf="password.invalid && password.errors.required">Password is required</mat-error>
            <mat-error *ngIf="password.invalid && password.errors.minlength">Password should be 8 characters long.
              Password should contain one uppercase,one lowercase,one number and one special character.
            </mat-error>
          </mat-form-field>
          <p class="password-message">Password should be 8 characters long.
            Password should contain one uppercase, one lowercase, one number and one special character.</p>
        </div>
        <div fxFlex="48%" *ngIf="!userId">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Confirm Password</mat-label>
            <input matInput type="password" [errorStateMatcher]="matcher"
                   formControlName='repeatPassword'>
            <mat-error *ngIf="repeatPassword.invalid && repeatPassword.errors.required">Repeat Password is required
            </mat-error>
            <mat-error *ngIf="repeatPassword.invalid && repeatPassword.errors.minlength">Repeat Password should have
              minimum 8 characters and should contain one uppercase, one lowercase, one number and one special
              character.
            </mat-error>
            <mat-error *ngIf="addEditForm.hasError('notMatch')">Both password should match</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="48%">
          <div class="upload-btn-wrapper" fxLayout="row wrap" fxLayoutAlign="start center">
            <div *ngIf="avatar.value" class="image-avatar" fxFlex="20%" fxLayout="column">
              <img [src]='avatar.value' class='avatar'/>
              <a (click)="removePhoto()">Remove Photo</a>
            </div>
            <div fxFlex="50%">
              <button class="btn" mat-raised-button id="hoverId" (click)="imgSelector.click()"><i
                  class="fa fa-upload"></i>
                Upload
              </button>
              <mat-progress-bar *ngIf="uploader.isUploading && showSideUploadButton" mode="determinate"
                                [color]="uploader.progress === 100 ? 'primary' : 'warn'" class="mt-1"
                                value="{{uploader.progress}}">
              </mat-progress-bar>
            </div>
            <input type="file" ng2FileSelect accept="image/*" #imgSelector style="display: none;" [uploader]="uploader"
                   (onFileSelected)="showSideUploadButton = true;" (change)="uploadFile($event)"/>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions class="text-right">
    <button *ngIf="!apiUser" mat-raised-button class="mat-green pull-left" (click)="createAPIUser()"
    style="margin-right: 100px">Create API User
    </button>
    <button mat-raised-button class="mat-green" (click)="saveUser()">Submit</button>
    <button mat-raised-button routerLink='/admin/users'>Cancel</button>
  </mat-card-actions>
</mat-card>
