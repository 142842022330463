import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class SurvaleStartService extends BaseHttpService {

  private resource = 'survale-start';

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   * @param authenticationService Authentication Service
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getSurvaleStart(limit = 10, offset = 0, contains = '', tags = '') {

    // console.log('limit and offset ', limit, offset);
    const params: any = {
      limit,
      offset,
      contains,
      loadCounts: true,
      tags
    };

    return this.get(this.resource, '', params);
  }

  /**
   * POST Request for adding campagin
   * @param payload
   */
  addSurvaleStart(payload: any) {
    return this.post(this.resource, payload);
  }

  /**
   * Return campaign object by campaign id
   * @param id id of campaign
   */
  getSurvaleStartById(id: string): any {
    return this.get(this.resource, id);
  }

  /**
   * Delete selected campaign with given id
   * @param id
   */
  deleteSurvaleStart(id: string) {
    return this.delete(this.resource, id);
  }

  /**
   * Update campaign
   * @param payload Campaign object
   * @param id campaign id
   */
  updateSurvaleStartById(payload, id): any {
    return this.put(this.resource, payload, id);
  }


  getLogs(id: string): any {
    const resource = 'survale-start/logs';
    return this.get(resource, id, {limit: 50});
  }

}
