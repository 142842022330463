import { Injectable } from '@angular/core';
import { BaseHttpService, ConfigService, AuthenticationService } from './services.module';
import { HttpClient } from '@angular/common/http';
import { EventsServiceDateRange } from './events.service';
import {nullSafeIsEquivalent} from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ServerDataServiceService extends BaseHttpService {

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getHiddenQuestionList() {
    return this.get('hidden-survey-questions');
  }

  getSurveyList(limit = 10) {
    return this.get('surveys', undefined, {limit});
  }

  createSiteInClickyAcc(thisSite) {
    return this.post('clicky/add', thisSite);
  }

  addSite(thisSite) {
    return this.post('site/add', thisSite);
  }

  getSite(id: string) {
    return this.get('site/get?id=' + id);
  }

  putSettings(id, thisSite) {
    return this.put('settings', thisSite, id);
  }

  putClient(client) {
    return this.put('client', client, '');
  }

  addTagChangesBackInTime(tagsChanged, surveyId) {
    return this.post('add-tag-changes', {tagsChanged, surveyId}, '');
  }

  addRatingChangesBackInTime(ratingsChanged, surveyId) {
    return this.post('add-rating-changes', {ratingsChanged, surveyId}, '');
  }


  recalcTag(id) {
    return this.get('recalc-tag', '', {id});

  }

  fetchPendingRecalcItems() {
    return this.get('fetch-pending-recalc-items', '');
  }

  fetchPendingRecalcRatingItems() {
    return this.get('fetch-pending-recalc-rating-items', '');
  }

  fetchInProgressRecalcItems() {
    return this.get('fetch-inprogress-recalc-items', '');
  }

  fetchInProgressRecalcRatingItems() {
    return this.get('fetch-inprogress-recalc-rating-items', '');
  }

  needsTagRecalc(tag) {
    return this.get('needs-tag-recalc', '', {tag});
  }

  needsRatingRecalc(tag) {
    return this.get('needs-rating-recalc', '', {tag});
  }

  recalcRating(id) {
    return this.get('recalc-rating', '', {id});
  }

  recalcTagInProgress(tag) {
    return this.get('recalc-tag-in-progress', '', {tag});
  }

  recalcRatingInProgress(tag) {
    return this.get('recalc-rating-in-progress', '', {tag});
  }

  getGDPRLog(limit = 10, offset = 0) {
    const params: any = {
      limit,
      offset,
      loadCounts: true
    };
    return this.get('gdpr-log', '', params);
  }

  getApiCallLogs(limit, offset = 0, timePeriod: EventsServiceDateRange,) {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      limit,
      offset,
      timePeriod: JSON.stringify(tsObj)
    };
    return this.get('api-call-logs', '', params);
  }

  getApiCallLogDetails(url) {
    const params: any = {
      url
    };
    return this.get('api-call-log/details', '', params);
  }

  getGDPRSearch(emailAddress) {
    return this.get('email-list-search', '', {emailAddress});
  }

  addGDPRException(emailAddress, gdprAction, deleteSurveys) {
    const payload = {emailAddress, gdprAction};
    const params = {deleteSurveys};
    return this.post('gdpr-exception-add', payload, params);
  }

  postToSlack(data) {
    return this.post('slack/send-message', {message: data});
  }

  postToSlackEx(data) {
    return this.post('slack/send-message-ex', {message: data});
  }
}
