<h5>Index Weights</h5>
<div [style.minHeight]="'40vh'">
  <!--  <span class="title"> Index Weights</span>-->
  <h6 *ngIf="!indexWeights || indexWeights.length === 0" class="no-weights">
    No Weights found.
    <small>Click on Add More to add default weights
    </small>
  </h6>
  <div fxLayout="row wrap" class="weights-wrap" fxLayoutGap="10px" *ngFor="let iw of indexWeights; let idx = index">
    <div fxFlex="2%">
      <i class="fa fa-trash" (click)="remove(idx)"></i>
    </div>
    <div fxFlex="40%">
      <mat-form-field>
        <mat-label>Index Title</mat-label>
        <input matInput [(ngModel)]="iw.keyword">
      </mat-form-field>
    </div>
    <div fxFlex="40%">
      <mat-form-field>
        <mat-label>Multiplier</mat-label>
        <input matInput type="number" [(ngModel)]="iw.multiplier">
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" class="settings-row" fxLayoutGap="10px">
    <button mat-raised-button (click)="addRow()">Add More</button>
  </div>
  <div fxLayout="row wrap" class="settings-row" fxLayoutGap="10px">
    Please Logout and Login for these changes to take effect :(
  </div>
  </div>
