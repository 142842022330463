import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { InsightsService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppCache } from '../components/survale-common/cache/app.cache';
import * as _ from 'lodash';

@Injectable()
export class InsightResolver implements Resolve<any> {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private insightsService: InsightsService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const insightId = route.paramMap.get('insightId');
    let cached = this.cache.getCache(this.cacheTypes.Insight, insightId);
    // console.log('insightId', insightId, cached);
    if (cached) {
      return cached;
    } else {
      console.error('INSIGHT NOT CACHED');
      return new Observable<any>((observer) => {
        this.insightsService.getInsight(insightId)
          .subscribe(data => {
            if (_.isNil(data.body)) {
              alert('This insight was either deleted or unshared with you');
            } else {
              this.cache.setCache(this.cacheTypes.Insight, insightId, data.body);
              cached = data.body;
            }
            observer.next(cached);
            observer.complete();
          }, (error): any => {
            alert('This insight was either deleted or unshared with you');
            observer.error(error);
            observer.complete();
          });
      });
    }
  }
}
