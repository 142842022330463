import { Component, OnInit } from '@angular/core';
import { ServerDataServiceService } from 'app/services/server-data-service.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent {
  gdpr = [];
  emailListInstancesCount = 0;
  offset = 0;
  limit = 10;
  selected: any[] = [];
  txtEmail: any;
  surveyCount = 0;
  columns = [
    { name: 'Email Address', prop: '_source.emailAddress', flexGrow: 1 },
    { name: 'First Name', prop: '_source.firstName', flexGrow: 1 },
    { name: 'Last Name', prop: '_source.lastName', flexGrow: 1 },
    { name: 'Added', prop: '_source.created', flexGrow: .5 },
  ];
  lastSearchedFor = '';
  getGdrLog;

  constructor(private serverDataService: ServerDataServiceService) {
    this.getGdrLog = _.debounce((input) => this.loadData(input.target.value), 250);
  }
  // getGdrLog(input) {
  //   this.processResolversData(input.target.value)
  // }

  deleteAllData(deleteSurveys: boolean) {
    if (confirm('Delete all these records?. This action cannot be undone')) {
      this.serverDataService.addGDPRException(this.lastSearchedFor, 'DELETE', deleteSurveys)
        .subscribe({
          next: (result: any): void => {
            alert(`All the records for ${this.lastSearchedFor} have been deleted`);
            this.loadData(this.lastSearchedFor);
          },
          error: (error): any => {
            alert('There was a error deleting records for ' + this.lastSearchedFor);
            this.loadData(this.lastSearchedFor);
          }
        });
    }
  }

  annonomize() {
    if (confirm('Anonymize these records?. This action cannot be undone')) {
      this.serverDataService.addGDPRException(this.lastSearchedFor, 'ANNONOMIZE', false)
        .subscribe({
          next: (result: any): void => {
            alert(`All the records for ${this.lastSearchedFor} have been anonymized`);
            this.loadData(this.lastSearchedFor);
          },
          error: (error): any => {
            alert('There was a error anonymizing records for ' + this.lastSearchedFor);
            this.loadData(this.lastSearchedFor);
          }
        });
    }
  }

  loadData(email) {
    this.serverDataService.getGDPRSearch(encodeURIComponent(email.toLowerCase()))
      .subscribe((data: any) => {
        this.lastSearchedFor = email.toLowerCase();
        this.gdpr = _.get(data.body, 'emailListRecords.hits.hits', []);
        this.emailListInstancesCount = _.get(data.body, 'emailListRecords.hits.hits', []).length;
        this.surveyCount = data.body.surveys.total;
      });
  }
}
