import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
// import { DashboardService, EventsService } from '../../../services/services.module';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './op-fields-aliases.component.html',
  styleUrls: ['./op-fields-aliases.component.scss'],
})
export class OperationalFieldsAliasesComponent implements OnInit {

  operationalFieldsAliases;
  customFields;
  constructor(public dialogRef: MatDialogRef<OperationalFieldsAliasesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updatePosition({top: '50px'});
    this.operationalFieldsAliases = this.data.aliases;
    this.customFields = this.data.fields;
    // console.log('data passed ', this.operationalFieldsAliases);
  }

  removeAliases(cf,idx){

    delete this.operationalFieldsAliases[cf.key];
    this.customFields.splice(idx,1);
    //   console.log('after delete ', this.operationalFieldsAliases, idx);

  }
  onNoClick(): void {
    this.dialogRef.close(this.operationalFieldsAliases);
  }
}
