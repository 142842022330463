import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import Player from '@vimeo/player';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChildMenuNavigationItem, MainMenuNavigationItem, MenuItems } from '../../shared/menu-items/menu-items';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  AuthenticationService,
  ConfigService,
  DashboardService,
  EventsService,
  InsightsService,
  SurveyFiltersService
} from '@services/services.module';

import { AppCache } from '../../components/survale-common/cache/app.cache';

import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import {
  AddedDashboard,
  DeletedDashboard,
  EventsServiceDateRange,
  ReloadObjectTypes
} from '@services/events.service';
import { AppFeatures, PermissionOptions, PermissionTypes } from '@services/config.service';
import { SurvaleCommonComponent } from '../../common/survale-common.component';
import { TwoFaCodeComponent } from '../../screens/admin/users/two-fa-code/two-fa-code.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent extends SurvaleCommonComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;

  @ViewChild('sidemenu', { static: true }) sidemenu;

  // finalMenuItems: MainMenuNavigationItem[] = [];
  public config: PerfectScrollbarConfigInterface = {};
  public survaleMainLogo = 'assets/survale-enterprise.png';


  today: number = Date.now();
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  horizontal = false;
  sidebarBg = true;
  currentLang = 'en';
  layoutDir = 'ltr';
  searchFocus = false;
  menuLayout: any = 'vertical-menu';
  selectedSidebarImage: any = 'bg-1';
  selectedSidebarColor: any = 'sidebar-default';
  selectedHeaderColor: any = 'header-default';
  collapsedClass: any = 'side-panel-opened';
  logoUrl = 'https://static.survale.com/images/survale-clear-image.png';
  avatar: string;
  userName: string;
  selectedDateRange = { startDate: moment(), endDate: moment() };
  candeMenuClicked = false;
  dateRangePickerOptions: any;
  forceHideSideMenu = false; // only used by screenshot component


  private _router: Subscription;
  private userViews = [];
  private insights = [];
  private dashboards = [];
  private employeeCampaigns = [];
  private surveyFilters: any = [];
  private selfDashboardFilters: any = [];
  private cache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();
  private enableDateRangeEventEmitor = false;
  private menuCheckerCount = 0;
  private subscriptions: Array<Subscription> = [];



  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    public menuItems: MenuItems,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private insightsService: InsightsService,
    private surveyFiltersService: SurveyFiltersService,
    private configService: ConfigService,
    public dialog: MatDialog,
    private eventsService: EventsService,
    private dashboardService: DashboardService) {
    super(authenticationService);
    this.dateRangePickerOptions = this.configService.getDateRangePickerOptions();
    // console.log('AdminLayoutComponent .. ctor');
    const currentDateRange = this.eventsService.getCurrentDateRange();
    // const currentDateRangeLabel = this.eventsService.getCurrentDateRangeLabel();

    this.selectedDateRange.startDate = currentDateRange.startDate;
    this.selectedDateRange.endDate = currentDateRange.endDate;

    this.subscriptions.push(this.eventsService.onDashboardAdded$
      .subscribe((d: AddedDashboard) =>
        this.addDashboardToView(d.id, d.viewId, d.title, d.dashboard)
      )
    );

    this.subscriptions.push(this.eventsService.onDashboardDeleted$
      .subscribe((d: DeletedDashboard) =>
        this.removeDashboardFromView(d.id, d.viewId)
      )
    );
    this.subscriptions.push(this.eventsService.onReloadObject$
      .subscribe((objType: ReloadObjectTypes) => {
        if (objType === ReloadObjectTypes.Insights) {
          this.insightsService.getUsersSurveyInsights()
            .subscribe(response => {
              this.processInsights(response.body);
            });
          // } else if (objType === ReloadObjectTypes.SurveyFilters) {
          //   this.surveyFiltersService.getUsersSurveyFilters()
          //     .subscribe(response => {
          //       this.processSurveyFilters(response.body);
          //     })
          // } else if (objType === ReloadObjectTypes.DashboardFilters) {
          //   this.dashboardService.getSelfFilteredDashboards()
          //     .subscribe((data) => {
          //       this.processSelfDashboardsFilters(data.body);
          //     })
        }
      }));

    this.subscriptions.push(this.eventsService.onEventDateRangeChanged$.subscribe((dateRange: EventsServiceDateRange) => {
      this.selectedDateRange = dateRange;
    }));

    this.subscriptions.push(this.eventsService.onForceDateRange$.subscribe((dateRange: EventsServiceDateRange) => {
      this.selectedDateRange = dateRange;
      this.eventsService.emitDateRangeChanged(dateRange);
    }));

    this.subscriptions.push(this.eventsService.readyComponentsForScreenshot$.subscribe((): void => {
      // console.log('hiding side menu');
      this.collapsedClass = 'side-panel-closed';
      this.sidemenu.opened = false;
      this.forceHideSideMenu = true;
      setTimeout(() => window.dispatchEvent(new Event('resize')), 2000);
      // this fires resize event to adjust the content
      this.eventsService.emitSideToggled$();

    }));

    this.subscriptions.push(this.eventsService.onSelfDashboardFiltersChanged.subscribe((): void => {
      // repopulate the list
      this.dashboardService.getSelfFilteredDashboards()
        .subscribe((data) => {
          this.processSelfDashboardsFilters(data.body);
        });
    }));

    this.eventsService.onSelfSurveyFiltersChanged.subscribe((): void => {
      this.surveyFiltersService.getUsersSurveyFilters()
        .subscribe((data) => {
          this.processSurveyFilters(data.body);
        });
    });
    switch (window.location.hostname) {
      // case 'localhost':
      case 'surveys.e360data.com':
        this.survaleMainLogo = 'assets/partner-logos/demo-logo.png';
        break;
      case 'surveys.radancy.solutions':
        this.survaleMainLogo = 'assets/partner-logos/radancy.png';
        break;
      case 'surveys.phenomsurveys.com':
        this.survaleMainLogo = 'assets/partner-logos/phenom.png';
        break;
      case 'surveys.happydance.solutions':
        this.survaleMainLogo = 'assets/partner-logos/happy-dance.png';
        break;
      default:
        if (this.isCande()) {
          this.survaleMainLogo = 'assets/survale-lite-logo.png';
        } else {
          this.survaleMainLogo = 'assets/survale-enterprise.png';
        }
        break;
    }


  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    setTimeout((): void => {
      if (window.matchMedia('(min-width: 960px)').matches && !this.isMac() && !this.compactSidebar && this.layoutDir !== 'rtl') {
        const ps = new PerfectScrollbar(elemSidebar, {
          wheelSpeed: 2,
          suppressScrollX: true
        });
      }
    }, 350);
  }

  ngOnInit(): void {

    // TODO: based on current user set the logo url
    // https://static.survale.com/images/survale-limited-logo.png
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    // @ts-ignore
    if (window.matchMedia('(min-width: 960px)').matches && !this.isMac() &&
      !this.compactSidebar && this.layoutDir.toString() !== 'rtl') {
      const ps = new PerfectScrollbar(elemSidebar, {
        wheelSpeed: 2,
        suppressScrollX: true
      });
    }

    this._router = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
        if (this.isOver()) {
          this.sidemenu.close();
        }
        // @ts-ignore
        if (window.matchMedia('(min-width: 960px)').matches &&
          !this.isMac() && !this.compactSidebar && this.layoutDir.toString() !== 'rtl') {
          // Ps.update(elemContent);
        }
      });

    this.activatedRoute.data.subscribe(response => {
      this.processResolversData(response);
    });
  }

  ngAfterViewInit(): void {
    this.enableDateRangeEventEmitor = true;
  }

  ngOnDestroy(): void {
    if (this._router) {
      this._router.unsubscribe();
    }
    _.each(this.subscriptions, (s: Subscription): void => s.unsubscribe());
  }

  processResolversData(response: any): void {
    try {
      // console.log('loadData response: ', response);
      this.processViews(response.views.body);
      this.processInsights(response.insights.body);
      this.processUserDashboards(response.userDashboards.body);
      this.processEmployeeCampaigns(response.employeeCampaigns.body);
      this.processSurveyFilters(response.surveyFilters.body);
      this.processSelfDashboardsFilters(response.selfDashboardsFilters.body);
      this.setMenuItemsVisibility();
    } catch (error) {
      console.error('Error processing resolvers data', error);
    }
  }

  setMenuItemsVisibility(): void {
    this.avatar = this.currentUser.avatar;
    this.userName = this.currentUser.userName.replace('null', '');
    _.each(this.menuItems.getAll(), mi => {
      // go through the permissions and set visibility
      this.setMenuItemVisibility(mi);
    });
    // hide toolkit if children are 0
    // console.log('menuitems ', this.menuItems.getAll());
    _.each(['toolkit', 'admin'], mi => {
      const m = _.find(this.menuItems.getAll(), i => i.state === mi);
      m.visible = _.compact(_.map(m.children, c => c.visible)).length > 0;
    });
    this.overrideVisibility();
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }

  setMenuItemVisibility(item: MainMenuNavigationItem | ChildMenuNavigationItem): void {
    const currentUser = this.authenticationService.getCurrentUser();
    item.visible = (this.isSurvaleAdmin ||
      (_.isNil(item.permission) ? true : this.hasPermission(item.permission, PermissionOptions.View)));


    if (!_.isNil(item.requiresSurvaleAdmin)) {
      item.visible = item.visible && currentUser.isSurvaleAdmin;
    }

    // console.log('>>> item :: ', item.name, item.visible);

    if (currentUser.clientSource === 'CANDE-MAIN') {
      _.each(this.userViews, v => {
        const viewDashboards = this.cache.getCache(this.cacheTypes.ViewDashboards, v.viewId);
        if (viewDashboards && viewDashboards.body) {
          const filteredDashboards =
            _.filter(viewDashboards.body, dashboard => item.name === dashboard.title && item.state === dashboard.id);
          if (filteredDashboards.length > 0) {
            item.visible = false;
          }
        }
      });
    }

    if (item.children && item.children.length > 0) {
      _.each(item.children, (child: ChildMenuNavigationItem) => this.setMenuItemVisibility(child));
    }
  }

  // redirectUser() {
  // console.log('redirecting user');
  // const viewsMenu = _.find(this.menuItems.getAll(), i => i.state === 'views');
  // console.log('viewsMenu', ( viewsMenu));
  // if (viewsMenu.children.length > 0) {
  //   // @ts-ignore
  //   const dashboardId = viewsMenu.children[0].children[1].state;
  //   const viewId = viewsMenu.children[0].state;
  //   // console.log('::this.router.url', viewId, dashboardId, this.router.url);
  //   setTimeout((): void => {
  //     console.log('redirecting to dashboard', this.router.url);
  //     if (this.router.url === '' || this.router.url === '/home') {
  //       // redirect to dashboard if url is blank
  //       this.navigateTo(this.router, ['/', 'views', viewId, 'dashboard', dashboardId])
  //     }
  //   }, 1000)
  // } else {
  //   // insight user
  //   const insightsMenu = _.find(this.menuItems.getAll(), i => i.state === 'insights');
  //   if (insightsMenu.children.length === 0) {
  //     // menu's have not loaded
  //     this.menuCheckerCount++;
  //     if (this.menuCheckerCount > 10) {
  //       console.log('exceeded');
  //     } else {
  //       setTimeout(() => this.redirectUser(), 1000);
  //     }
  //     return;
  //   }
  //   const insightId = insightsMenu.children[0].state;
  //   console.log('redirecting to insight', this.router.url, insightId);
  //   setTimeout((): void => {
  //     if (this.router.url === '' || this.router.url === '/home') {
  //       // redirect to dashboard if url is blank
  //       this.navigateTo(this.router, ['/', 'insights', insightId])
  //     }
  //   })
  // }
  // }

  addDashboardToView(id: number, viewId: number, title: string, dashboard: any): void {
    const viewsMenu = _.find(this.menuItems.getAll(), i => i.state === 'views');
    const viewMenuItem: any = _.find(viewsMenu.children, (v: any) => v.data.viewId === viewId);
    const dashboardMenuItem = {
      state: id,
      name: title,
      data: dashboard,
      type: 'link',
      children: []
    };
    const exists = _.find(dashboardMenuItem, dmi => dmi.state === id);
    if (!exists) {
      viewMenuItem.children.push(dashboardMenuItem);
    }
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }

  removeDashboardFromView(id: number, viewId: number): void {
    const viewsMenu: any = _.find(this.menuItems.getAll(), i => i.state === 'views');
    const viewMenuItem: any = _.find(viewsMenu.children, (v: any) => v.data.viewId === viewId);
    const deleteIndex = _.findIndex(viewMenuItem.children, (c: any) => c.state === id);
    viewMenuItem.children.splice(deleteIndex, 1);
  }

  processViews(userViews: any): void {
    try {
      this.userViews = userViews;
      console.log('getViewsForUser response', userViews);
      const viewsMenu = _.find(this.menuItems.getAll(), i => i.state === 'views');
      viewsMenu.children = viewsMenu.children || [];
      const allViewsMenuItems = [];
      if (_.isNil(this.userViews)) {
        return;
      }
      for (const v of this.userViews) {
        const viewMenuItem: MainMenuNavigationItem = {
          state: v.viewId.toString(),
          name: v.viewName,
          data: v, // this is probably not needed
          type: 'link',
          children: [],
          visible: true,
        };
        //   // cache should have all the dashboards for views
        const viewDashboards = this.cache.getCache(this.cacheTypes.ViewDashboards, v.viewId);
        if (viewDashboards && viewDashboards.body) {
          // _.each(viewDashboards.body, dashboard => {
          for (const dashboard of viewDashboards.body) {
            if (typeof (dashboard.dashboardJSON) === 'string') {
              dashboard.dashboardJSON = JSON.parse(dashboard.dashboardJSON);
            }
          }
          // });
          const dashboardMenuItems = [];
          if (ConfigService.config.loadDashboardsInMenu) {
            for (const dashboard of viewDashboards.body) {
              const dashboardMenuItem: MainMenuNavigationItem = {
                state: dashboard.id.toString(),
                name: dashboard.title,
                data: dashboard, // this is probably not needed
                type: 'link',
                children: [],
                visible: true
              };
              const currentUser = this.authenticationService.getCurrentUser();
              if (currentUser.clientSource === 'CANDE-MAIN') {
                dashboardMenuItem.visible = false;
              }
              dashboardMenuItems.push(dashboardMenuItem);
            }
            // console.log('dashboardMenuItems', dashboardMenuItems);
            if (dashboardMenuItems.length > 0) {
              // >> PROBLEM HERE <<<
              // viewMenuItem.children.splice(viewMenuItem.children.length - 1, 0, dashboardMenuItems);
              // console.log('viewMenuItem.children', viewMenuItem.children);
              viewMenuItem.children = viewMenuItem.children.concat(dashboardMenuItems);
            }
          } else {
            if (viewDashboards.body.length === 1) {
              const dashboard = viewDashboards.body[0];
              const dashboardMenuItem: MainMenuNavigationItem = {
                state: dashboard.id,
                name: dashboard.title,
                data: dashboard,
                type: 'link',
                children: [],
                visible: true
              };
              viewMenuItem.children.push(dashboardMenuItem);

            } else {
              const menuItem: MainMenuNavigationItem = {
                state: 'dashboards',
                name: 'Dashboards',
                type: 'link',
                children: [],
                visible: true
              };
              viewMenuItem.children.push(menuItem);
            }
          }
          if (viewMenuItem.children.length > 1) {
            // move Main dashboard to top
            for (const dashboardName of ['Main Dashboard', 'Default Dashboard']) {
              const idx = _.findIndex(viewMenuItem.children, c => c.name === dashboardName);
              if (idx > 0) {
                viewMenuItem.children.unshift(viewMenuItem.children.splice(idx, 1)[0]);
              }
            }
          }

          if ((this.isFeatureEnabled(AppFeatures.SentimentAnalysis, false) || this.isSurvaleAdmin) &&
            !this.isCande() && !this.isFeatureHidden(AppFeatures.SentimentAnalysis)) {
            const sentimentAnalysisMenuItem: MainMenuNavigationItem = this.sentimentAnalysisMenuItem();
            viewMenuItem.children.unshift(sentimentAnalysisMenuItem); // second item is survey-analysis
          }

          if ((this.isFeatureEnabled(AppFeatures.QBR, false) || this.isSurvaleAdmin) &&
            !this.isCande() && !this.isFeatureHidden(AppFeatures.QBR)) {
            const surveyAnalysisMenuItem: MainMenuNavigationItem = this.surveyAnalysisMenuItem();
            viewMenuItem.children.unshift(surveyAnalysisMenuItem); // second item is sentiment-analysis
          }
          const surveysMenuItem: MainMenuNavigationItem = this.surveysMenuItem();
          viewMenuItem.children.unshift(surveysMenuItem); // first item is surveys
          viewMenuItem.type = viewMenuItem.children.length > 0 ? 'sub' : 'link';
          allViewsMenuItems.push(viewMenuItem);
          // viewsMenu.children.push(viewMenuItem);
        }
      }

      // console.log('allViewsMenuItems', allViewsMenuItems, allViewsMenuItems.slice(0, 2));
      // >> PROBLEM HERE <<< <<<
      viewsMenu.children = allViewsMenuItems; // .slice(0, 2);
      // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());

      const routePath = this.router.url.split('/');
      console.log(`routePath - ${routePath}`);
      if (routePath[1] === 'home') {
        if (viewsMenu.children.length > 0) {
          const viewId = routePath[2] || viewsMenu.children[0].state;
          // @ts-ignore
          const dashboardId = viewsMenu.children[0].children[0].state;
          setTimeout((): void => {
            this.clickElement('accordionToggle-views', 0);
            this.clickElement(`accordionToggle-views-${viewId}`, 0);
          }, 100);
        } else {
          // insight user
          setTimeout((): void => {
            this.clickElement('accordionToggle-insights', 0);
            this.clickElement('accordionToggle-dashboards', 0);
          }, 100);
        }
      } else if (routePath[1] === 'insights') {
        // insights
        setTimeout((): void => {
          this.clickElement('accordionToggle-insights', 0);
        }, 100);
      } else if (routePath[1] === 'dashboards') {
        // dashboards
        console.log('dashboards');
        setTimeout((): void => {
          this.clickElement('accordionToggle-dashboards', 0);
        }, 100);
      } else {
        // all other routes
        setTimeout((): void => {
          // console.log(`accordionToggle-${routePath[1]}`);
          this.clickElement(`accordionToggle-${routePath[1]}`, 0);
          this.clickElement(`accordionToggle-${routePath[1]}-${routePath[2]}`, 0);
        }, 100);

      }
    } catch (error) {
      console.error('Error processing views', error);
    }
  }

  clickElement(id: string, attempt: number): void {
    const element: HTMLAnchorElement = document.getElementById(id) as HTMLAnchorElement;
    if (element) {
      // console.log('element', element);
      element.click();
    } else if (attempt < 5) {
      setTimeout((): void => this.clickElement(id, attempt + 1), 50);
    } else {
      console.log('element not found', id);
    }
  }

  public sentimentAnalysisMenuItem(): MainMenuNavigationItem {
    return {
      state: 'sentiment-analysis',
      name: 'Sentiment Analysis',
      type: 'link',
      children: [],
      permission: PermissionTypes.SentimentAnalysis,
      visible: true
    };
  }

  public surveyAnalysisMenuItem(): MainMenuNavigationItem {
    return {
      state: 'survey-analysis',
      name: 'Talent Impact',
      type: 'link',
      permission: PermissionTypes.TalentImpact,
      children: [],
      visible: true
    };
  }

  public surveysMenuItem(): MainMenuNavigationItem {
    return {
      state: 'surveys',
      name: 'Surveys',
      type: 'link',
      children: [],
      visible: true,
      customCssClass: 'nuii-pf-surveys'
    };
  }

  processInsights(insights) {
    // console.log('insights', insights);
    this.insights = insights;
    const menuItems = this.menuItems.getAll();
    const insightsMenuItem = _.find(menuItems, i => i.state === 'insights');
    // if(!insightsMenuItem)
    // insightsMenuItem.children = insightsMenuItem.children || [];
    insightsMenuItem.children = [];
    // @ts-ignore
    _.each(this.insights, v => insightsMenuItem.children.push({ state: v.insightId, name: v.insightName, data: v }));
    insightsMenuItem.visible = this.insights.length > 0;
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }

  overrideVisibility() {
    const menuItems = this.menuItems.getAll();
    const insightsMenuItem = _.find(menuItems, i => i.state === 'insights');
    insightsMenuItem.visible = this.insights.length > 0;
    const userDashboardsMenuItem = _.find(menuItems, i => i.state === 'dashboards');
    userDashboardsMenuItem.visible = this.dashboards.length > 0;
    // console.log('userDashboardsMenuItem', userDashboardsMenuItem);
    const viewsMenuItem = _.find(menuItems, i => i.state === 'views');
    viewsMenuItem.visible = this.userViews?.length > 0;
    const employeeCampaignResultsMenuItem = _.find(menuItems, i => i.state === 'employee-campaign-results');
    employeeCampaignResultsMenuItem.visible = (this.hasPermission(PermissionTypes.EmployeeCampaignResults) || this.isSurvaleAdmin) &&
      this.employeeCampaigns.length > 0;
    const filterMenuItem = _.find(menuItems, i => i.state === 'saved-user-filters');
    filterMenuItem.visible = this.surveyFilters.length > 0 || this.selfDashboardFilters.length > 0;
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }

  processUserDashboards(dashboards) {
    // console.log('dashboards', dashboards);
    this.dashboards = dashboards;
    const menuItems = this.menuItems.getAll();
    const menuItem = _.find(menuItems, i => i.state === 'dashboards');
    // if(!insightsMenuItem)
    // insightsMenuItem.children = insightsMenuItem.children || [];
    menuItem.children = [];
    _.each(this.dashboards, (v) => menuItem.children.push({
      visible: true,
      state: v.dashboardId,
      // subState: v.shareId,
      name: v.dashboardTitle,
      data: v
    }));
    menuItem.visible = this.dashboards.length > 0;
    // console.log('processUserDashboards', menuItem);
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }


  processEmployeeCampaigns(employeeCampaigns) {
    // console.log('insights', insights);
    this.employeeCampaigns = employeeCampaigns.campaigns;
    const menuItems = this.menuItems.getAll();
    const employeeCampaignsMenuItem = _.find(menuItems, i => i.state === 'employee-campaign-results');
    // if(!insightsMenuItem)
    // insightsMenuItem.children = insightsMenuItem.children || [];
    employeeCampaignsMenuItem.children = [];
    // @ts-ignore
    _.each(this.employeeCampaigns, v => employeeCampaignsMenuItem.children.push({ state: v.id, name: v.title, data: v }));
    employeeCampaignsMenuItem.visible = (this.employeeCampaigns || []).length > 0;
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }

  processSurveyFilters(surveyFilters) {
    this.surveyFilters = surveyFilters;
    this.processSavedUserFiltersMenu();
  }

  processSelfDashboardsFilters(selfDashboardFilters) {
    this.selfDashboardFilters = selfDashboardFilters;
    this.processSavedUserFiltersMenu();
  }

  processSavedUserFiltersMenu() {
    const menuItems = this.menuItems.getAll();
    const filterMenuItem = _.find(menuItems, i => i.state === 'saved-user-filters');
    if (_.isNil(filterMenuItem)) {
      return;
    }
    filterMenuItem.children = [];
    if (this.surveyFilters.length > 0) {
      const surveysMI: MainMenuNavigationItem = {
        visible: true,
        state: 'survey-insights',
        name: 'Surveys/Insights',
        children: [],
        type: 'sub',
      };
      filterMenuItem.children.push(surveysMI);
      // console.log('this.surveyFilters', this.surveyFilters);
      _.each(this.surveyFilters, v => surveysMI.children.push({
        visible: true,
        state: v.id,
        name: v.filterName,
        data: v
      }));
    }
    if (this.selfDashboardFilters.length > 0) {
      const dashboard: MainMenuNavigationItem = {
        visible: true,
        state: 'dashboards',
        name: 'Dashboards',
        children: [],
        type: 'sub',
      };
      filterMenuItem.children.push(dashboard);
      // console.log('this.selfDashboardFiltersFilters', this.selfDashboardFilters);
      _.each(this.selfDashboardFilters, v => dashboard.children.push({
        visible: true,
        state: v.dashboardId,
        additionalStates: [(v.filtered_dashboard_id || '-1'), v.id.toString()],
        name: v.title,
        data: v
      }));
    }
    filterMenuItem.visible = this.surveyFilters.length > 0 || this.selfDashboardFilters.length > 0;
    // this.finalMenuItems = _.cloneDeep(this.menuItems.getAll());
  }


  async signOut() {
    const viewsMenu = _.find(this.menuItems.getAll(), i => i.state === 'views');
    viewsMenu.children = [];
    await this.authenticationService.logout();
  }

  setTwoFactorAuthentication() {
    const opts = _.merge({ data: { user: this.currentUser } }, ConfigService.getDialogConfigWide());
    const d = this.dialog.open(TwoFaCodeComponent, opts);
  }

  isOver(): boolean {
    // if (this.url === '/apps/messages' || this.url === '/apps/calendar' || this.url === '/apps/media' || this.url === '/maps/leaflet') {
    //   return true;
    // } else {
    if (this.forceHideSideMenu) {
      return false;
    }
    return window.matchMedia('(max-width: 960px)').matches;
    // }
  }

  isMac(): boolean {
    let bool = false;
    // navigator.platform is not deprecated https://stackoverflow.com/questions/38506517/deprecated-javascript-os-detection-techniques
    // eslint-disable-next-line deprecation/deprecation
    const platform = navigator.platform.toUpperCase();
    if (platform.indexOf('MAC') >= 0 || platform.indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  menuMouseOver(): void {
    if (window.matchMedia('(min-width: 960px)').matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia('(min-width: 960px)').matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }

  menuToggleFunc() {
    this.sidemenu.toggle();
    this.collapsedClass = this.collapsedClass === 'side-panel-opened' ? 'side-panel-closed' : 'side-panel-opened';
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
  }

  openDateRangePicker(e: any) {
    const el: HTMLElement = document.getElementById('main-data-range-picker') as HTMLElement;
    setTimeout(() => el.click(), 0);
    // toggle does not work
    // this.pickerDirective.toggle();
    // this.pickerDirective.open does not work
    // this.pickerDirective.open(e);
  }

  rangeClicked(range) {
    // suppress the emittor for date range for some time
    this.enableDateRangeEventEmitor = false;

    // update the model
    this.selectedDateRange.startDate = this.configService.getDateRangePickerOptions().ranges[range.label][0];
    this.selectedDateRange.endDate = this.configService.getDateRangePickerOptions().ranges[range.label][1];

    const xpath = '//button[text()=\'Apply\']';
    const nativeElement = this.pickerDirective.viewContainerRef.element.nativeElement;
    const matchingElement = document.evaluate(xpath, nativeElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    setTimeout(() => (matchingElement as HTMLElement).click(), 0);
    this.pickerDirective.toggle();
    // console.log('AdminLayoutComponent :: [rangeClicked] range is : ', range, this.selectedDateRange.startDate.toISOString());
    const dr = new EventsServiceDateRange(this.selectedDateRange.startDate, this.selectedDateRange.endDate);
    this.eventsService.eventDateRangeLabelChanged(range.label, dr);
    // enable the emmitor if range is selected manually
    setTimeout(() => this.enableDateRangeEventEmitor = true, 100);
  }

  datesUpdated(range) {
    if (this.enableDateRangeEventEmitor) {
      // console.log('AdminLayoutComponent :: [datesUpdated] range is : ', range, this.selectedDateRange.startDate.toISOString());
      const dr = new EventsServiceDateRange(this.selectedDateRange.startDate, this.selectedDateRange.endDate);
      this.eventsService.emitDateRangeChanged(dr);
    }
  }

  showVideo() {
    this.candeMenuClicked = true;
    const options = {
      // id: 698799494,
      url: 'https://player.vimeo.com/video/698799494?h=578a45a145&badge=0&autopause=0&player_id=0&app_id=58479',
      width: 640
    };

    // const video = new Player('video', options);
    setTimeout((): void => {
      const video = new Player('video');
    }, 500);

    // player.on('play', function() {
    //   console.log('played the video!');
    // });
  }

  // changeMenuLayout(value) {
  //   console.log(value)
  //   if (value) {
  //     this.menuLayout = 'top-menu';
  //   } else {
  //     this.menuLayout = 'vertical-menu';
  //     this.menuToggleFunc();
  //   }
  // }
  //
  // onSelectSidebarImage(selectedClass, event) {
  //   this.selectedSidebarImage = selectedClass;
  // }
  //
  // onSelectedSidebarColor(selectedClass) {
  //   this.selectedSidebarColor = selectedClass;
  // }
  //
  // onSelectedHeaderColor(selectedClass) {
  //   this.selectedHeaderColor = selectedClass;
  // }
  //
  // isBgActive(value) {
  //   if (value == this.selectedSidebarImage) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  //
  // isSidebarActive(value) {
  //   if (value == this.selectedSidebarColor) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  //
  // isHeaderActive(value) {
  //   if (value == this.selectedHeaderColor) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // addMenuItem(): void {
  //   this.menuItems.add({
  //     state: 'menu',
  //     name: 'MENU',
  //     type: 'sub',
  //     icon: 'trending_flat',
  //     children: [
  //       {state: 'menu', name: 'MENU'},
  //       {state: 'timelmenuine', name: 'MENU'}
  //     ]
  //   });
  // }
}
