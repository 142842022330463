<div fxLayout="row wrap">
  <div fxFlex="100%" class="m-gap p-gap text-right">
    <a mat-raised-button class="mat-green" routerLink="assign"
       *ngIf="hasPermission(permissionTypes.Roles, permissionOptions.Edit)">Assign admin</a>
    <a mat-raised-button class="role-btn mat-green"
       *ngIf="hasPermission(permissionTypes.Roles, permissionOptions.Edit)"
       routerLink="add">Add New Role</a>
  </div>
</div>

<mat-card>
  <mat-card-header>
    <mat-card-title class="title">Manage Roles</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="roles" class="mat-elevation-z8">

      <!-- Role title Column -->
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef> Role Title</th>
        <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
      </ng-container>

      <!-- Permissions Column -->
      <ng-container matColumnDef="permissions">
        <th mat-header-cell *matHeaderCellDef class="permissions"> Permissions</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-list aria-label="Permissions">
            <span *ngFor="let permission of element.permissions">
              <mat-chip *ngIf="permission">{{permission}}</mat-chip>
            </span>
          </mat-chip-list>
        </td>
      </ng-container>

      <!-- Controls Column -->
      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef> Controls</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button [routerLink]="['/admin/roles', element.roleId]">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-stroked-button class="role-btn" (click)="deleteRole(element.roleId)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
