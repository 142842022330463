import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService, SurveyQuestionTypes, WidgetFilterKeyType } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { DashboardFilter, EventsServiceDateRange, SurveyFilter } from './events.service';
import * as moment from 'moment';

@Injectable()

export class SurveysResultsService extends BaseHttpService {
  private resource = 'survey';

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getSurveysByCompletions(surveyList: any[], timePeriod: EventsServiceDateRange): Observable<any> {
    const tsObj = this.getTsObj(timePeriod);
    const params = { surveyList, timePeriod: JSON.stringify(tsObj) };
    return this.get('survey-results/surveys-applied', undefined, params);
  }

  /**
   * getIndexMetricData survey-results-service.js - getFollowUpByTag
   * prepareIndexMtr
   * @param tag
   * @param surveyList
   * @param dateType [hired|created]
   * @param timePeriod
   * @param currentPostIds used in the surveys tab
   * @param viewId probably not used
   */
  getFollowUpByTag(tag: string, surveyList: any[], dateType: string, timePeriod: EventsServiceDateRange, currentPostIds = undefined,
    viewId = undefined, filters: DashboardFilter[] = [], shareId = undefined, dashboardId = undefined): any {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      tag,
      timePeriod: JSON.stringify(tsObj),
      surveyList,
      dateType,
      currentPostIds,
      viewId,
      shareId,
      dashboardId,
      'cache-buster': (new Date()).getTime()
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }
    return this.get('survey-results/before-after-by-tag', undefined, params);
  }

  getSurveyStats(surveyList: any[], timePeriod: EventsServiceDateRange, filters: any[], surveyId: any, resultType: any): any {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      timePeriod: JSON.stringify(tsObj),
      surveyList,
      filters: JSON.stringify(filters),
      surveyId,
      resultType,
    };
    return this.get('survey-results/surveys-filled', undefined, params);
  }

  addSurveyLink(surveyId: any, surveyHash: any, label: any, hiddenAnswersJSON: any): any {
    const payload = {
      surveyId,
      surveyHash,
      label,
      hiddenAnswersJSON
    };

    return this.post('add-survey-link', payload);
  }


  correctAnswer(params: any): any {
    const payload = {
      surveyId: params.surveyId,
      questionId: params.questionId,
      oldAnswer: params.oldAnswer,
      newAnswer: params.newAnswer,
      replaceAcrossSurveys: params.replaceAcrossSurveys,
    };

    return this.post('correct-multichoice-answer', payload);
  }


  getSurveyLinks(surveyId: any): any {
    const params = {
      surveyId
    };

    return this.get('fetch-survey-links', undefined, params);
  }

  getDeviceTypes(surveyList: any[], timePeriod: EventsServiceDateRange): any {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      timePeriod: JSON.stringify(tsObj),
      surveyList,
    };
    return this.get('survey-results/device-types', undefined, params);
  }

  getNPSHistogram(questionIds: Array<number>, timePeriod: EventsServiceDateRange, surveyList: Array<number>,
    filters: DashboardFilter[], shareId = undefined, dashboardId = undefined): any {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      questionIds: questionIds.join(','),
      timePeriod: JSON.stringify(tsObj),
      surveyList: surveyList.join(','),
      shareId,
      dashboardId,
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }
    return this.get('survey-results/nps-histogram', undefined, params);
  }

  getIndexTrendHistogram(index: string, timePeriod: EventsServiceDateRange,
    surveyList: Array<number> = undefined, filters: DashboardFilter[] = [],
    shareId = undefined, dashboardId = undefined): any {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      tag: index,
      timePeriod: JSON.stringify(tsObj),
      shareId,
      dashboardId,
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }
    if (surveyList) {
      params.surveyList = surveyList.join(',');
    }
    return this.get('survey-results/tagHistogram', undefined, params);
  }

  getOperationalFieldHistogram(questionId: any, fieldValues: any, timePeriod: EventsServiceDateRange, surveyList: Array<number>): any {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      questionId,
      surveyList,
      fieldValues,
      timePeriod: JSON.stringify(tsObj),
      r: 1
    };
    return this.get('survey-results/operational-field-histogram', undefined, params);

  }

  getNPSMetricsData(questionIds: Array<number>, timePeriod: EventsServiceDateRange,
    surveyList: Array<number>, filters: DashboardFilter[] = [], shareId = undefined, dashboardId = undefined): any {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      questionIds: questionIds.join(','),
      timePeriod: JSON.stringify(tsObj),
      surveyList: surveyList.join(','),
      shareId,
      dashboardId,
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }
    return this.get('survey-results/before-after-by-NPS', undefined, params);
  }

  getNPSBenchmarkData(questionIds: Array<number>, timePeriod: EventsServiceDateRange, surveyList: Array<number>, benchmark) {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      questionIds: questionIds.join(','),
      timePeriod: JSON.stringify(tsObj),
      surveyList: surveyList.join(','),
      benchmark
    };
    return this.get('survey-results/before-after-by-NPS-benchmarks', undefined, params);
  }

  getTableWidgetData(viewId, parameters, byDate, filters, dashboardFilters: DashboardFilter[] = [],
    shareId = undefined, dashboardId = undefined) {
    // ignore empty filters
    filters = _.compact(_.map(filters, o => _.isEmpty(o) ? null : o));
    const params: any = {
      surveyId: parameters.selSurvey.id,
      valueType: parameters.valueType,
      rowQuestionId: parameters.selRow.questionId,
      valueQuestionIds: parameters.valueQuestionIds,
      dateRange: parameters.selDateRange ? parameters.selDateRange.value : null,
      byDate,
      dateOptions: parameters.dateOptions ? parameters.dateOptions : null, // TODO: don't send this
      dateTimePeriod: _.get(parameters, 'dateTimePeriod' , 'monthly'), // TODO: implement in backend
      monthlyTimeFrame: _.get(parameters, 'monthlyTimeFrame' , '6 months'), // TODO: implement in backend
      columnQuestionId: parameters.selQuestion ? parameters.selQuestion.questionId : null,
      filters: filters.length > 0 ? filters : undefined,
      viewId,
      surveyList: (parameters.selectedDashboardSurveys.map((sds) => sds.id).concat([parameters.selSurvey.id]).join(',')),
      // monthlyTimeFrame: parameters.monthlyTimeFrame,
    };
    if (parameters.valueType === 'index') {
      params.tag = parameters.index;
    } else {
      params.valueQuestionType = parameters.valueQuestionType;
    }
    if (!_.isEmpty(dashboardFilters)) {
      params.dashboardFilters = (dashboardFilters);
    }

    // params = _.omitBy(params, _.isNil); // Checks if value is null or undefined.
    // console.log('~~params: ', params);
    return this.get('survey-results/dashboard-table-data', undefined, {
      shareId,
      dashboardId,
      params: encodeURIComponent(JSON.stringify(params))
    });
  }


  getTableWidgetMCData(viewId: any, parameters: any, filters: any[], dateRange: any, dashboardFilters: DashboardFilter[] = [],
    shareId = undefined, dashboardId = undefined): any {
    // ignore empty filters
    filters = _.compact(_.map(filters, o => _.isEmpty(o) ? null : o));
    const params: any = {
      surveyId: parameters.selSurvey.id,
      rowQuestionId: parameters.selRow.questionId,
      dateRange,
      columnQuestionId: parameters.selQuestion ? parameters.selQuestion.questionId : null,
      columnQuestionType: parameters.selQuestion ? parameters.selQuestion.questionType : null,
      filters: filters.length > 0 ? filters : undefined,
      viewId,
      surveyList: (parameters.selectedDashboardSurveys.map((sds) => sds.id).concat([parameters.selSurvey.id]).join(',')),
      monthlyTimeFrame: parameters.monthlyTimeFrame,
    };
    if (!_.isEmpty(dashboardFilters)) {
      params.dashboardFilters = (dashboardFilters);
    }
    // params = _.omitBy(params, _.isNil); // Checks if value is null or undefined.
    // console.log('~~params: ', params);
    return this.get('survey-results/dashboard-table-mc-data', undefined, {
      shareId,
      dashboardId,
      params: encodeURIComponent(JSON.stringify(params))
    });
  }

  getTableWidgetCompletionData(
    viewId: any, parameters: any, dashboardFilters: any[], dateRange: any, shareId = undefined, dashboardId = undefined
  ): any {
    // ignore empty filters
    const params: any = {
      surveyId: parameters.selSurvey.id,
      rowQuestionId: parameters.selRow.questionId,
      dateRange,
      viewId,
      surveyList: (parameters.selectedDashboardSurveys.map((sds) => sds.id).concat([parameters.selSurvey.id]).join(',')),
      monthlyTimeFrame: parameters.monthlyTimeFrame,
    };

    if (!_.isEmpty(dashboardFilters)) {
      params.dashboardFilters = (dashboardFilters);
    }
    // params = _.omitBy(params, _.isNil); // Checks if value is null or undefined.
    // console.log('~~params: ', params);
    return this.get('survey-results/dashboard-table-completion-data', undefined, {
      shareId,
      dashboardId,
      params: encodeURIComponent(JSON.stringify(params))
    });
  }

  getCustomQuestions(surveyId: number | string): any {
    return this.get(`survey-results/custom-questions/${surveyId}`);
  }

  getUniqCustomAnswers(
    surveyId: string | number, questionId: string | number, dashboardFilters: DashboardFilter[] = [],
    shareId: string | number, sortOrder: string | number, dashboardId: string | number
  ): any {
    const params: any = { questionId, shareId, sortOrder, dashboardId };
    if (!_.isEmpty(dashboardFilters)) {
      params.dashboardFilters = encodeURIComponent(JSON.stringify(dashboardFilters));
    }
    return this.get(`survey-results/unique-custom-answers/${surveyId}`, undefined, params);
  }

  getCommentsFeed(
    timePeriod: EventsServiceDateRange,
    surveyList: Array<number | string>,
    limit = 10, offset = 0,
    rangeFrom: number = undefined,
    rangeTo: number = undefined,
    searchTerm: string = undefined,
    dashboardFilters: DashboardFilter[] = [],
    shareId = undefined,
    dashboardId = undefined,
    questionList = ''
  ) {
    const tsObj = timePeriod ? this.getTsObj(timePeriod) : timePeriod;
    let params: any = {
      surveyList: surveyList.join(','),
      rangeFrom,
      rangeTo,
      searchTerm,
      limit,
      offset,
      shareId,
      questionList
    };
    if (timePeriod) {
      params.timePeriod = JSON.stringify(tsObj);
    }
    if (!_.isEmpty(dashboardFilters)) {
      params.filters = encodeURIComponent(JSON.stringify(dashboardFilters));
    }

    params = _.omitBy(params, _.isNil); // Checks if value is null or undefined.
    return this.get('survey-results/comments', undefined, params);
  }

  getAllIndexes() {
    return this.get('question-tags', undefined, undefined);
  }

  getSurveyResponse(surveyId: number, timePeriod: EventsServiceDateRange, excludeQuestionIds = '') {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = { timePeriod: JSON.stringify(tsObj) };
    if (excludeQuestionIds.length > 0) {
      params.excludeQIds = excludeQuestionIds;
    }

    return this.get('survey-results/survey-responses', surveyId.toString(), params);
  }

  getFiltersObj(filters: Array<SurveyFilter>) {
    const filtersToSend = _.map(filters, (filter: SurveyFilter) =>
      _.pick(filter, ['questionId', 'selectedValues', 'filterType'])
    );
    _.each(filtersToSend, f => f.selectedValues = _.flatten(f.selectedValues));
    // console.log('filtersToSend', filtersToSend);
    return JSON.stringify(filtersToSend);
  }

  /**
   * needs surveyId or surveyList, if both are passed then backend prefers `surveyId`
   * @param filters
   * @param timePeriod
   * @param surveyId
   * @param surveyList
   * @param limit
   * @param offset
   */
  getFilteredSurveyRecords(
    filters: Array<SurveyFilter>, timePeriod: EventsServiceDateRange,
    surveyId: number | string, surveyList: Array<number> = undefined,
    limit = 10, offset = 0
  ) {
    const tsObj = this.getTsObj(timePeriod);
    let params: any = {
      timePeriod: JSON.stringify(tsObj),
      surveyList: surveyList ? surveyList.join(',') : undefined,
      filters: encodeURIComponent(this.getFiltersObj(filters)),
      surveyId,
      limit,
      offset
    };
    params = _.omitBy(params, _.isNil); // Checks if value is null or undefined.
    return this.get('survey-results/filtered-survey-records', undefined, params);
  }

  compareQuestionResult(timePeriod: EventsServiceDateRange, surveyId: number, srcQ: any, tgtQ: any, widgetType = 'questionChart') {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      timePeriod: JSON.stringify(tsObj),
      surveyId,
      srcQ: JSON.stringify(srcQ),
      tgtQ: JSON.stringify(tgtQ),
      widgetType
    };
    // console.log(_.forOwn(params, encodeURIComponent))
    return this.get('survey-results/compare-questions-results', undefined, params);
  }

  // getFilteredResponses(
  //   surveyId: number,
  //   timePeriod: EventsServiceDateRange,
  //   surveyTitle: string,
  //   clientCategories: Array<string>,
  //   clientSource: string,
  //   metricQuestions: Array<{ questionId: number, tags: string }>, // this is to get the benchmark data
  //   filterQuestions: Array<SurveyFilter>
  // ) {
  //   // TODO: this should be a get
  //   const tsObj = this.getTsObj(timePeriod);
  //   const params: any = {
  //     timePeriod: JSON.stringify(tsObj),
  //     surveyId,
  //     passSurveyId: false,
  //   };
  //   const srcQs: any = _.cloneDeep(filterQuestions);
  //   _.each(srcQs, (fq) => fq.key = fq.keys.join(','));
  //   const payload: any = {
  //     clientSource,
  //     metricQuestions, // this is the array of all bench-markable questions
  //     srcQs
  //   };
  //   if (clientCategories && clientCategories.length > 0) {
  //     payload.clientCategories = clientCategories;
  //   }
  //   return this.post('survey-results/multiple-filtered-responses', payload, params);
  // }

  getOneSurveyResponse(surveyId) {
    return this.get(`survey-results/one-survey-responses/${surveyId}`);
  }

  updateSurveyResponses(postId, fieldsToUpdate) {
    // const payload = {surveyId, campaignId, emailItemId, fieldsToUpdate};
    const payload = { postId, fieldsToUpdate };
    return this.post('survey-results/update-survey-responses', payload);
  }

  getEmployeeBenchmarks(benchmarkTitle, questionType, timePeriod) {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      benchmarkTitle,
      questionType,
      timePeriod: JSON.stringify(tsObj),
    };

    return this.get('fetch-employee-benchmark', undefined, params);
  }

  getSurvaleBenchmarks(benchmarkTitle, classificationTitle, classificationValue, questionType, timePeriod: EventsServiceDateRange) {
    // req.query.benchmarkTitle, req.query.classificationTitle, req.query.classificationValue, data.clientId, req.query.timePeriod
    const tsObj = this.getTsObj(timePeriod);
    // for survale benchmarks always include last 1 year
    tsObj.startDate = moment(tsObj.endDate).add(-1, 'year').toISOString();
    const params: any = {
      benchmarkTitle,
      questionType,
      classificationTitle,
      classificationValue,
      timePeriod: JSON.stringify(tsObj),
    };
    if (!classificationTitle) {
      delete params.classificationTitle;
    }
    if (!classificationValue) {
      delete params.classificationValue;
    }
    return this.get('survale-benchmarks', undefined, params);
  }

  getSurveyQuestionResponse(filters: Array<SurveyFilter>, surveyId: string, employeeCampaignId: number,
    widgetFilterKey: WidgetFilterKeyType, stepIndex: number = 0,
    consolidatedView: string, questionId: string, questionType: SurveyQuestionTypes,
    timePeriod: EventsServiceDateRange, category: string,
    parentSurveyId: number, selectedSurveys: any,
    selectedQuestionIds: number[], shareId, dashboardId) {
    const tsObj = this.getTsObj(timePeriod);
    const clientSource = this.authenticationService.getCurrentUser().clientSource;
    // this needs to be done for cande so that cached response id not used when they login as different clients
    const cId = this.authenticationService.getCurrentUser().clientId;

    // const workingQuestionTypes = [SurveyQuestionTypes.MultipleChoice];
    const workingQuestionTypes = [
      SurveyQuestionTypes.HiLowScale,
      SurveyQuestionTypes.NPS,
      SurveyQuestionTypes.MultipleSelect,
      SurveyQuestionTypes.MultipleChoice,
      SurveyQuestionTypes.MatrixQuestion,
      SurveyQuestionTypes.MatrixS1_10,
      SurveyQuestionTypes.MatrixS1_5,
      SurveyQuestionTypes.MatrixS1_3,
      SurveyQuestionTypes.MatrixGroupS1_5,
      SurveyQuestionTypes.MatrixGroupS1_3,
      SurveyQuestionTypes.MatrixGroupS1_10
    ];
    const params: any = {
      cId,
      surveyId,
      employeeCampaignId,
      filters: encodeURIComponent(this.getFiltersObj(filters)),
      clientSource,
      category,
      timePeriod: JSON.stringify(tsObj),
      consolidated: consolidatedView,
      questionType,
      parentSurveyId,
      stepIndex,
      sQIds: selectedQuestionIds,
      shareId,
      dashboardId,
    };
    if (selectedSurveys && selectedSurveys.length > 0) {
      params.selectedSurveys = _.map(selectedSurveys, 'id');
    }
    if (widgetFilterKey === WidgetFilterKeyType.EmployeeCampaignId) {
      delete params.surveyId;
    } else {
      delete params.employeeCampaignId;
    }
    // if (questionId.toString() === '5974') {
    if (workingQuestionTypes.indexOf(questionType) > -1) {
      return this.get('survey-results/individual-question-responses-ex', questionId, params);
    } else {
      console.error(questionType, 'not yet implemented');
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  getCompletionData(surveyId, timePeriod: EventsServiceDateRange, filters, surveyList = '') {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      surveyId,
      timePeriod: JSON.stringify(tsObj),
      filters: JSON.stringify(filters),
      surveyList
    };
    return this.get('survey-results/surveys-applied', undefined, params);
  }

  getCompletionHistogram(surveyId, timePeriod: EventsServiceDateRange, filters) {
    const tsObj = this.getTsObj(timePeriod);
    const params = { surveyId, timePeriod: JSON.stringify(tsObj), filters: JSON.stringify(filters) };
    return this.get('survey-results/submit-histogram', undefined, params);
  }


  /**
   *
   * @param filters
   * @param surveyId
   * @param searchTerm
   * @param timePeriod
   * @param limit
   * @param offset
   * @param rangeFrom
   * @param rangeTo
   * @param shareId
   * @param dashboardId
   */
  getFilteredComments(
    filters: Array<SurveyFilter>, surveyId: number, searchTerm: string, timePeriod: EventsServiceDateRange,
    limit = 10, offset = 0, rangeFrom = undefined, rangeTo = undefined,
    shareId = undefined, dashboardId = undefined
  ) {
    const tsObj = this.getTsObj(timePeriod);
    const body = {
      timePeriod: JSON.stringify(tsObj),
      filters: encodeURIComponent(this.getFiltersObj(filters)),
      limit,
      surveyId,
      offset,
      rangeFrom,
      rangeTo,
      shareId,
      dashboardId,
    };
    return this.get('survey-results/filtered-comments', undefined, body);
  }

  getNPSSurveyResponse(surveyId: number, timePeriod: EventsServiceDateRange, includeQuestionIds = '') {
    const tsObj = this.getTsObj(timePeriod);
    const params: any = { timePeriod: JSON.stringify(tsObj) };
    if (includeQuestionIds.length > 0) {
      params.includeQIds = includeQuestionIds;
    } else {
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
    return this.get('survey-results/survey-nps-responses', surveyId.toString(), params);
  }

  getSurveysApplied(surveyId, timePeriod: EventsServiceDateRange) {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      surveyList: surveyId,
      timePeriod: JSON.stringify(tsObj)
    };
    return this.get('survey-results/surveys-applied', undefined, params);
  }

  getSurveySubmitHistogram(surveyId, timePeriod: EventsServiceDateRange) {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      surveyId,
      timePeriod: JSON.stringify(tsObj)
    };
    return this.get('survey-results/submit-histogram', undefined, params);
  }

  getBenchmarkData(questionId: string | number, surveyId: string | number, tag: string,
    questionType: SurveyQuestionTypes, timePeriod: EventsServiceDateRange) {
    // clientSource: CANDE
    // limit: 10
    // offset: 0
    // questionId: 5680
    // questionType: Multiple Choice
    // surveyId: 1474
    // tag: CandeApplyAgain_2016
    // timePeriod: {"startDate":"2019-01-01T08:00:00.000Z","endDate":"2019-12-05T16:59:59.000Z"}
    const tsObj = this.getTsObj(timePeriod);
    const clientSource = this.authenticationService.getCurrentUser().clientSource;
    const params = {
      clientSource,
      questionId,
      surveyId,
      questionType,
      limit: 10,
      offset: 0,
      timePeriod: JSON.stringify(tsObj)
    };
    return this.get('survey-results/survey-benchmarks', undefined, params);
  }

  getSurveyComments(limit, offset, from, to, term, id) {
    const params = {
      limit,
      offset,
      rangeFrom: from,
      rangeTo: to,
      searchTerm: term,
      surveyId: id,
      timePeriod: ''
    };
    return this.get('survey-results/comments', undefined, params);
  }


  getTagCloud(id, timePeriod: EventsServiceDateRange, surveyList, filters: DashboardFilter[] = [], shareId, dashboardId,
    searchTerm = undefined, questionId = 0) {
    console.log(id);
    let cacheBuster;
    if (id.toString() === '0') {
      cacheBuster = this.authenticationService.getCurrentUser().userId;
    }
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      limit: 10,
      offset: 0,
      surveyId: id,
      surveyList,
      timePeriod: JSON.stringify(tsObj),
      shareId,
      dashboardId,
      searchTerm,
      cacheBuster,
      questionId
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }

    return this.get('survey-results/tag-cloud', undefined, params);
  }

  downloadFilteredComments(id, timePeriod: EventsServiceDateRange, surveyList, filters: DashboardFilter[] = [], shareId) {
    console.log(id);
    let cacheBuster;
    if (id.toString() === '0') {
      cacheBuster = this.authenticationService.getCurrentUser().userId;
    }
    const tsObj = this.getTsObj(timePeriod);
    const params: any = {
      surveyId: id,
      surveyList,
      timePeriod: JSON.stringify(tsObj),
      cacheBuster,
      shareId
    };
    if (!_.isEmpty(filters)) {
      params.filters = encodeURIComponent(JSON.stringify(filters));
    }

    return this.post('survey-results/download-filtered-comments', undefined, params);
  }


  resendEmail(id, payload) {
    payload.campaign = JSON.stringify(payload.campaign);
    return this.post('reports/resend-campaign-email/' + id, payload);
  }

  resendEmployeeEmail(id, payload) {
    payload.campaign = JSON.stringify(payload.campaign);
    return this.post('reports/resend-employee-campaign-email/' + id, payload);
  }


  getMultipleChoiceOtherAnswersData(surveyId, questionId, size, offset, dateType) {
    const params = {
      surveyId,
      size,
      offset,
      dateType
    };

    return this.get('survey-results/multiple-choice-other-answers', questionId, params);

  }

  candidateCampaignDetails(email: string) {
    const params = { email: encodeURIComponent(email) };
    return this.get('candidate-campaign-details', undefined, params, undefined, false);
  }

  getSummarySurveys(surveyList: any[], filters: any[], lookBackTimePeriod: string, groupByTimePeriod: string): any {
    const params = {
      surveyList,
      filters: JSON.stringify(filters),
      lookBackTimePeriod,
      groupByTimePeriod
    };
    return this.get('survey-results/surveys-summary', undefined, params);
  }

  getSummaryCampaigns(surveyList: any[], filters: any[], lookBackTimePeriod: string, groupByTimePeriod: string): any {
    const params = {
      surveyList,
      filters: JSON.stringify(filters),
      lookBackTimePeriod,
      groupByTimePeriod
    };
    return this.get('survey-results/campaigns-summary', undefined, params);
  }

  getSummaryEmailLists(lookBackTimePeriod: string, groupByTimePeriod: string): any {
    const params = {
      lookBackTimePeriod,
      groupByTimePeriod
    };
    return this.get('email-list-summary', undefined, params);
  }

  getSummaryEmailDelivery(timePeriod: EventsServiceDateRange, pivotField: string, surveyList: any[]): any {
    const tsObj = this.getTsObj(timePeriod);
    const params = {
      timePeriod: JSON.stringify(tsObj),
      pivotField,
      surveyList
    };
    return this.get('email-delivery-summary', undefined, params);
  }

  getSurveyResponsesByQuestionId(
    searchTerm: string, surveyIds: number[], questionId: number, 
    from: number, size: number,
    dashboardFilters: DashboardFilter[],  monthlyTimeFrame: string = '12 Months'
  ): any {
    const params = {
      searchTerm,
      surveyIds,
      questionId,
      dashboardFilters,
      monthlyTimeFrame,
      from,
      pageSize: size
    };
    return this.get('survey-results/survey-responses-by-question-id', questionId.toString(), params);
  }

}
