// Core modules
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

// Application services
import { BaseHttpService } from './base-http.service';
import { Subject } from 'rxjs';

@Injectable()

export class EmailListService extends BaseHttpService {

  public subFormValues = new Subject();
  public getFormValues = this.subFormValues.asObservable();
  private isEmployeeList = false;

  /**
   * Returns the email list
   * @param limit limit of items
   * @param offset beginning of next list items
   */
  getEmailLists(limit = 10, offset = 0, contains = '') {
    const params: any = {
      limit,
      offset,
      contains,
      loadCounts: true
    };
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-all';

    return this.get(resource, undefined, params);
  }  

  getAllEmailListRecords() {
    const resource = (this.isEmployeeList ? 'all-employee-list-records' : 'all-email-list-records');
    return this.get(resource, undefined, {});
  }

  setIsEmployeeList(val: boolean) {
    this.isEmployeeList = val;
  }

  getEmailList(id) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list';
    return this.get(resource, id);
  }

  saveEmailList(payload) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list';
    return this.post(resource, payload);
  }

  /**
   * Return the email based upon the id
   * @param id id of email
   */
  getEmailListItem(id: string) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-content/0';
    return this.get(resource, id);
  }

  /**
   * Return email contents based upon id
   * @param emailId email id
   */
  getListContent(emailId, offset, limit, fromts, searchTerm = '', nextToken) {
    const resource = (searchTerm ? 'filtered-' : '') + (this.isEmployeeList ? 'employee' : 'email') + '-list-contents';
    const param: any = {
      limit,
      offset,
      fromts,
      key: encodeURIComponent(searchTerm.toLowerCase().trim()),
      listId: emailId,
      employeeListId: emailId,
    };

    if (nextToken !== undefined) {
      const params: any = {
        limit,
        offset,
        fromts,
        key: encodeURIComponent(searchTerm.toLowerCase().trim()),
        listId: emailId,
        employeeListId: emailId,
        nextToken: encodeURIComponent(nextToken)
      };
      if (searchTerm) {
        return this.get(resource, undefined, param);
      } else {
        return this.get(resource, emailId, params);
      }
      // return this.get(resource, emailId, params);
    }
    if (searchTerm) {
      return this.get(resource, undefined, param);
    } else {
      return this.get(resource, emailId, param);
    }
    // return this.get(resource, emailId, param);

  }

  /**
   * Return email custom fields based upon email id
   * @param emailId Email id
   */
  getEmailCustomFields(emailId) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list';
    return this.get(resource, emailId, '');
  }

  /**
   * Get a email list extracted fields for index column
   */
  getEmailListExtractedFields() {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-extracted-fields';
    return this.get(resource, '', '');
  }

  /**
   * Update email title
   * @param id email id
   * @param payload request payload
   */
  updateTitle(id: string, payload: any) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-name';
    return this.put(resource, payload, id, '');
  }

  deleteListItemRec(id: string) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-contents';
    return this.delete(resource, id);
  }

  /**
   * Save as new
   * @param id email id
   * @param payload request payload
   */
  saveEmail(id: string, listId: string, payload: any, editViaViewResponse: boolean) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-contents' + `/${listId}`;
    if (id) {
      return this.put(resource, payload, id, {editViaViewResponse});
    }
    return this.post(resource, payload);
  }

  /**
   * Get campaign logs
   * @param id email id
   * @param listId list id
   */
  getCampaignLogs(id, listId: string) {
    const resource = 'campaign/logs';
    return this.get(`${resource}/${listId}`, id, '');
  }

  /**
   * Gets matching campaigns
   * @param listId email id
   */
  getMatchingCampaigns(listId: string) {
    const resource = 'campaigns/email-list';
    return this.get(resource, listId, '');
  }

  /**
   * Trigger campaigns
   * @param listId email id
   */
  triggerCampaign(emailListItemId, listId, campaignId) {
    const payload = {
      emailListItemId,
      emailListId: listId,
      campaignId
    };
    return this.post('campaign/trigger-campaign', payload, '');
  }

  /**
   * Save as new
   * @param id email id
   * @param payload request payload
   */
  updateAsNewEmail(id: string, payload: any, listId: any) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-list-contents';
    return this.put(`${resource}/as-new/${id}`, payload, listId);
  }

  /**
   * Adds Survale Refer Email List
   */
  addSurvaleReferEmailList() {
    return this.post('create-survale-refer-email-list', {});
  }

  /**
   * Sets form values
   * @param values value
   */
  setFormValues(values) {
    this.subFormValues.next(values);
  }

  getDataRetentionRules(id: string) {
    return this.get('email-list-retention-rules', id);
  }

  getDataRetentionLogs(id: string, ignoreEmpty: boolean, offset) {
    return this.get('email-list-retention-logs', id, {ignoreEmpty, offset});
  }

  setDataRetentionRules(id: string, rules: any) {
    return this.put('email-list-retention-rules', rules, id);
  }

  clearDataRetentionRules(id: string) {
    return this.delete('email-list-retention-rules', id);
  }

  getUploadedEmailList(fileURL) {
    const resource = (this.isEmployeeList ? 'employee' : 'email') + '-file-data';
    const params = {fileURL};
    return this.get(`${resource}`, undefined, params);
  }
}

