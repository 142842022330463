import { SurvaleStartService } from './survale-start.service';
import { NgModule } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { BaseHttpService } from './base-http.service';
import { ConfigService, DashboardWidgetTypes, SurveyQuestionTypes } from './config.service';
import { CampaignsService } from './campaigns.service';
import { EmailListService } from './email-list.service';
import { SurveysService } from './surveys.service';
import { ViewService } from './view.service';
import { InsightsService } from './insights-service';
import { SurveyFiltersService } from './survey-filters.service';
import { GeneralDataService } from './general-data-service';
import { UsersService } from './users.service';
import { ClientService } from './client.service';
import { RolesService } from './roles.service';
import { DashboardService } from './dashboard.service';
import { EventsService } from './events.service';
import { SurveysResultsService } from './surveys-results.service';
import { PermissionsService } from './permission.service';
import { IndexesService } from './indexes.service';
import { VersionCheckService } from './version-check.service';
import { OAuthService } from './o-auth.service';
import { EmployeeCampaignService } from './employee-campaigns.service';
import { SharedSurveyTabsService } from './shared-survey-tabs.service';
import { TagsService } from './tags.service';
import { SurveyAnalysisService } from './survey-analysis.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { SentimentAnalysisService } from './sentiment-analysis.service';


@NgModule()
export class ServicesModule {
  static forRoot() {
    return {
      ngModule: ServicesModule,
      declaration: [
        BaseHttpService,
        ConfigService,
        DashboardWidgetTypes,
        SurveyQuestionTypes,
        AuthenticationService,
        CampaignsService,
        SurvaleStartService,
        EmailListService,
        SurveysService,
        SurveysResultsService,
        ViewService,
        InsightsService,
        SurveyFiltersService,
        GeneralDataService,
        UsersService,
        ClientService,
        RolesService,
        PermissionsService,
        DashboardService,
        EventsService,
        IndexesService,
        VersionCheckService,
        OAuthService,
        EmployeeCampaignService,
        TagsService,
        SharedSurveyTabsService,
        SurveyAnalysisService,
        GoogleAnalyticsService,
        SentimentAnalysisService,
      ],
      providers: [
        BaseHttpService,
        ConfigService,
        AuthenticationService,
        CampaignsService,
        SurvaleStartService,
        EmailListService,
        SurveysService,
        SurveysResultsService,
        ViewService,
        InsightsService,
        SurveyFiltersService,
        GeneralDataService,
        UsersService,
        ClientService,
        RolesService,
        PermissionsService,
        DashboardService,
        EventsService,
        IndexesService,
        VersionCheckService,
        OAuthService,
        EmployeeCampaignService,
        TagsService,
        SharedSurveyTabsService,
        SurveyAnalysisService,
        GoogleAnalyticsService,
        SentimentAnalysisService,
      ]
    };
  }
}

export {
  BaseHttpService,
  ConfigService,
  AuthenticationService,
  CampaignsService,
  SurvaleStartService,
  EmailListService,
  SurveysService,
  SurveysResultsService,
  ViewService,
  InsightsService,
  SurveyFiltersService,
  GeneralDataService,
  UsersService,
  ClientService,
  RolesService,
  PermissionsService,
  DashboardService,
  EventsService,
  DashboardWidgetTypes,
  SurveyQuestionTypes,
  IndexesService,
  VersionCheckService,
  OAuthService,
  EmployeeCampaignService,
  TagsService,
  SharedSurveyTabsService,
  SurveyAnalysisService,
  GoogleAnalyticsService,
  SentimentAnalysisService,
};
