import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventsService, EventsServiceDateRange } from '@services/events.service';
import { ServerDataServiceService } from 'app/services/server-data-service.service';
import { ApiCalllogDetailsDialogComponent } from '../api-calllog-details-dialog/api-calllog-details-dialog.component';
import { ConfigService } from '../../../services/config.service';
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-api-call-log',
  templateUrl: './api-call-log.component.html',
  styleUrls: ['./api-call-log.component.scss']
})
export class ApiCallLogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  apiCallLogs = [];
  count = 0;
  limit = 0;
  loading = false;
  selected: any[] = [];
  listParams = {
    offset: 0,
    limit: 30,
    filter: '',
    tags: ''
  };
  // columns = [
  //   {name: 'Provider', prop: 'apiProvider', flexGrow: .5},
  //   {name: 'URL', prop: 'options.url', flexGrow: 1},
  //   {name: 'Params', prop: 'options.qs', flexGrow: 1},
  //   {name: 'Requested time', prop: 'requestedAt', flexGrow: 1},
  //   {name: 'Response time', prop: 'responseAt', flexGrow: 1},
  //   {name: 'Status', prop: 'responseCode', flexGrow: .5},
  //   {name: 'Action', prop: '', flexGrow: 1}
  // ];
  columns = ['apiProvider', 'options.url', 'options.qs', 'requestedAt', 'responseAt', 'responseCode', 'action'];
  // 'URL', 'Params', 'Requested Time', 'Response Time', 'Status', 'Action'
  dataSource;
  currentTimePeriod;
  private readonly subscriptions: any;

  constructor(private serverDataService: ServerDataServiceService,
    private eventsService: EventsService,
    public dialog: MatDialog) {

    this.currentTimePeriod = this.eventsService.getCurrentDateRange();
    this.subscriptions = {};
    this.subscriptions.onEventDateRangeChanged =
      this.eventsService.onEventDateRangeChanged$.subscribe((dateRange: EventsServiceDateRange) => {
        this.currentTimePeriod = dateRange;
        // console.log('current time period ', this.currentTimePeriod);
        this.apiCallLogs = [];
        this.loadData();

      });

  }

  ngOnInit(): void {
    this.loadData().then(_.noop);
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  async loadData(): Promise<void> {
    try {
      this.loading = true;
      const data = await firstValueFrom(this.serverDataService.getApiCallLogs(this.listParams.limit, this.listParams.offset,
        this.currentTimePeriod
      ));
      this.loading = false;

      // for (let i = 0; i < data.body.length; i++) {
      //   this.apiCallLogs.push(data.body[i]);
      // }
      for (const r of data.body) {
        this.apiCallLogs.push(r);
      }

      this.dataSource = new MatTableDataSource(this.apiCallLogs);
    } catch(error) {
      this.loading = false;
      console.error(error);
    }

    // console.log('logs ', this.apiCallLogs);
  }

  loadMore(): void {
    this.listParams.offset += 1;
    this.loadData().then(_.noop);
  }

  displayDetails(row: any): void {
    this.dialog.open(ApiCalllogDetailsDialogComponent, Object.assign({ data: row }, ConfigService.getDialogConfigWide()));
  }

  // onPage(event: any) {
  //
  //   console.log('event ', event);
  //   const offset = event.offset;
  //   this.listParams.limit = event.limit;
  //   this.loadData();
  // }

}
