import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
// import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
// import { DashboardService, EventsService } from '../../../services/services.module';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { UsersService } from '@services/services.module';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './two-fa-code.component.html',
  styleUrls: ['./two-fa-code.component.scss'],
})
export class TwoFaCodeComponent implements OnInit {
  public readonly user: any;
  public secret;
  public token;
  public qrCode;
  public verifyCount = 0;

  constructor(public dialogRef: MatDialogRef<TwoFaCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private usersService: UsersService,
    private toaster: ToastrService,
  ) {
    console.log(data);
    this.user = data.user;
  }

  async ngOnInit(): Promise<void> {
    // initialize form
    this.dialogRef.updatePosition({ top: '50px' });
  }

  async generateTwoFACode(): Promise<void> {
    const r = await firstValueFrom(this.usersService.generateTwoFACode(this.user.userId));
    // console.log('result', r.body);
    this.secret = r.body.result.secret;
    // this.token = r.body.result.token;
    this.qrCode = r.body.qrCode;
    // console.log('this.qrCode ', this.qrCode);
  }

  async reGenerateTwoFACode(): Promise<void> {
    if (confirm('Existing QR Code will be replaced')) {
      await this.generateTwoFACode();
    }
  }

  async clearTwoFACode(): Promise<void> {
    if (confirm('Are you sure you want to remove the Two Factor Authentication for this user?')) {
      const r = await firstValueFrom(this.usersService.clearTwoFACode(this.user.userId));
      if (r.body.success) {
        this.toaster.success('Two Factor Authentication has been cleared from this account');
        this.close();
      } else {
        console.log(r);
        this.toaster.error('There was an error clearing Two Factor Authentication');
      }
    }
  }

  async commitCode(): Promise<void> {
    const r = await firstValueFrom(this.usersService.commitTwoFACode(this.user.userId, this.secret));
    // console.log(r.body);
    if (r.body.success) {
      this.toaster.success('Two Factor Authentication settings saved. Second factor for authentication will be required at Login.');
      this.close();
    } else {
      this.toaster.error('Errors Saving Settings');
    }
  }

  async validateTwoFACode(): Promise<void> {
    const r = await firstValueFrom(this.usersService.validateTwoFACode(this.token, this.secret));
    console.log(r.body);
    if (r.body.result) {
      this.verifyCount++;
      const extraMsg = this.verifyCount === 1 ?
        'Please wait for a second code to be generated. Then enter the code and verify again' :
        'Please click on \'Save Changes & Enable 2FA\' to Save this the settings.';
      this.toaster.success('Verification Code is correct. ' + extraMsg);
      this.token = '';
    } else {
      this.verifyCount = 0;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
