

import { Component, Input, OnInit } from '@angular/core';

@Component({
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  selector: 'survale-menu-item'
})

export class MenuItemComponent {
  @Input() menuItem: any;
  constructor() {
  }

}
