<div mat-dialog-title>
    <i class="fa fa-times fa-pull-right" (click)="onNoClick()"></i>
    <p style="font-size:22px;font-weight:400">Operational Fields Aliases</p>
  </div>
  
  <mat-divider></mat-divider>
  
  <div mat-dialog-content style="margin-top: 20px;margin-bottom: 20px">
    <!-- <div *ngFor="let item of filters">
      <span style="font-size:18px;font-weight: 500;">{{item}}</span>
    </div> -->
    <div *ngFor="let cf of customFields;let i = index" style="margin-top:15px">
          <span *ngIf="cf.alias">{{cf.value}} - {{cf.alias}}
            <button mat-raised-button mat-primary class="delete-button" (click)="removeAliases(cf,i)">
                <i class="fa fa-trash"></i></button>
          </span>
    </div>
  </div>
