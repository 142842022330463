import { Injectable, EventEmitter, Output, Directive } from '@angular/core';


@Directive()
export class SharedSurveyTabsService {

  @Output() surveyModuleChangedEvent = new EventEmitter<string>();


  surveyModuleChanged(msg: string) {
    this.surveyModuleChangedEvent.emit(msg);
  }

}
