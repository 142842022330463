/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
// import { Headers, RequestOptions, Response } from '@angular/http'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { EventsServiceDateRange } from './events.service';
import * as moment from 'moment';
import { LocalStorageService } from 'angular-2-local-storage';
import * as hashLib from 'object-hash';
// import { Observable } from 'rxjs/dist/types/internal/Observable';

export enum ServiceCacheTypes {
  SurveyDetails = 'SurveyDetails'
}

@Injectable()
export class BaseHttpService {
  public restHost: string;
  public headers: any = new HttpHeaders().set('Content-Type', 'application/json');
  public options: any = {
    headers: this.headers,
    observe: 'response'
  };
  private dateRangeRoundingToMinutes = 5;
  private readonly servicesCache = {};
  private _localStorageService;
  private readonly cacheKey = 'services-cache';

  constructor(
    public http: HttpClient,
  ) {
    this.restHost = ConfigService.config.apiHost;
    this._localStorageService = new LocalStorageService();
    const servicesCache: string = this._localStorageService.get(this.cacheKey);
    try {
      if (servicesCache) {
        this.servicesCache = JSON.parse(servicesCache);
      } else {
        this.servicesCache = {};
      }
    } catch (error) {
      console.log('Error Loading Cache from Local Storage');
    }
  };

  get(resource: string, id?: string, params: any = {}, userHash = '', useCached = false, cacheDuration = 3000): Observable<any> {
    const url = [this.restHost, '/v0/', resource]; const options: any = [];
    if (id || Number(id) === 0) {
      url.push(`/${id}`);
    }
    // this.options.params = params;
    const opts: any = _.cloneDeep(this.options);
    opts.params = params;
    if (useCached) {
      params.userHash = userHash;
      const hash = hashLib(params);
      return new Observable(observer => {
        const cache = this.getCache(resource, hash);
        if (cache) {
          // console.log('** returning from cache');
          observer.next(cache);
          observer.complete();
        } else {
          this.http.get(url.join(''), opts)
            .subscribe((response: any) => {
              this.setCacheWithExpiry(resource, hash, response, cacheDuration);
              observer.next(response);
              observer.complete();
            }, (error): any => {
              observer.error(error);
              observer.complete();
            });
        }

      });
    } else {
      // console.log('** not using cache');
      const observable: Observable<ArrayBuffer> = this.http.get(url.join(''), opts);
      return observable;
    }
  }


  post(resource: any, payload: any, params: any = undefined): Observable<any> {
    const url = [this.restHost, '/v0/', resource];
    const opts: any = _.cloneDeep(this.options);
    if (params) {
      // this.options.params = params;
      opts.params = params;
    }
    return this.http.post(url.join(''), payload, opts);
  }

  delete(resource: any, id: string, params: any = undefined): Observable<any> {
    const url = [this.restHost, '/v0/', resource, `/${id}`];
    const opts: any = _.cloneDeep(this.options);
    if (params) {
      // this.options.params = params;
      opts.params = params;
    }
    return this.http.delete(url.join(''), opts);
  }

  put(resource: any, payload: any, id: string, params: any = undefined): Observable<any> {
    const url = [this.restHost, '/v0/', resource, `/${id}`];
    const opts: any = _.cloneDeep(this.options);
    if (params) {
      // this.options.params = params;
      opts.params = params;
    }
    return this.http.put(url.join(''), payload, opts);
  }

  nearestPastMinutes(interval, someMoment: moment.Moment) {
    if (!someMoment) {
      console.log('moment empty');
    }
    const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0).millisecond(0);
  }

  getTsObj(timePeriod: EventsServiceDateRange) {
    const stDt = this.nearestPastMinutes(this.dateRangeRoundingToMinutes, timePeriod.startDate);
    const edDt = this.nearestPastMinutes(this.dateRangeRoundingToMinutes, timePeriod.endDate);
    return {startDate: stDt.toISOString(), endDate: edDt.toISOString()};
  }

  public setCacheWithExpiry(resource: string, key: string, value: any, expireInSeconds = 300) {
    const r = resource.replace(/\//g, '--');
    this.servicesCache[`cache ${r}-${key}`] = {expireInSeconds, value, cachedAt: new Date()};
    console.log('** setCacheWithExpiry: ', r, this.servicesCache);
    this._localStorageService.set(this.cacheKey, JSON.stringify(this.servicesCache));
  }

  public getCache(resource: string, key: string) {
    const r = resource.replace(/\//g, '--');
    const v = this.servicesCache[`cache ${r}-${key}`];
    if (_.isNil(v) || _.isNil(v.expireInSeconds)) {
      return v;
    } else {
      if (typeof (v.cachedAt) === 'string') {
        v.cachedAt = new Date(v.cachedAt);
      }
      if (((new Date().getTime() - v.cachedAt.getTime()) / 1000) < v.expireInSeconds) {
        return v.value;
      } else {
        delete this.servicesCache[`cache ${r}-${key}`];
        this._localStorageService.set(this.cacheKey, JSON.stringify(this.servicesCache));
        return undefined;
      }
    }
  }
}
