<div mat-dialog-title class="dialog-header">
  <button class="close-button" (click)="close()">
    X
  </button>
  <h2>Two Factor Authentication</h2>
</div>
<div mat-dialog-content class="dialog-content">
  <ol *ngIf="user.twoFAEnabled !== 1">
    <li>Download the Google Authenticator App
      <u><a href="https://apps.apple.com/us/app/google-authenticator/id388497605">App Store for iPhone / iPad</a></u> or
      <u><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US">
        Play Store for Android Devices</a></u>
    </li>
    <li>Click on the '+' icon at the bottom, and click Scan Code</li>
    <li>After Installing the App, click on 'Generate 2FA Code Button'</li>
    <li>Point your devices camera to scan the QR Code on the Screen</li>
    <li>App will start showing you time based tokens (6 digit numbers)</li>
    <li>Validate settings by verifying 2 Codes generated on the Google Authenticator App</li>
    <li>Then click 'Save Changes' button.</li>
  </ol>
  <div class="qr-code-wrap">
    <div *ngIf="qrCode">
      <div *ngIf="verifyCount !== 2" fxLayout="row wrap" fxLayoutGap="10px" class="verify-qr-code">
        <div fxFlex="48%">
          <div> Scan this code in Google Authenticator App or enter the code below</div>
          <br/>
          <div class="clearfix"></div>
          <qr-code [value]="qrCode" [size]="250"></qr-code>
          <div class="clearfix"></div>
          <br/>
          <code>{{qrCode}}</code>
        </div>
        <div fxFlex="48%">
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="99%">
              <div style="text-align: left">
                <p>1. Scan the qr-code in Google Authenticator App </p>
                <p>2. Enter the code as shown in Google Authenticator App and click Validate</p>
                <p>3. Enter the code one more time shown in Google Authenticator App and click Validate</p>
              </div>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Token</mat-label>
                <input matInput placeholder="Code" [(ngModel)]='token'>
                <mat-hint *ngIf="verifyCount === 1">
                  <i class="fa fa-check"></i>
                  Please wait for a second code to be generated. Then enter the code and verify again
                </mat-hint>
                <mat-hint *ngIf="verifyCount === 2">
                  <i class="fa fa-check-double"></i>
                  Please click on 'Commit Code & Enable 2FA' to Save this Code`
                </mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex="99%">
              <button mat-raised-button
                      *ngIf="qrCode"
                      color="primary"
                      (click)="validateTwoFACode()">
                <span> Validate </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="verifyCount === 2">
        <button mat-raised-button color="primary" class="large"
                (click)="commitCode()">
          Save Changes & Enable 2FA
        </button>
      </div>

    </div>
    <div *ngIf="!qrCode">
      <p *ngIf="user.twoFAEnabled === 1">Two Factor Authentication Set</p>
      <button *ngIf="user.twoFAEnabled === 1" mat-raised-button
              color="primary"
              (click)="clearTwoFACode()">
        <span> Clear 2FA Code </span>
      </button>
      <button *ngIf="user.twoFAEnabled !== 1" mat-raised-button
              color="primary" class="large"
              (click)="generateTwoFACode()">
        <span> Generate 2FA Code </span>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-actions class="dialog-footer">
  <div class="buttons-wrap">
    <button mat-raised-button
            *ngIf="qrCode && verifyCount !== 2"
            color="primary"
            (click)="reGenerateTwoFACode()">
      <span> Re-generate 2FA Code </span>
    </button>

    <button mat-raised-button color="primary"
            (click)="close()">
      Close
    </button>
  </div>
</div>
