<!--<span class="title">Client Classification</span>-->
<h5>Client Type</h5>
<form [formGroup]="clientTypeForm" *ngIf="isSurvaleAdmin && clientTypeForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="20%">
      <mat-form-field appearance="outline">
        <mat-label>Client Status</mat-label>
        <mat-select formControlName="clientType">
          <mat-option value="sandbox">Sandbox</mat-option>
          <mat-option value="production">Production</mat-option>
          <mat-option value="campaignPaused">Campaign Paused</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="20%">
      <mat-form-field appearance="outline">
        <mat-label>Main Client</mat-label>
        <mat-select formControlName="mainClientId" [disabled]="disableNonSandboxFields()">
          <mat-option *ngFor="let c of childClients" [value]="c.clientId">{{c.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="75%">
      <mat-form-field appearance="outline" >
        <mat-label>Test Email Addresses, separated by comma. If this is not set email's will go to email address on record.</mat-label>
        <textarea matInput formControlName="testEmailAddresses" placeholder="" [disabled]="disableNonSandboxFields()"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>

<h5>Classification</h5>
<form [formGroup]="clientClassificationForm" *ngIf="clientClassificationForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Primary Industry</mat-label>
        <mat-select formControlName="primaryIndustry">
          <mat-option *ngFor="let item of primaryIndustries" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Company Revenue</mat-label>
        <mat-select formControlName="companyRevenue">
          <mat-option *ngFor="let item of companyRevenue" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global Revenue</mat-label>
        <mat-select formControlName="globalRevenue">
          <mat-option *ngFor="let item of globalRevenue" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Employee Population</mat-label>
        <mat-select formControlName="employeePopulation">
          <mat-option *ngFor="let item of employeePopulation" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global Employee Population</mat-label>
        <mat-select formControlName="globalEmployeePopulation">
          <mat-option *ngFor="let item of globalEmployeePopulation" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>New Hires</mat-label>
        <mat-select formControlName="newHires">
          <mat-option *ngFor="let item of newHires" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global New Hires</mat-label>
        <mat-select formControlName="globalNewHires">
          <mat-option *ngFor="let item of globalNewHires" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Recruiting Team Size</mat-label>
        <mat-select formControlName="recruitingTeamSize">
          <mat-option *ngFor="let item of recruitingTeamSize" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Recruiting Team Structure</mat-label>
        <mat-select formControlName="recruitingStructure">
          <mat-option *ngFor="let item of recruitingTeamStructure" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Average Job Reqs</mat-label>
        <mat-select formControlName="averageJobReqs">
          <mat-option *ngFor="let item of averageJobReqs" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
