import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { DashboardService, InsightsService, SurveysService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { AppCache } from '../components/survale-common/cache/app.cache';
import { first, map } from 'rxjs/operators';

@Injectable()
export class UserDashboardsResolver implements Resolve<any> {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.dashboardService.getAllUserDashboards();
    // .pipe(
    //   first(),
    //   map(dashboards => {
    //     return dashboards
    //   })
    // )
  }

}
