import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { DashboardService, SurveysService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { AppCache } from '../components/survale-common/cache/app.cache';

@Injectable()
export class SelfDashboardFiltersResolver implements Resolve<any> {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const selfDashboardFilterId = route.paramMap.get('selfDashboardFilterId');
    console.log('selfDashboardFilterId', selfDashboardFilterId);
    const cached = this.cache.getCache(this.cacheTypes.SelfDashboardFilters, selfDashboardFilterId);
    if (cached) {
      // this.cacheSurveys(cached);
      return cached;
    }
    // console.log('calling getDashboardJson from resolver');
    return this.dashboardService.getSelfFilteredDashboard(selfDashboardFilterId)
      .pipe(
        ((response: any) => {
          response.subscribe(data => this.cache.setCache(this.cacheTypes.SelfDashboardFilters, selfDashboardFilterId, data));
          return response;
        })
      );
  }

}
