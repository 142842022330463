<h5>Email Alert Settings</h5>
<!--<span class="title"> Email Settings </span>-->
<form [formGroup]="emailForm" *ngIf="emailForm">
<div fxLayout="row wrap" fxLayoutGap="10px">
  <div fxFlex="10%" class="send-email">Send Email To</div>
  <div fxFlex="30%">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label> <input matInput placeholder="Email" formControlName="email">
      <mat-error *ngIf="email.invalid && email.errors.email">
        Please enter valid email
      </mat-error>
    </mat-form-field>
  </div>
  <div fxFlex="30%">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label> <input matInput placeholder="Name. E.g Tim" formControlName="username">
    </mat-form-field>
  </div>
  <div fxFlex="10%">
    <button mat-raised-button class="mat-green margin-top-10" (click)="addUser()"> Add user</button>
  </div>
  <div fxFlex="100%">
    <mat-chip-list aria-label="Email selection" *ngIf="surveyEscalationSettings">
      <mat-chip color="primary" selected *ngFor="let item of surveyEscalationSettings.reportToEmail; let idx = index">
        {{item.username}} ({{item.email}}) &nbsp; <i class="fa fa-trash" (click)="removeRecipient(idx)" ></i>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
</form>
<form [formGroup]="keywordForm" *ngIf="keywordForm">
<div fxLayout="row wrap" class="second-row" fxLayoutGap="10px">
  <div fxFlex="10%" class="send-email">Alert keywords</div>
  <div fxFlex="30%">
    <mat-form-field appearance="outline">
      <mat-label>Add Keyword</mat-label>
      <input matInput placeholder="Add Keyword" formControlName="keyword">
    </mat-form-field>
  </div>
  <div fxFlex="30%">
    <button mat-raised-button class="mat-green margin-top-10" (click)="addKeyword()"> Add keyword</button>
  </div>
  <div fxFlex="100%">
    <mat-chip-list aria-label="Keyword selection" *ngIf="surveyEscalationSettings">
      <mat-chip color="primary" selected *ngFor="let item of surveyEscalationSettings.customLookoutKeyWords; let idx = index">
        {{item}}  &nbsp;  <i class="fa fa-trash" (click)="removeKeyword(idx)"></i>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
</form>

<form [formGroup]="form" *ngIf="form">
<div fxLayout="row wrap" fxLayoutGap="10px" class="second-row">
  <div fxFlex="32%">
    <mat-form-field appearance="outline">
      <mat-label>Long Comments minimum characters</mat-label>
      <input matInput type="number" min="5" placeholder="Long comments" formControlName="longCommentsMinChars">
    </mat-form-field>
  </div>
  <div fxFlex="32%">
    <mat-form-field appearance="outline">
      <mat-label>Schedule</mat-label>
      <mat-select formControlName="schedule">
        <mat-option value="hourly">Hourly</mat-option>
        <mat-option value="weekly">Weekly</mat-option>
        <mat-option value="monthly">Monthly</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="32%" *ngIf="schedule.value === 'hourly'">
    <mat-form-field appearance="outline">
      <mat-label>Hours</mat-label>
      <input matInput placeholder="Hours" min="1" max="24" formControlName="hours">
    </mat-form-field>
  </div>
  <div fxFlex="32%">
    <mat-form-field appearance="outline">
      <mat-label>Alert if score is below</mat-label>
      <input matInput placeholder="Alert if score is below" formControlName="minScore">
    </mat-form-field>
  </div>
  <div fxFlex="32%">
    <mat-form-field appearance="outline">
      <mat-label>NPS Alert</mat-label>
      <mat-select multiple formControlName="npsAlert">
        <mat-option value="detractor">Detractor</mat-option>
        <mat-option value="promoter">Promoter</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
</form>
