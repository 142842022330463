import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { AppCache } from '../components/survale-common/cache/app.cache';
import { firstValueFrom } from 'rxjs';

@Injectable()

export class DashboardService extends BaseHttpService {
  private resource = 'dashboard';
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  getDashboard(id: string) {
    return this.get('dashboard/byId', undefined, { id, dashboardVersion: 2 });
  }

  getDashboardByViewId(viewId: string) {
    return this.get('dashboard/byViewId', undefined, { viewId, dashboardVersion: 2 });
  }

  getSharedWithUsers(dashboardId) {
    return this.get('dashboard/shared-with-users', dashboardId);
  }

  getAllUserDashboards() {
    return this.get('dashboard/user-dashboards');
  }

  setSharedWithUsers(dashboardId, body) {
    return this.put('dashboard/shared-with-users', body, dashboardId);
  }

  shareFilteredDashboard(body) {
    return this.post('dashboard/share-filtered-dashboard', body);
  }

  async deleteDashboard(id: string) {
    return await this.post('dashboard/delete', { id }).toPromise();
  }

  async deleteDashboardImpact(id: string) {
    return await this.get('dashboard/delete-impact', id).toPromise();
  }

  async deleteFilteredSharedDashboard(id: string) {
    return await firstValueFrom(this.post('dashboard/delete-filtered-shared', { id }));
  }

  async deleteFilteredSharedUser(id: string) {
    console.log('delete user service', id);
    return await firstValueFrom(this.post('dashboard/delete-filtered-shared-user', { id }));
  }

  async deleteSharedUser(userShareId: string) {
    // console.log('delete user service', id);
    return await firstValueFrom(this.post('dashboard/delete-shared-user', { id: userShareId }));
  }

  async updateSelfSubscription(id: string, selfSubscriptions) {
    if (id) {
      this.cache.clearLocalCache(this.cacheTypes.Dashboard, id.toString());
    }
    const body = {
      id: id ? parseInt(id, 10) : undefined,
      selfScreenshotEmailSubscribers: selfSubscriptions,
    };
    return await firstValueFrom(this.post('dashboard/self-subscription', body, { dashboardVersion: 2 }));
  }

  async saveDashboard(id: string, dashboard: any): Promise<any> {
    if (id) {
      this.cache.clearLocalCache(this.cacheTypes.Dashboard, id.toString());
    }
    const body = {
      id: id ? parseInt(id, 10) : undefined,
      title: dashboard.title,
      state: dashboard.state,
      viewId: dashboard.viewId,
      shared: dashboard.shared,
      dashboardJSON: JSON.stringify({ content: dashboard.content }),
      screenshotEmailSubscribers: dashboard.screenshotEmailSubscribers ? JSON.stringify(dashboard.screenshotEmailSubscribers) : undefined,
    };
    body.dashboardJSON =
      body.dashboardJSON.replace(/[\u007F-\uFFFF]/g, chr => '\\u' + ('0000' + chr.charCodeAt(0).toString(16)).substr(-4));
    try {
      // console.log('saveDashboard', body);
      const result = await firstValueFrom(this.post('dashboard/save', body, { dashboardVersion: 2 }));
      body.id = result.body.insertId;
      const cached = this.cache.getCache(this.cacheTypes.Dashboard, body.id?.toString());
      if (cached) {
        for (const key of Object.keys(cached)) {
          if (body[key] && key !== 'dashboardJSON') {
            if (key === 'screenshotEmailSubscribers') {
              cached[key] = body[key] ? JSON.parse(body[key]) : undefined;
            } else {
              cached[key] = body[key];
            }
          }
        }
        this.cache.setCache(this.cacheTypes.Dashboard, body.id.toString(), cached);
      }
      // console.log('cached', cached, result);
      return result;
    } catch (error) {
      console.error('Error saving dashboard', error);
      throw error;
    }
  }

  getAllDashboardShares() {
    return this.get('dashboard/all-shared');
  }

  getAllFilteredDashboardShares() {
    return this.get('dashboard/all-filtered-shared');
  }

  createUserDashboardFilter(body) {
    return this.post('dashboard/user-dashboard-filter', body);
  }

  getSelfFilteredDashboards() {
    return this.get('dashboard/user-dashboard-filter-list');
  }

  getSelfFilteredDashboard(id: string) {
    return this.get('dashboard/user-dashboard-filter', id);
  }

  saveSelfFilteredDashboard(id: string, payload: object) {
    return this.put('dashboard/user-dashboard-filter', payload, id);
  }

  deleteSelfFilteredDashboard(id: string) {
    return this.delete('dashboard/user-dashboard-filter', id);
  }

  /**
   * Return default dashboard
   */
  getDefaultDashboard() {
    return {
      title: 'Default Dashboard',
      content: {
        widgets: [
          {
            title: 'DropOff',
            itemId: 'CandDropOff',
            items: [],
            sizeX: 4,
            sizeY: 4,
            row: 2,
            col: 0,
            type: 'candidateDropOffChart'
          },
          {
            title: 'Survey Statistics',
            itemId: 'SurveyStats',
            items: [],
            sizeX: 4,
            sizeY: 4,
            row: 4,
            col: 4,
            type: 'surveyStatsChart'
          },
          {
            title: 'Visits Breakdown',
            itemId: 'visitsBreakdown',
            items: [],
            sizeX: 4,
            sizeY: 4,
            type: 'visitsBreakdownChart',
            row: 8,
            col: 4
          },
          {
            title: 'All Comments',
            type: 'commentsWidget',
            itemId: 'commentsFeed',
            items: [],
            sizeX: 4,
            sizeY: 8,
            row: 0,
            col: 8
          },
          {
            title: 'Top Sources',
            itemId: 'topSources',
            items: [],
            sizeX: 4,
            sizeY: 3,
            type: 'topSourcesWidget',
            row: 8,
            col: 8
          }
        ],
        active: true
      },
      id: undefined
    };
  }
}

