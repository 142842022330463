<div class="app search-i" [dir]="layoutDir"
  [ngClass]="[ menuLayout, selectedSidebarImage, selectedSidebarColor, selectedHeaderColor, collapsedClass]"
  [class.app-dark]="dark" [class.boxed]="boxed" [class.collapsed-sidebar]="collapseSidebar"
  [class.compact-sidebar]="compactSidebar" [class.bg-img-disable]="!sidebarBg">
  <mat-sidenav-container class="app-inner" style="overflow-y: hidden; -ms-overflow-y: hidden">
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'"
      [opened]="!isOver()" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <div class="opt-side relative ">
        <div class="sidebar-container ">
          <div class="branding text-center">
            <div class="logo-wrap">              
              <img class="survale-main-logo-limited" [src]="survaleMainLogo" />
            </div>
          </div>
          <div class="main-nav">
            <mat-nav-list appAccordion class="navigation relative">
              <survale-menu-items [menuItems]="menuItems.getAll()"></survale-menu-items>

              <mat-divider></mat-divider>
            </mat-nav-list>
          </div>
        </div>
      </div>
      <div class="bg-overlay"></div>
    </mat-sidenav>

    <mat-toolbar class="main-header">

      <div class="vertical-menu w-100">
        <button (click)="menuToggleFunc()" mat-icon-button class="lines-btn">
          <mat-icon *ngIf="!sidemenu.opened">menu</mat-icon>
          <mat-icon *ngIf="sidemenu.opened">menu_open</mat-icon>
        </button>
        <button appToggleFullscreen mat-icon-button class="sm-disable">
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button (click)="openDateRangePicker($event)" mat-icon-button class="lines-btn date-range-picker-button">
          <mat-icon>date_range</mat-icon>
        </button>
        <div class="date-range-picker-wrap">
          <input [readonly]="true" id="main-data-range-picker" type="text" ngxDaterangepickerMd autocomplete="off"
            [(ngModel)]="selectedDateRange" [ranges]="dateRangePickerOptions.ranges"
            [locale]="dateRangePickerOptions.locale" [showCustomRangeLabel]="true" [showCancel]="false"
            [showDropdowns]="true" [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true"
            [showRangeLabelOnInput]="true" (rangeClicked)="rangeClicked($event)" (datesUpdated)="datesUpdated($event)"
            class="form-control date-range-selector" name="daterange" />
        </div>
        <span fxFlex></span>
        <span [mat-menu-trigger-for]="user" class="ml-xs current-user">
          <img class="avatar" *ngIf="avatar" [src]="avatar" />
          <span class="user-name-wrap">{{(userName || 'Unnamed User')| replaceText }}</span>
          <i class="fa fa-caret-down"></i>
        </span>
        <!--        <button [mat-menu-trigger-for]="user" mat-icon-button class="ml-xs">-->
        <!--          <mat-icon>person</mat-icon>-->
        <!--        </button>-->
        <mat-menu #user="matMenu" class="opt-menu" x-position="before">
          <button mat-menu-item routerLink="/authentication/change-password" *ngIf="!hasExternalAuth">
            <mat-icon>vpn_key</mat-icon>
            Change Password
          </button>
          <button (click)="setTwoFactorAuthentication()" mat-menu-item *ngIf="!hasExternalAuth">
            <mat-icon>looks_two</mat-icon>
            Two Factor Authentication
          </button>
          <button (click)="signOut()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            Sign Out
          </button>
          <button mat-menu-item *ngIf="hasExternalAuth">User Authenticated by SSO</button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="body-container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-container>
  <!-- <app-ngx-notifier></app-ngx-notifier> -->
</div>
