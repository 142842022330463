<div mat-dialog-title class="dialog-header">
  <!-- <button class="close-button" mat-dialog-close (click)="close()">
    X
  </button> -->
  <i class="fa fa-times fa-pull-right hand-cursor" (click)="close()"></i>
  <h3>Response / Error</h3>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="pad-10">
    <h4 *ngIf="data.responseCode === 200">Response</h4>
    <h4 *ngIf="data.responseCode !== 200">Error</h4>
    
    <div>
      <span *ngIf="isLoading" class="loading">Loading ... JSON: {{isJSON}}</span>
      <pre *ngIf="isJSON">{{response | json}}</pre>
      <pre *ngIf="isXml">{{response | xml}}</pre>
      <pre *ngIf="!isJSON && !isXml">{{response}}</pre>
    </div>
  </div>
</div>
