import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AppCache } from '../components/survale-common/cache/app.cache';

@Injectable()

export class SurveyFiltersService extends BaseHttpService {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getUsersSurveyFilters() {
    return new Observable<any>((observer) => {
      this.get('survey-filters/user', undefined, {version: 2})
        .subscribe((response) => {
          // console.log('SurveyFiltersService', response);
          this.cache.setCache(this.cacheTypes.SurveyFilters, '', response);
          _.each(response.body, i => this.cache.setCache(this.cacheTypes.SurveyFilter, i.id, i));
          observer.next(response);
          observer.complete();
        }, (error): any => {
          // this is temp fix EU server giving 404 for this endpoint bizzare
          observer.next({body: []});
          observer.complete();
        });
    });
  }

  getAllSurveyFilters(withIds = 'no') {
    return this.get('survey-filters/all', undefined, _.merge({withIds}, {version: 2}));
  }

  createSurveyFilter(payload) {
    payload.v2 = true;
    return this.post('survey-filters/add', payload);
  }

  getSurveyFilter(id) {
    return this.get('survey-filters/by-id', id, {version: 2});
  }

  deleteSurveyFilter(id) {
    return this.post('survey-filters/delete', {id});
  }
}


