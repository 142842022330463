import { Component, Inject, Injectable, Input, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class JsonContentDialogComponentData {
  jsonContent: string;
}

@Component({
  selector: 'survale-json-content-dialog',
  templateUrl: './json-content-dialog.html',
  styleUrls: ['./json-content-dialog.scss']
})

export class JsonContentDialogComponent {
  // @Input() dialogRef;
  // @Input() jsonContent;
  constructor(public dialogRef: MatDialogRef<JsonContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: JsonContentDialogComponentData) {
    setTimeout((): void => { this.init(); }, 100);
  }
  init(): void {
    this.dialogRef.updatePosition({ top: '50px' });
  }
}
