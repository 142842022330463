// Core modules
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

// Application services
import { ClientService } from 'app/services/client.service';

/**
 * Immediate alert settings
 */
@Component({
  selector: 'app-immediate-settings',
  templateUrl: './immediate-settings.component.html',
  styleUrls: ['./immediate-settings.component.scss']
})
export class ImmediateSettingsComponent implements OnInit, OnChanges {
  @Input() settings;

  public surveyEscalationSettings: any;
  public immediateAlertForm: FormGroup;


  /**
   * Life cycle method
   * @param clientService ClientService
   * @param fb FormBuilder
   */
  constructor(
    private clientService: ClientService,
    private fb: FormBuilder
  ) { }

  /**
   * Finding the default survey escalation settings and initialize form
   */
  ngOnInit(): void {
    this.surveyEscalationSettings = this.clientService.getDefaultSurveyEscalation().immediateAlert;
    this.initializeForm();
  }

  /**
   * Sets the client survey escalation values received from parent component
   */
  ngOnChanges(): void {
    if (this.settings && this.settings.surveyEscalation && this.settings.surveyEscalation.immediateAlert) {
      this.surveyEscalationSettings = this.settings.surveyEscalation.immediateAlert;
      console.log(this.immediateAlertForm);
      this.immediateAlertForm.controls.minScore.patchValue(this.surveyEscalationSettings.minScore);
      this.immediateAlertForm.controls.alertLongComments.patchValue(this.surveyEscalationSettings.alertLongComments);
    }
  }

  /**
   * Initialize form
   */
  initializeForm(): void {
    this.immediateAlertForm = this.fb.group({
      minScore: new FormGroup({
        enabled: new FormControl(this.surveyEscalationSettings.minScore.enabled),
        value: new FormControl(this.surveyEscalationSettings.minScore.value)
      }),
      alertLongComments: new FormGroup({
        enabled: new FormControl(this.surveyEscalationSettings.alertLongComments.enabled),
        value: new FormControl(this.surveyEscalationSettings.alertLongComments.value)
      }),
    });
  }
}
