import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RolesService extends BaseHttpService {
  private resource = 'role';

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getRoles() {
    return this.get('roles');
  }

  getRole(id: string) {
    return this.get(`${this.resource}/${id}`);
  }

  addRole(payload: any) {
    return this.post(this.resource, payload);
  }

  editRole(payload: any, id: string): Observable<any>  {
    return this.put(this.resource, payload, id);
  }

  deleteRole(id: string): Observable<any> {
    return this.delete(this.resource, id);
  }
}

