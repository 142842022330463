<mat-card>
  <mat-card-header>
    <mat-card-title class="title"> Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="Settings">
        <app-client-settings [settings]="clientSettings"></app-client-settings>
      </mat-tab>
      <mat-tab label="Classification">
        <app-client-classification [settings]="clientSettings"></app-client-classification>
      </mat-tab>
      <mat-tab label="Email Alert Settings">
        <app-email-settings [settings]="clientSettings"></app-email-settings>
      </mat-tab>
      <mat-tab label="Immediate Alert Settings">
        <app-immediate-settings [settings]="clientSettings"></app-immediate-settings>
      </mat-tab>
      <mat-tab label="Index Weights">
        <app-client-index-weights [settings]="clientSettings"></app-client-index-weights>
      </mat-tab>
      <mat-tab label="Security">
        <app-client-ip-white-list [settings]="clientSettings" [useIpWhiteList]="useIpWhiteList"
          [clientAPIKeys]="clientAPIKeys" [ipWhiteList]="ipWhiteList">
        </app-client-ip-white-list>
      </mat-tab>
      <mat-tab label="Other Settings" *ngIf="isSurvaleAdmin">
        <app-other-client-settings ></app-other-client-settings>
      </mat-tab>
    </mat-tab-group>
    <hr />
    <mat-card-actions class="float-right">

      <button mat-raised-button class="mat-green" *ngIf="isSurvaleAdmin && currentUser.parentClientIds === '-1'"
        (click)="showAddClient()">Add client
      </button>
      <button mat-raised-button class="mat-green" *ngIf="isSurvaleAdmin" (click)="showAddSamlSSO()">
        SSO Settings
      </button>


      <button mat-raised-button class="mat-primary" (click)="saveSettings()">Save Settings</button>

    </mat-card-actions>
    <p>&nbsp;</p>
  </mat-card-content>
</mat-card>
