import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { EventsServiceDateRange } from './events.service';

@Injectable()

export class SurveysService extends BaseHttpService {
  private resource = 'survey';

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  /**
   *
   * @param surveyIds:String  CSV String of surveys or null
   * @param limit
   * @param offset
   * @param startsWith
   */
  getSurveys(surveyIds?: string, limit = 10, offset = 0, startsWith = '', tags = '') {
    const params: any = {
      limit,
      offset,
      loadCounts: true,
      'starts-with': startsWith,
      tags
    };
    if (surveyIds) {
      params.surveyIds = surveyIds;
    }
    return this.get(`${this.resource}s`, '', params);
  }

  getSurvey(id: string) {
    return this.get(`${this.resource}/${id}`);
  }

  getQuestionTags() {
    return this.get('question-tags');
  }

  getQuestionList(limit, offset) {
    return this.get('survey-questions', undefined, { limit, offset });
  }

  getBenchmarkList(surveyModule, questionType='all') {
    return this.get('benchmark-sources', undefined, { surveyModule, questionType });
  }

  getSurveyHiddenQuestions() {
    return this.get('hidden-survey-questions');
  }

  getRelatedQuestionList(q: any) {
    const ep = 'related-questions';
    const params = {
      questionType: q.questionType,
      questionTitle: q.title,
      limit: 300,
      offset: 0
    };
    return this.get(`${ep}`, null, params);
  }

  saveQuestion(q: any, id) {
    const ep = 'survey-question';
    if (id) {
      return this.put(`${ep}`, q, id);
    } else {
      return this.post(`${ep}`, q);
    }
  }


  saveSurvey(survey: any, id: string, parentSurveyId: number = undefined) {
    const params: any = parentSurveyId ? { parentSurveyId } : {};
    const data = { title: survey.title, surveyJSON: survey };
    if (id) {
      return this.put(`${this.resource}`, data, id, params);
    } else {
      return this.post(`${this.resource}`, data, params);
    }
  }

  pushToProduction(id: string) {
    return this.post(`surveys-production-push/${id}`, {});
  }

  updateSurveyShare(id: string, share: boolean, survey: any) {
    const data = { title: survey.title, surveyJSON: {}, share };
    const ep = 'survey/update-share';
    return this.put(`${ep}`, data, id, undefined);
  }

  startSmsSurvey(id: number | string, smsNumber: string) {
    const data = { smsNumber, surveyId: Number(id) };
    const ep = `start-sms-survey/${id}`;
    return this.post(`${ep}`, data);
  }

  postQuestionTag(tag: string) {
    return this.post('question-tags', { tag });
  }

  getLanguages() {
    return [
      // {id: 'en', title: 'English'}, //'en-GB', 'en', 'en-US'
      { id: 'fr', title: 'French' },
      { id: 'es', title: 'Spanish' },
      { id: 'ko', title: 'Korean' },
      { id: 'de', title: 'German' },
      { id: 'ja', title: 'Japanese' },
      { id: 'en-gb', title: 'British English' },
      { id: 'tr', title: 'Turkish' },
      { id: 'ru', title: 'Russian' },
      { id: 'he', title: 'Hebrew' },
      { id: 'pl', title: 'Polish' },
      { id: 'it', title: 'Italian' },
      { id: 'bg', title: 'Bulgarian' },
      { id: 'pt', title: 'Portuguese' },
      { id: 'nl', title: 'Dutch' },
      { id: 'zh', title: 'Mandarin' },
      { id: 'zh-CHT', title: 'Traditional Chinese' },
      { id: 'de-at', title: 'Austrian' },
      { id: 'nl-be', title: 'Belgium' },
      { id: 'cs-cz', title: 'Czech' },
      { id: 'nld', title: 'Netherlands' },
      { id: 'ro', title: 'Romanian' },
      { id: 'sl', title: 'Slovenian' },
      { id: 'sk', title: 'Slovakian' },
      { id: 'fr-ca', title: 'French-Canadian'}

    ];
  }

  deleteSurvey(id: string) {
    return this.delete(`${this.resource}`, id);
  }

  deleteQuestion(id: string) {

    return this.delete('survey-question', id);
  }

  getSurveysByCompletions(surveyList, timePeriod: EventsServiceDateRange) {
    const tsObj = this.getTsObj(timePeriod);
    const params = { surveyList, timePeriod: JSON.stringify(tsObj) };
    return this.get('survey-results/surveys-applied', undefined, params);
  }

  getParentSurveyIds() {
    return this.get('parent-survey-ids-list');
  }

  getSurveyResponse(id) {
    return this.get('reports/survey-response', id);
  }


}


