import { NumberShortnerPipe } from './number-shortner.pipe';
import { NgModule } from '@angular/core';
import { HumanizePipe } from './humanize-pipe';
import { HighlightSearch } from './highlight-pipe';
// import { TimeAgoPipe } from 'time-ago-pipe';
import { FilterdataPipe } from './filterdata.pipe';
import { ObfuscateEmailPipe } from './obfuscate-email.pipe';
import { ObfuscateEmailInTextPipe } from './obfuscate-email-in-text.pipe';
import { ReplaceTextPipe } from './replace-text-pipe';
import { XmlPipe } from './xml-display.pipe';
import { TimeAgoExtendsPipePipe } from './custom-time-ago-pipe.pipe';
import { AbsoluteNumberPipe } from './absolute-number.pipe';
@NgModule({
  declarations: [
    NumberShortnerPipe,
    HumanizePipe,
    HighlightSearch,
    // TimeAgoPipe,
    FilterdataPipe,
    ObfuscateEmailPipe,
    ObfuscateEmailInTextPipe,
    ReplaceTextPipe,
    XmlPipe,
    TimeAgoExtendsPipePipe,
    AbsoluteNumberPipe,
  ],
  exports: [
    NumberShortnerPipe,
    HumanizePipe,
    HighlightSearch,
    // TimeAgoPipe,
    FilterdataPipe,
    ObfuscateEmailPipe,
    ObfuscateEmailInTextPipe,
    ReplaceTextPipe,
    XmlPipe,
    TimeAgoExtendsPipePipe,
    AbsoluteNumberPipe,
  ]
})

export class PipesModule {}
