import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
// import { DashboardService, EventsService } from '../../../services/services.module';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfigService, EmailListService, UsersService } from '@services/services.module';
import { ToastrService } from 'ngx-toastr';
import { CustomValidator, MyErrorStateMatcher } from 'app/shared/validators/custom-validator';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  public readonly user: any;
  // public password;
  // public repeatPassword;
  public setPasswordForm: FormGroup;
  public matcher = new MyErrorStateMatcher();


  constructor(public dialogRef: MatDialogRef<SetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router,
    private usersService: UsersService,
    private toaster: ToastrService,
  ) {
    console.log(data);
    this.user = data.user;
  }

  get password(): AbstractControl {
    return this.setPasswordForm.get('password');
  }

  get forcePasswordChange(): AbstractControl {
    return this.setPasswordForm.get('forcePasswordChange');
  }

  // get repeatPassword(): AbstractControl {
  //   return this.setPasswordForm.get('repeatPassword');
  // }

  async ngOnInit(): Promise<void> {
    // initialize form
    this.initializeForm();
    this.dialogRef.updatePosition({ top: '50px' });
  }

  initializeForm(): void {
    this.setPasswordForm = this.fb.group({
      password: this.user.userId ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(8)]),
      forcePasswordChange: new FormControl(false),
      // repeatPassword: this.user.userId ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  async setPassword(): Promise<void> {
    const regEx = ConfigService.getPasswordValidationRegEx();
    try {
      if (regEx.test(this.password.value)) {
        const r = await firstValueFrom(
          this.usersService.setPassword(this.user.userId, this.password.value, this.forcePasswordChange.value)
        );
        // console.log(r.body);
        if (r.body.success) {
          this.toaster.success('Password Set.');
          // this.close()
        } else {
          this.toaster.error('Errors Setting Password');
        }
      } else {
        this.toaster.error('Password does not meet standards');
      }
    } catch (error) {
      console.log(error);
      this.toaster.error('Errors Setting Password');
    }
  }


  close(): void {
    this.dialogRef.close();
  }
}
