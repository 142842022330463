import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItemsComponent } from './menu-items/menu-items.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { SubMenuItemComponent } from './sub-menu-item/sub-menu-item.component';
import { AppMaterialModule } from 'app/app-material.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule
  ],
  declarations: [
    MenuItemsComponent,
    MenuItemComponent,
    SubMenuItemComponent
  ],
  exports: [
    MenuItemsComponent,
    MenuItemComponent,
    SubMenuItemComponent
  ]
})

export class SideMenuModule {
}
