<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h3>API Call Log
      <span *ngIf="loading">Loading ...</span>
    </h3>
  </mat-card-title>
  <mat-card-content>
    <!--<ngx-datatable class="material" [rows]="apiCallLogs" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"-->
    <!--[footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [count]="count" [limit]="limit"-->
    <!--(page)='onPage($event)'>-->
    <!--<ngx-datatable-column [resizeable]="false" *ngFor="let column of columns; let i = index;" name="{{column.name}}"-->
    <!--prop="{{column.prop}}" [flexGrow]="column.flexGrow">-->
    <!--<ng-template let-value="value" let-row="row" ngx-datatable-cell-template>-->
    <!--<span style="display: block; word-wrap: break-word" *ngIf="column.name !== 'Action'">-->
    <!--{{column.name==='Requested time' || column.name === 'Response time' ? (value | amCalendar) : value}}-->
    <!--</span>-->
    <!--<button type="button" [disabled]="row.responseCode===undefined" mat-raised-button color="primary"-->
    <!--*ngIf="column.name === 'Action'"-->
    <!--(click)="displayDetails(row)">-->
    <!--<span *ngIf="row.responseCode === 200">Show Response</span>-->
    <!--<span *ngIf="row.responseCode !== 200 && row.responseCode !== undefined">Show Error</span>-->
    <!--<span *ngIf="row.responseCode === undefined">No Response</span>-->
    <!--</button>-->

    <!--</ng-template>-->
    <!--</ngx-datatable-column>-->
    <!--</ngx-datatable>-->
    <table mat-table [dataSource]="dataSource" class=""
           style="table-layout: fixed;word-break:break-all;word-wrap: break-word">
      <ng-container matColumnDef="apiProvider">
        <th mat-header-cell fxFlex="9" *matHeaderCellDef class="header-style">Provider</th>
        <td mat-cell fxFlex="9" *matCellDef="let row">
          <span>{{row.apiProvider}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="options.url">
        <th mat-header-cell fxFlex="40" *matHeaderCellDef class="header-style">URL</th>
        <td mat-cell fxFlex="40" *matCellDef="let row">
          <p>{{row.options.url}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="options.qs">
        <th mat-header-cell fxFlex="12" *matHeaderCellDef class="header-style">Params</th>
        <td mat-cell fxFlex="12" *matCellDef="let row">
          <p>{{row.options.qs}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="requestedAt">
        <th mat-header-cell fxFlex="12" *matHeaderCellDef class="header-style">Requested Time</th>
        <td mat-cell fxFlex="12" *matCellDef="let row">
          <p>{{row.requestedAt | amCalendar}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="responseAt">
        <th mat-header-cell fxFlex="12" *matHeaderCellDef class="header-style">Response Time</th>
        <td mat-cell fxFlex="12" *matCellDef="let row">
          <p>{{row.responseAt | amCalendar}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="responseCode">
        <th mat-header-cell fxFlex="5" *matHeaderCellDef class="header-style">Status</th>
        <td mat-cell fxFlex="5" *matCellDef="let row">
          <p>{{row.responseCode}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell fxFlex="8" *matHeaderCellDef class="header-style">Action</th>
        <td mat-cell fxFlex="8" *matCellDef="let row">
          <button type="button" [disabled]="row.responseCode===undefined" mat-raised-button color="primary"
                  (click)="displayDetails(row)" style="width: 95px; font-size: 12px; padding-right: 2px; padding-left: 2px;">
            <span *ngIf="row.responseCode === 200">Show Response</span>
            <span *ngIf="row.responseCode !== 200 && row.responseCode !== undefined">Show Error</span>
            <span *ngIf="row.responseCode === undefined">No Response</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="!loading && apiCallLogs.length === 0">No records found</div>
    <button mat-raised-button color="primary" class="more-button" (click)="loadMore()">More
    </button>
    <!--<mat-paginator-->
    <!--[pageSizeOptions]="[5,10,15]" [length]="limit"-->
    <!--(page)="getPaginatorData($event)"-->
    <!--showFirstLastButtons></mat-paginator>-->
  </mat-card-content>
</mat-card>
