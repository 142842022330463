import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class SurveyAnalysisService extends BaseHttpService {
  // private resource = 'survale-analysis';

  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getAnalysisList(surveyIds: string) {
    const params: any = {
      surveyIds
    };
    return this.get('survey-analysis-list', undefined, params);
  }

  // surveyIds, q.npsOnly === 'yes', q.sort === 'asc', q.limit, q.offset]
  getSurveyAnalysis(surveyIds: string, npsOnly: 'yes' | 'no', sort: 'asc' | 'desc', limit: number, offset: number,
    qbrTitle: string, threshold: number, filterLevel,
    filterQuestionId: string = undefined, filterAnswer: string = undefined) {
    const params: any = {
      surveyIds, npsOnly, sort, limit, offset, threshold, qbrTitle, filterLevel
    };
    if (filterQuestionId) {
      params.filterQuestionId = filterQuestionId;
    }
    if (filterAnswer) {
      params.filterAnswer = filterAnswer;
    }
    return this.get('survey-analysis', undefined, params);
  }

  requestSurveyAnalysis(sourceDoc) {
    return this.post('perform-survey-analysis', sourceDoc);
  }

  hideCard(cardId: string, hide: boolean) {
    return this.post('survey-analysis-hide', {id: cardId, hide});
  }
}
