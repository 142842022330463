<h5 class="mt-0">Edit Title</h5>
<p> {{ dialogData.dialogMessage }} </p>

<mat-form-field style="width: 100%" class="mb-2">
  <input matInput placeholder="Widget Title?" #widgetTitle [(ngModel)]="dialogData.title" style="width: 100%;">
</mat-form-field>
<div class="buttons-wrap">
  <button class="mat-green" (click)="dialogRef.close(dialogData.title)" mat-raised-button>OK</button>
  <button class="mat-grey" (click)="dialogRef.close()" mat-raised-button>Cancel</button>
</div>

