// Core modules
import { Component, OnInit } from '@angular/core';
import { RolesService } from 'app/services/roles.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../services/services.module';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { ToastrService } from 'ngx-toastr';

/**
 * Roles component
 */
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends SurvaleCommonComponent implements OnInit {

  public roles: any;
  public displayedColumns: string[] = ['roleName', 'permissions', 'controls'];

  /**
   * Life cycle method
   */
  constructor(
    private rolesService: RolesService,
    public authenticationService: AuthenticationService,
    private toaster: ToastrService
  ) {
    super(authenticationService);
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.getAllRoles();
  }

  /** Find all roles */
  getAllRoles(): void {
    this.rolesService.getRoles().subscribe((roles: any) => {
      this.roles = roles.body;
      this.roles.map(elem => elem.permissions = Object.keys(elem.permJSON).map(key => {
        const permission = elem.permJSON[key];
        return permission.hasEdit || permission.hasView ? key + ' - ' +
          (permission.hasEdit ? 'E' : '') +
          (permission.hasView ? ' V' : '') : '';
      }
      ));
    });
  }

  /**
   * Delete role for user
   */
  deleteRole(id: string): void {
    this.rolesService.deleteRole(id).subscribe({
      next: (result: any): void => {
        this.toaster.success('Role deleted successfully');
        this.getAllRoles();
      },
      error: ((error): void => {
        console.error(error);
        this.toaster.error('Error(s) Occurred');
      })
    });
  }

}
