// Core modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Third party modules
import * as _ from 'lodash';


// Application services
import { ConfigService, PermissionTypes } from './config.service';
import { BaseHttpService } from './base-http.service';

/**
 * Permission services
 */
@Injectable()
export class PermissionsService extends BaseHttpService {

  private permissions = Object.values(PermissionTypes);

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService
  ) {
    super(http);
    // console.log('PermissionTypes: ', _.keys(PermissionTypes));
    // console.log('permissions', this.permissions.length, Object.keys(PermissionTypes))
  }

  /**
   * Find all permissions
   */
  getPermissions() {
    return this.permissions;
  }
}

