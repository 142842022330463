<div fxLayout="row">
  <ng-template #confirmDialog let-dialogRef="dialogRef">
    <h2 mat-dialog-title>Confirm Action</h2>
    <mat-dialog-content>Are you sure you want to delete this item?</mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="dialogRef.close(false)">No</button>
      <button mat-button color="primary" (click)="dialogRef.close(true)">Yes</button>
    </mat-dialog-actions>
  </ng-template>
  <mat-card fxFlex="100%">
    <mat-card-header>
      <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
        sftp access
      </mat-card-title>
      <div fxFlex></div>

      <div class="m-gap text-right upload-csv-sftp padding-5">
        <input type="file" (change)="onFileSelected($event)" accept=".csv">
        <a mat-raised-button (click)="uploadFile()" color="primary">Upload CSV file</a>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Select User</mat-label>
        <mat-select [(ngModel)]="selectedUser" (selectionChange)="listContents()">
          <mat-option *ngFor="let user of users" [value]="user">{{user.username}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select folder</mat-label>
        <mat-select [(ngModel)]="selectedFolder" (selectionChange)="listContents()">
          <mat-option *ngFor="let folder of folders" [value]="folder">{{folder}}</mat-option>
        </mat-select>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0">
        <ng-container matColumnDef="Key">
          <th mat-header-cell *matHeaderCellDef> File Name</th>
          <td mat-cell *matCellDef="let element">
            {{element.Key}}
          </td>
        </ng-container>
        <ng-container matColumnDef="LastModified">
          <th mat-header-cell *matHeaderCellDef> Last modified</th>
          <td mat-cell *matCellDef="let element">
            {{element.LastModified}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Size">
          <th mat-header-cell *matHeaderCellDef> Size </th>
          <td mat-cell *matCellDef="let element">
            {{element.Size}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Owner.DisplayName">
          <th mat-header-cell *matHeaderCellDef> Owner </th>
          <td mat-cell *matCellDef="let element">
            {{element.Owner.DisplayName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="buttons-wrap" style="margin-left: 20px">
              <mat-button-toggle-group>
                <mat-button-toggle class="mat-raised-button sftp-button" (click)="openConfirmDialog(element.Key)">
                  <i class="fa fa-lg fa-trash" matTooltip="Delete"></i>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>