<mat-card>
  <mat-card-header>
    <mat-card-title class="title">Assign Admin</mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <form [formGroup]="formGroup" *ngIf="formGroup" fxLayout="column">
      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100%">
        <mat-label>Select User</mat-label>
        <mat-select formControlName='user'>
          <mat-option *ngFor="let user of users" value={{user.userId}}> {{user.firstName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="user.invalid && user.errors.required">User Name is required</mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions class="text-right">
    <button mat-raised-button class="mat-green" (click)="assignAdminRole()">Submit</button>
    <button mat-raised-button routerLink='/admin/roles'>Cancel</button>
  </mat-card-actions>
</mat-card>
