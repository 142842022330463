<h5>Immediate Alert Settings</h5>
<form [formGroup]="immediateAlertForm" *ngIf="surveyEscalationSettings && immediateAlertForm">
<!--  <span class="title"> Client Settings </span>-->
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="49%" fxLayout="column" class="settings-row margin-top-10" fxLayoutGap="10px"
      formGroupName="alertLongComments">
      <div fxFlex="100%">
        <mat-checkbox formControlName="enabled"> Long comments</mat-checkbox>
      </div>
      <div fxFlex="100%">
        <mat-form-field appearance="outline">
          <input matInput placeholder="" type="number" formControlName="value">
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="49%" fxLayout="column" class="margin-top-10" fxLayoutGap="10px" formGroupName="minScore">
      <div fxFlex="100%">
        <mat-checkbox formControlName="enabled">Minimum Score</mat-checkbox>
      </div>
      <div fxFlex="100%">
        <mat-form-field appearance="outline">
          <input matInput placeholder="" type="number" formControlName="value">
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
