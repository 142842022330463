import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ClientComponent } from './client/client.component';
import { UsersComponent } from './users/users.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { RolesComponent } from './roles/roles.component';

import { AppMaterialModule } from 'app/app-material.module';
import { AdminRoutes } from './admin.routing';
import { SurvaleCommonModule } from 'app/components/survale-common/survale-common.module';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';
import { AssignAdminComponent } from './assign-admin/assign-admin.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { ImmediateSettingsComponent } from './immediate-settings/immediate-settings.component';
import { ClientClassificationComponent } from './client-classification/client-classification.component';
import { AddClientDialogComponent } from './add-client-dialog/add-client-dialog.component';
import { ClientIndexWeightsComponent } from './client-index-weights/client-index-weights.component';
import { GdprLogComponent } from './gdpr-log/gdpr-log.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TwoFaCodeComponent } from './users/two-fa-code/two-fa-code.component';
import { SetPasswordComponent } from './users/set-password/set-password.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ClientIpWhitelistComponent } from './client-ip-whitelist/client-ip-whitelist.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FileUploadModule } from 'ng2-file-upload';
import { MomentModule } from 'ngx-moment';
import { ApiCallLogComponent } from './api-call-log/api-call-log.component';
import { ApiCalllogDetailsDialogComponent } from './api-calllog-details-dialog/api-calllog-details-dialog.component';
import { AddSamlSSODialogComponent } from './add-saml-sso-dialog/add-saml-sso-dialog.component';
import { OtherClientSettingsComponent } from './other-client-settings/other-client-settings.component';
import { SftpAccessComponent } from './sftp-access/sftp-access.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(AdminRoutes),
    FlexLayoutModule,
    AppMaterialModule,
    SurvaleCommonModule,
    NgxDatatableModule,
    QRCodeModule,
    NgxMatSelectSearchModule,
    FileUploadModule,
    MomentModule
  ],
  declarations: [
    UsersComponent,
    ClientComponent,
    AddEditUserComponent,
    RolesComponent,
    AddEditRoleComponent,
    AssignAdminComponent,
    ClientSettingsComponent,
    EmailSettingsComponent,
    ImmediateSettingsComponent,
    ClientClassificationComponent,
    AddClientDialogComponent,
    AddSamlSSODialogComponent,
    ClientIndexWeightsComponent,
    GdprLogComponent,
    GdprComponent,
    TwoFaCodeComponent,
    ClientIpWhitelistComponent,
    SetPasswordComponent,
    ApiCallLogComponent,
    ApiCalllogDetailsDialogComponent,
    OtherClientSettingsComponent,
    SftpAccessComponent,
  ]
})

export class AdminModule {}

