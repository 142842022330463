import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'obfuscateEmail'
})
export class ObfuscateEmailPipe implements PipeTransform {

  transform(value: string) {
    const emailParts = value.split('@');
    if (emailParts[1]) {
      const emailDomainParts = emailParts[1].split('.');
      let v = this.replaceExceptFirstAndLast(emailParts[0]) + '@';
      _.each(emailDomainParts, s => v = v + this.replaceExceptFirstAndLast(s) + '.');
      return v.substring(1, v.length - 1);
    } else {
      return value;
    }
  }

  replaceExceptFirstAndLast(inp: string) {
    if (inp.length < 2) {
      return ''.padStart(2, '*');
    } else {
      const inpLen = inp.length;
      return inp.substring(0, 1) + ''.padStart(inpLen - 2, '*') + inp.substring(inpLen - 1);
    }
  }
}
