import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService, ServiceCacheTypes } from './base-http.service';
import { Observable } from 'rxjs';
import { SurvaleHttpInterceptor } from '../interceptors/survale-http-interceptor';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';


@Injectable()

export class ViewService extends BaseHttpService {
  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getViewsForUser() {
    const currentUser = this.authenticationService.getCurrentUser();
    const userId: string = currentUser.userId;
      const viewIds = currentUser.viewIds || -1;
    return this.get('view/user', '', {userId, viewIds});
  }

  fetchSurveyJSONs(surveyIds) {
    const currentUser = this.authenticationService.getCurrentUser();
    return this.get('view/survey-details', undefined, {surveyIds}, currentUser.userId.toString(), true);
  }

  getView(viewId) {
    return this.get('view/by-id', viewId);
  }

  getUserSurveys() {
    const currentUser = this.authenticationService.getCurrentUser();
    const userId: string = currentUser.userId;
    return this.get('user-surveys', undefined, {userId});
  }

  deleteSurvey(id: string) {
    return this.delete('survey-response', id);
  }

  moveSurveyResponse(postId: string, clientId: number) {
    const url = `move-response/${postId}`;
    return this.post(url, {clientId});
  }

  getChildClients() {
    // child-clients/
    return this.get('child-clients');
  }

  getAllViews() {
    return this.get('view/all');
  }

  updateView(view) {
    return this.post('view/update', view);
  }

  /**
   * Add view data
   * @param payload View data
   */
  addView(payload) {
    return this.post('view/add', payload);
  }

  /**
   * Delete view
   * @param id viewId
   */
  deleteView(element) {
    return this.post('view/delete', element);
  }

  createView(surveys: string) {
    this.post('view/add', {surveyList: surveys});
  }
}

