import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute, Routes } from '@angular/router';
import { InsightsService, SurveyFiltersService } from '../services/services.module';
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppCache } from '../components/survale-common/cache/app.cache';
import * as _ from 'lodash';

@Injectable()
export class SurveyFiltersResolver implements Resolve<any> {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(private activatedRoute: ActivatedRoute,
    private surveyFiltersService: SurveyFiltersService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const cached = this.cache.getCache(this.cacheTypes.SelfSurveyFilters, 'all');
    if (cached) {
      // this.cacheSurveys(cached);
      return cached;
    }

    return this.surveyFiltersService.getUsersSurveyFilters()
      .pipe(
        ((response: any) => {
          response.subscribe(data => this.cache.setCache(this.cacheTypes.SelfSurveyFilters, 'all', data));
          return response;
        }) // , ((err) => throwError(err))
      );

  }
}
