<a *ngIf="hasShareId(childItem) && childItem.type !== 'sub' &&
       (menuItem.state !== 'cande-fake-menu-1' && menuItem.state !== 'cande-fake-menu-2')"
  [routerLink]="['/', menuItem.state, childItem.state, childItem?.data?.shareId]" class="relative" mat-ripple>
  {{ childItem.name | translate }}
</a>
<a *ngIf="!hasShareId(childItem) && childItem.type !== 'sub' &&
       (menuItem.state !== 'cande-fake-menu-1' && menuItem.state !== 'cande-fake-menu-2')"
  [routerLink]="['/', menuItem.state, childItem.state ]" class="relative" mat-ripple>
  {{ childItem.name | translate }}
</a>


<a appAccordionToggle id="accordionToggle-{{menuItem.state + '-' + childItem.state}}" *ngIf="childItem.type === 'sub'"
  class="relative" mat-ripple>
  <!--                   [routerLink]="['/', menuItem.state, childItem.state ]"   -->
  <span>{{ childItem.name | translate }} </span>

  <span fxFlex></span>
  <mat-icon class="menu-caret" *ngIf="childItem.type === 'sub'">arrow_drop_down</mat-icon>

</a>

<mat-nav-list class="sub-menu" *ngIf="childItem.type === 'sub'">
  <mat-list-item [class.hidden]="!subChildItem.visible" *ngFor="let subChildItem of childItem.children">
    <a *ngIf="subChildItem.state !== 'dashboards' && (subChildItem.state !== 'surveys' && subChildItem.state !== 'survey-analysis' && subChildItem.state !== 'sentiment-analysis' && menuItem.state !== 'saved-user-filters')"
      [routerLink]="['/', menuItem.state, childItem.state, 'dashboard', subChildItem.state ].concat(subChildItem.additionalStates || [])"
      [class]="'relative ' + childItem.customCssClass" mat-ripple>
      {{ subChildItem.name | translate }}
    </a>
    <!-- [data-subChildItem-state]="subChildItem.state" [data-menu-item]="menuItem.state" -->

    <a *ngIf="subChildItem.state === 'dashboards' || subChildItem.state === 'surveys' || subChildItem.state === 'survey-analysis' || subChildItem.state === 'sentiment-analysis' || menuItem.state === 'saved-user-filters'"
      [routerLink]="['/', menuItem.state, childItem.state, subChildItem.state ].concat(subChildItem.additionalStates || [])"
      [class]="'relative ' + subChildItem.customCssClass" mat-ripple>
      <!-- [menu-item]="menuItem.state"  -->
      {{ subChildItem.name | translate }}
    </a>

  </mat-list-item>
</mat-nav-list>
