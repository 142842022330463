import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ClientService} from '@services/client.service';
import {UsersService} from '@services/users.service';
import {MatDialog} from '@angular/material/dialog';

export interface sftpUser {
  username: string;
  clientId: number;
  clientName: string;
  homeDirectory: string;
}

@Component({
  selector: 'app-sftp-access',
  templateUrl: './sftp-access.component.html',
  styleUrls: ['./sftp-access.component.scss']
})
export class SftpAccessComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog!: TemplateRef<any>;
  client: any;
  selectedFile: File | null = null;
  selectedUser: sftpUser = null;
  selectedFolder: string = null;
  users: [];
  displayedColumns: string[] = ['Key', 'LastModified', 'Size', 'Owner.DisplayName', 'actions'];
  dataSource;
  folders = ['candidate-data', 'employee-data'];
  constructor(private usersService: UsersService, private clientService: ClientService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    const data = await this.clientService.getClient().toPromise();
    this.client = data.body[0];

    this.users = (await this.usersService.getSftpUsers(this.client.clientId).toPromise()).body;
    console.log('hello');
  }

  async listContents() {
    if (!this.selectedUser || !this.selectedFolder) {
      return;
    }
    let res = (await this.usersService.getUserSftpBucketContents(
      `${this.selectedUser.username}/${this.selectedFolder}`).toPromise()).body;

    this.dataSource = new MatTableDataSource(res.Contents);

    console.log('hello');
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  async uploadFile(): Promise<void> {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('Key', `${this.selectedUser.username}/${this.selectedFolder}`);
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      console.log('FormData after append:', formData.get('file')); // Should log the File object

      await this.usersService.uploadSftpFile(formData, `${this.selectedUser.username}/${this.selectedFolder}`).toPromise();
      await this.listContents();
    }
  }

  openConfirmDialog(Key): void {
    const dialogRef = this.dialog.open(this.confirmDialog, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('User confirmed the action');
        await this.usersService.deleteSftpObject({Key}).toPromise();
        await this.listContents();
      } else {
        console.log('User canceled the action');
      }
    });
  }

}
