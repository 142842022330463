import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { MatButtonModule } from '@angular/material/button';
// import { MatCardModule } from '@angular/material/card';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatIconModule } from '@angular/material/icon';
// import { MatInputModule } from '@angular/material/input';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatRadioModule } from '@angular/material/radio';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppMaterialModule } from 'app/app-material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // MatIconModule,
    // MatCardModule,
    // MatIconModule,
    // MatInputModule,
    // MatMenuModule,
    // MatMenuModule,
    // MatRadioModule,
    // MatButtonModule,
    // MatProgressBarModule,
    // MatTabsModule,
    // MatToolbarModule,
    FlexLayoutModule,
    // MatChipsModule,
    AppMaterialModule,
  ],
  declarations: [
    BreadcrumbComponent

  ],
  exports: [
    BreadcrumbComponent,

  ],
  providers: []
})

export class SurvaleCommonModule {
}

