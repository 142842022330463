// Core modules
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';

/**
 * Add client dialog
 */
@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent extends SurvaleCommonComponent implements OnInit {

  addClientForm: FormGroup;

  /**
   * Life cycle method
   * @param fb FormBuilder
   */
  constructor(
    public authenticationService: AuthenticationService,
    private fb: FormBuilder,
  ) {
    super(authenticationService);
  }

  /**
   * Returns name of client
   */
  get name(): AbstractControl {
    return this.addClientForm.get('name');
  }

  /**
   * Returns the URL
   */
  get url(): AbstractControl {
    return this.addClientForm.get('url');
  }

  /**
   * Returns the users
   */
  get users(): AbstractControl {
    return this.addClientForm.get('users');
  }

  /**
   * Returns the views
   */
  get views(): AbstractControl {
    return this.addClientForm.get('views');
  }

  /**
   * Returns the parent
   */
  get parent(): AbstractControl {
    return this.addClientForm.get('parent');
  }

  /**
   * Returns the login
   */
  get login(): AbstractControl {
    return this.addClientForm.get('login');
  }

  /**
   * Returns the first name
   */
  get CSRFirstName(): AbstractControl {
    return this.addClientForm.get('CSRFirstName');
  }

  /**
   * Returns the last name
   */
  get CSRLastName(): AbstractControl {
    return this.addClientForm.get('CSRLastName');
  }

  /**
   * Returns the reply to email
   */
  get replyToEmail(): AbstractControl {
    return this.addClientForm.get('replyToEmail');
  }

  /**
   * Returns the reply to email
   */
  get replyToName(): AbstractControl {
    return this.addClientForm.get('replyToName');
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void{
    let parent = 'SURVALE';
    if (this.currentUser.clientId === 19) {
      parent = 'CANDE';
    } else if (this.currentUser.clientId === 353) {
      parent = 'TMP';
    } else if (this.currentUser.clientId === 407) {
      parent = 'OUTMATCH';
    } else if (this.currentUser.clientId === 405) {
      parent = 'JOBVITE';
    } else if (this.currentUser.clientId === 954) {
      parent = 'PH_CREATIVE';
    }
    this.addClientForm = this.fb.group({
      name: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      users: new FormControl(2, Validators.required),
      views: new FormControl(2, Validators.required),
      parent: new FormControl(parent, Validators.required),
      login: new FormControl('', Validators.required),
      CSRFirstName: new FormControl('Survale CSR', Validators.required),
      CSRLastName: new FormControl('', Validators.required),
      replyToEmail: new FormControl('', Validators.required),
      replyToName: new FormControl('', Validators.required),
    });
    this.addClientForm.get('name').valueChanges.subscribe((key): void => this.onChangeName(key));
  }

  /**
   * On change client , automatically filled last name , url, replyToEmail
   * @param key client name
   */
  onChangeName(key: string): void {
    const dashed = key.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    this.login.setValue(`survale+${dashed}@survale.com`);
    this.CSRLastName.setValue(key);
    this.url.setValue(`www.${dashed}.com`);
    this.replyToEmail.setValue(`no-reply@${dashed}.com`);
    this.replyToName.setValue(key);
  }

}
