<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    GDPR Log
  </mat-card-title>
  <mat-card-content>
    <ngx-datatable class="material" [rows]="gdpr" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
      [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [count]="count" [offset]="offset" [limit]="limit"
      [selected]="selected" [selectionType]="'row'"  (page)='onPage($event)'>
      <ngx-datatable-column [resizeable]="false" *ngFor="let column of columns; let i = index;" name="{{column.name}}"
        prop="{{column.prop}}" [flexGrow]="column.flexGrow">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="column.name == 'identifier'">
            {{row._id}}
          </span>
          <span *ngIf="column.name == 'Action'">
            {{value}}
          </span>
          <span *ngIf="column.name == 'By'">
            {{value | obfuscateEmail}}
          </span>
          <span *ngIf="column.name == 'Log'">
            {{value | obfuscateEmailInText}}
          </span>
          <span *ngIf="column.name=='Added'">
            {{value | timeAgo}}
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card-content>
</mat-card>
