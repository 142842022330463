import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { DashboardService, ViewService } from '../services/services.module';
import { AppCache } from '../components/survale-common/cache/app.cache';
// import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ViewsResolver implements Resolve<any> {

  constructor(private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private viewService: ViewService,
    private router: Router) {

  }


  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return new Observable<any>((observer) => {
      const cache = AppCache.getInstance();
      const cacheTypes = AppCache.cacheTypes();
      const cached = cache.getCache(cacheTypes.Views, 'views');
      if (cached) {
        return cached;
      }
      this.viewService.getViewsForUser()
        .subscribe(
          ((allViews: any): void => {
            // console.log('ViewsResolver :: response', response);
            // console.log('ViewsResolver :: data', allViews);
            if (allViews.body.success === false) {
              observer.next([]);
              observer.complete();
              return;
            }
            cache.setCache(cacheTypes.Views, 'views', allViews);
            const viewIds = _.map(allViews.body, i => i.viewId);
            _.each(allViews.body, v => cache.setCache(cacheTypes.View, v.viewId, v));
            const source = of(viewIds);
            forkJoin(_.map(viewIds, viewId => (this.dashboardService.getDashboardByViewId(viewId))))
              .subscribe(viewDashboards => {
                // console.log('forkJoin dashboards', viewDashboards);
                _.each(viewDashboards, dashboards => {
                  const viewId = _.get(dashboards, 'body.[0].viewId');
                  cache.setCache(cacheTypes.ViewDashboards, viewId, dashboards);
                  // cache all the dashboards
                  _.each(dashboards.body, d => {
                    // console.log('d.id', d.id);
                    cache.setCache(cacheTypes.Dashboard, d.id, d);
                  });
                });
                observer.next(allViews);
                observer.complete();
              });
          }),
          ((err) => {
            observer.next({ body: [] });
            observer.complete();
            // throwError(err)
          }
          )
        );
    });
  }
}
