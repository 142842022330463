import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
// import { SurvaleHttpInterceptor } from '../interceptors/survale-http-interceptor'
import { HttpClient } from '@angular/common/http';
// import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class GeneralDataService extends BaseHttpService {
  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getCustomFields(fieldsFrom = 'email_list') {
    return new Observable((observer) => {
      const params = { fieldsFrom };
      this.get('custom-fields', undefined, params)
        .subscribe(response => {
          const interviewerIdx = _.findIndex(response.body, (f: any) => f.hash.toString() === (-35913644).toString());
          if (interviewerIdx > 1) {
            response.body[interviewerIdx].questionType = 'Multiple Select';
          } else {
            response.body.push({
              key: 'interviewers',
              value: 'Interviewers',
              hash: -35913644,
              questionType: 'Multiple Select'
            });
          }
          observer.next(response);
          observer.complete();
        });
    });
  }

  getGHWebHook() {
    return this.get('iwh-json-ep', undefined, {});
  }

  createGHWebHook() {
    return this.post('iwh-json-ep', {}, {});
  }

  getCustomFieldValues(customField) {
    const params = { customField };
    return this.get('custom-field-values', undefined, params);
  }

  getClientDataset() {
    return this.get('get-client-dataset');
  }

  getClient() {
    return this.get('client');
  }

  getSiteSettings(siteId) {
    return this.get('settings', siteId);
  }

  getCampaignList(limit = 10) {
    return this.get('campaign-list', undefined, { limit });
  }

  getEmployeeCampaignList(limit = 10) {
    return this.get('employee-campaigns', undefined, { limit });
  }

  sendDashboardScreenshotTestEmail(payload) {
    return this.post('send-dashboard-screenshot-test-email', payload);
  }

  updateSurveyInsights(payload) {
    return this.post('survey-insights/update', payload);
  }

  updateSurveyFilter(payload) {
    return this.post('survey-filters/update', payload);
  }

  getURLShortenerStats() {
    return this.get('url-shortener-stats');
  }

  createShortURL(title: string, url: string, name = '', forceCreate: boolean = false, requestor = 'manual') {
    return this.post('url-shortener', { url, title, name, forceCreate, requestor });
  }

  getShortURL(longURL: string) {
    return this.get('url-shortener/by-longURL', longURL);
  }

  getURL(id: string) {
    return this.get('url-shortener/by-id', id);
  }

  getEmailLogs(campaignId: number, isEmployeeCampaign: boolean, nextToken: string) {
    return this.get('email-logs', undefined, { isEmployeeCampaign, campaignId, nextToken });
  }

  reflectResponse(url: string): any {
    return this.post('reflect-response', {url});
  }

  webhookStatus(): any {
    return this.get('iwh-status');
  }

}

