import { ValidatorFn, FormGroup, ValidationErrors, FormGroupDirective, FormControl, NgForm, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Error state matcher
 */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

/**
 * Custom validator
 */
export class CustomValidator {

  static passwordErrorValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('password');
    const repeatPassword = control.get('repeatPassword');
    return password.value !== repeatPassword.value ? { notMatch: true } : null;
  };

  static passwordMatch(control: AbstractControl): ValidationErrors | null {
    // const valid: boolean = (
    //   (control.value.newPassword && control.value.confirmPassword) && control.value.newPassword !== control.value.confirmPassword) ?
    //   control.get('confirmPassword').setErrors({ notMatch: true }) : false;
    let valid = false;
    if ((control.value.newPassword && control.value.confirmPassword) && control.value.newPassword !== control.value.confirmPassword) {
      control.get('confirmPassword').setErrors({ notMatch: true });
    } else if (((control.value.currentPassword && control.value.newPassword) &&
      control.value.newPassword === control.value.currentPassword)) {
      valid = true;
      control.get('newPassword').setErrors({ samePassword: true });
    }
    return valid ? { notMatch: true } : null;
  };
}
