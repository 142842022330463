// Core modules
import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services/services.module';

/**
 * Replace text based upon account
 */
@Pipe({
  name: 'replaceText'
})

export class ReplaceTextPipe implements PipeTransform {

  /**
   * Life cycle method
   */
  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  transform(value: any): any {
    const user = this.authenticationService.getCurrentUser();
    let oldText;
    if (!value) {
      return '';
    }
    const newText = 'Acme Inc';
    if (user.email.indexOf('survale+asn@survale.com') !== -1) {
      oldText = 'asurion';
    } else if (user.email.indexOf('survale+exn@survale.com') !== -1) {
      oldText = 'exelon|ComEd|nuclear';
    } else if (user.clientId === 576) {
      oldText = 'epicor|Epicor';
    } else if (user.email.indexOf('survale+epcr@survale.com') !== -1) {
      oldText = 'epicor';
    } else if (user.email.indexOf('survale+ntx@survale.com') !== -1) {
      oldText = 'nutanix';
    }
    if (!oldText) {
      return value;
    }
    const re = new RegExp(oldText, 'gi');
    return value.replace(re, newText);
  }
}
