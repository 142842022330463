import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { SurvaleHttpInterceptor } from '../interceptors/survale-http-interceptor';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AppCache } from '../components/survale-common/cache/app.cache';

@Injectable()

export class InsightsService extends BaseHttpService {
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getUsersSurveyInsights() {
    return new Observable<any>((observer) => {
      this.get('survey-insights/user')
        .subscribe((response) => {
          // console.log(response);
          this.cache.setCache(this.cacheTypes.Insights, '', response);
          _.each(response.body, i => this.cache.setCache(this.cacheTypes.Insight, i.insightId, i));
          observer.next(response);
          observer.complete();
        });
    });
  }

  getAllInsights(withIds = 'no') {
    return this.get('survey-insights/all', undefined, { withIds });
  }

  async createAndShareInsight(payload) {
    const insight = await this.createSurveyInsight(payload).toPromise();
    console.log('insight', insight);
    return true;
    // console.log('createAndShareInsight', insight);
    // const postData = {
    //   sharedWithUserIds: payload.selectedUsers,
    //   insightId: insight.body.insertId
    // };
    // await this.post('survey-insights/add', postData).toPromise();
    // return postData;
  }

  createSurveyInsight(payload) {
    return this.post('survey-insights/add', payload);
  }

  getInsight(id) {
    return this.get('survey-insights/by-id', id);
  }

  deleteInsight(insightId) {
    return this.post('survey-insights/delete', { insightId });
  }

  deleteInsightShare(id) {
    return this.post('shared-survey-insights/delete', { sharedInsightId: id });
  }

  addShare(insightId, userId, emailsOnInsightCreation: 'yes' | 'no') {
    const sharedWithUserIds = [userId];
    return this.post('survey-insights/share', { insightId, sharedWithUserIds, emailsOnInsightCreation });
  }
}


