import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';


import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, EventsService, VersionCheckService } from './services/services.module';
import { AppCache } from './components/survale-common/cache/app.cache';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import * as humanizeString from 'humanize-string';
import * as _ from 'lodash';

declare let gtag: (type: string, name: string, options: object) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss']
  // add scss to the layout if needed
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public loading = false;
  public consoleOutput = '';
  private navigateCheckCounter = 0;
  private cache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private versionCheckService: VersionCheckService,
    private authenticationService: AuthenticationService,
    private eventsService: EventsService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    // console.log('AppComponent..ctor');
    // this.activatedRoute.params.subscribe((parameter) => {
    //   console.log('>> activatedRoute param', parameter);
    // });
    this.eventsService.onSelfDashboardFiltersChanged.subscribe((id): void => {
      this.cache.clearLocalCache(this.cacheTypes.SelfDashboardsFilters, id || 'all');
    });

    this.eventsService.onSelfSurveyFiltersChanged.subscribe((id): void => {
      this.cache.clearLocalCache(this.cacheTypes.SelfSurveyFilters, id || 'all');
    });

  }

  forceNavigateHome(): void {
    this.navigateCheckCounter++;
    console.log('3. AppComponent forceNavigateHome this.router.url', this.router.url, this.navigateCheckCounter);
    // eslint-disable-next-line max-len
    this.consoleOutput += ['3. AppComponent forceNavigateHome this.router.url', this.router.url, this.navigateCheckCounter, '===-----------==='].join(' \n');
    if (this.router.navigated && this.router.url !== '/home') {
      window.location.href = '/#/home;force-reload=' + this.navigateCheckCounter;
      // this.router.navigateByUrl('/home')
      //   .then((r): void => {
      //     console.log('navigated home', r);
      //     // this.consoleOutput += ['navigated home', r, '===-----------==='].join('\n');
      //     if (!r) {
      //       setTimeout((): void => this.forceNavigateHome(), this.navigateCheckCounter * 1000);
      //     }
      //   })
      //   .catch((error): void => {
      //     console.error('error in forceNavigateHome', error);
      //   });
    } else {
      setTimeout((): void => this.forceNavigateHome(), this.navigateCheckCounter * 1000);
    }
  }

  forceNavigateScreenshot(): void {
    this.navigateCheckCounter++;
    console.log('3. AppComponent forceNavigateScreenshot this.router.url', this.router.url, this.navigateCheckCounter);
    const cache = AppCache.getInstance();
    const dest: Array<string> = cache.getScreenshotURL();
    // eslint-disable-next-line max-len
    // this.consoleOutput += ['\n3. AppComponent forceNavigateScreenshot this.router.url', this.router.url, this.navigateCheckCounter, this.router.navigated, '\n===-----------===\n'].join('\n');
    // if (this.router.navigated) {
    const destURL = '/#/' + dest.join('/').replace('//', '/');
    if (this.router.navigated && this.navigateCheckCounter > 3 && this.router.url.indexOf('say-cheese') > -1) {
      window.location.href = destURL + ';force-reload=' + this.navigateCheckCounter;
    } else if (this.router.navigated && this.router.url.indexOf('say-cheese') > -1) {
      // this.consoleOutput += ['\n4. AppComponent forceNavigateScreenshot navigating to ', dest.join(' '), ''].join('\n')
      this.router.navigateByUrl(dest.join('/').replace('//', '/'))
        .then((r): void => {
          console.log('navigated Screenshot', r);
          // this.consoleOutput += ['5. navigated result', r, dest.join(' '), this.router.url, '===-----------===\n'].join('\n');
          if (!r) {
            setTimeout((): void => this.forceNavigateScreenshot(), this.navigateCheckCounter * 1000);
          }
        })
        .catch((error): void => {
          console.error('error in forceNavigateScreenshot', error);
        });
    } else if (this.router.url.indexOf('say-cheese') > -1) {
      // this.consoleOutput += '\n6. this.router.navigated: ' + this.router.navigated + '\n';
      setTimeout((): void => this.forceNavigateScreenshot(), this.navigateCheckCounter * 1000);
    }
    // } else if (this.navigateCheckCounter > 3) {
    //   window.location.href = cache.getScreenshotURL();
    // } else {
    //   setTimeout(() => this.forceNavigateScreenshot(), this.navigateCheckCounter * 1000)
    // }
  }

  forceNavigateDestination(): void {
    this.navigateCheckCounter++;
    if (this.router.navigated && this.navigateCheckCounter < 9) {
      // console.log('2. AppComponent forceNavigateDestination this.router.url', this.router.url, this.navigateCheckCounter);
      // this.consoleOutput +=
      // ['2. AppComponent forceNavigateDestination this.router.url', this.router.url, this.navigateCheckCounter,
      // '===-----------===\n'].join(' \n');
      // following is a limbo route
      if (this.router.url === '/') {
        this.forceNavigateHome();
        // following is another limbo route
      } else if (this.router.url.indexOf('say-cheese') > -1) {
        this.forceNavigateScreenshot();
      }
    } else if (this.navigateCheckCounter < 9) {
      setTimeout((): void => this.forceNavigateDestination(), this.navigateCheckCounter * 1000);
    }
  }

  ngOnDestroy(): void {
    console.log('AppComponent .. ngOnDestroy');
  }

  ngAfterViewInit(): void {
    // console.log('AppComponent .. ngAfterViewInit');
    setTimeout((): void => this.checkAuth(), 1000);
  }

  ngOnInit(): void {
    // check for new version every 5 minutes
    // console.log('AppComponent .. ngAfterViewInit', environment.name);
    if (environment.name !== 'dev') {
      this.versionCheckService.initVersionCheck('/version.json', 1000 * 60 * 5);
    }

    // need to wait to validate the session as in order to give setAuthToken to succeed
    // for dashboard screenshots component


    this.router.events.subscribe((event: any): void => {
      const isNumeric = (value): boolean => /^-?\d+$/.test(value);

      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          // console.log('navigation started');
          // this.consoleOutput += (new Date()) + 'navigation started \n==================== \n';
          break;
        }

        case event instanceof NavigationEnd: {
          const url = event.url;
          const pageTitle = _.map(
            _.compact(
              _.map(url.split('/'), t => isNumeric(t) ? null : t)
            ), _.startCase).join(' - ');
          this.titleService.setTitle(pageTitle);

          setTimeout((): void => {
            this.trackGAClick(event);
          }, 10);

          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          // console.log('navigation ended', this.router.url);
          // this.consoleOutput += (new Date()) + `navigation ended ${this.router.url} \n===================\n`;
          break;
        }
        default: {
          break;
        }
      }
    });

    // this.checkAuth();
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  checkAuth(): void {
    let redirected = false;
    console.log('this.router.url', this.router.url);
    if (this.router.url.indexOf('/authentication/') > -1) {
      return;
    }
    const user = this.authenticationService.getCurrentUser();
    console.log('AppComponent user', user);
    this.consoleOutput += ['AppComponent user', user].join('/n');

    if (!user) {
      this.authenticationService.validateSession()
        .subscribe({
          next: (data: any): void => {
            redirected = true;
            console.log('AppComponent/ngOnInit/validateSession: navigating home', data);
            // this.consoleOutput += ['AppComponent/ngOnInit/validateSession: navigating home', data, '===-----------==='].join(' ') + '\n';
            console.log('1. AppComponent this.router.url', this.router.navigated, this.router);
            // this.consoleOutput += ['1. AppComponent this.router.url', this.router.navigated, '===-----------==='].join(' ') + '\n';
            setTimeout((): void => this.forceNavigateDestination(), 3000);
          },
          error: (error): void => {
            redirected = true;
            console.log('AppComponent /ngOnInit/validateSession: navigating login', error);
            // this.consoleOutput += ['AppComponent /ngOnInit/validateSession: navigating login',
            // error, '===-----------==='].join(' ') + '\n';
            this.router.navigate(['/', 'authentication', 'login'])
              .then((r): void => console.log('navigated login', r));
          }
        });
    } else {
      setTimeout((): void => this.forceNavigateDestination(), 3000);
    }
  }

  private trackGAClick(event: any): void {
    if (environment.gaCode) {
      // console.log('tracked pageview', event.urlAfterRedirects);
      const currentUser = this.authenticationService.getCurrentUser();
      let customMap;
      if (currentUser) {
        customMap = {
          clientName: currentUser.clientName,
          clientSource: currentUser.clientSource,
          userType: currentUser.userType,
          email: currentUser.email,
          isAdmin: currentUser.email.endsWith('@survale.com')
        };
      }
      gtag('config', environment.gaCode,
        {
          page_path: event.urlAfterRedirects,
          custom_map: customMap
        }
      );
    }
  }

}
