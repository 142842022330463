import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerDataServiceService } from 'app/services/server-data-service.service';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-api-calllog-details-dialog',
  templateUrl: './api-calllog-details-dialog.component.html',
  styleUrls: ['./api-calllog-details-dialog.component.scss']
})
export class ApiCalllogDetailsDialogComponent implements OnInit {
  response: any;
  isLoading = true;
  isJSON = true;
  isXml = false;

  constructor(private serverDataService: ServerDataServiceService,
    public dialogRef: MatDialogRef<ApiCalllogDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  async ngOnInit(): Promise<void> {
    if (this.data.errorResponse !== undefined) {
      this.response = this.data.errorResponse;
    } else if (this.data.url !== undefined) {
      try {
        const results: any = await firstValueFrom(this.serverDataService.getApiCallLogDetails(
          this.data.url
        ));
        console.log('results.body', results.body);
        this.response = (results.body.fileContent);
        try {
          const r = JSON.parse(_.get(results, 'body.fileContent'));
          this.isJSON = typeof r === 'object';
          this.response = r;
        } catch (e) {
          // do nothing
          this.isJSON = false;
        }
        this.isXml = _.get(results, 'body.fileContent').toString().startsWith('<');
        console.log('this.isJSON', this.isJSON, this.isXml);
        this.isLoading = false;
        // console.log('this.response', this.response);
      } catch (e) {
        this.isJSON = false;
        this.isXml = false;
        this.response = e.message;
      }
    } else {
      this.response = JSON.stringify(this.data.response);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
